<prognosis-form #defaultDate (prognosisRequest)="onSubmitPrognosisRequest($event)"></prognosis-form>

<div class="flex flex-row gap-5 p-5">

    <div class="flex flex-col w-1/2">
        <div class="py-5">
            <h1>Prognose: <span *ngIf="name">{{name}} am {{date | date: 'dd. MMMM yyyy'}} um {{time}} Uhr</span></h1>
        </div>
        <table mat-table [dataSource]="dataSourcePrognosis" class="mat-elevation-z1 mb-5">

            <!-- Site Column -->
            <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef> Parkraum </th>
                <td mat-cell *matCellDef="let element">
                    <b> {{element.site.station.name}} </b><br>
                    <small> {{element.site.name}} </small>
                </td>
            </ng-container>

            <!-- Prognosis Column -->
            <ng-container matColumnDef="prognosis">
                <th mat-header-cell *matHeaderCellDef> Prognostiziert </th>
                <td mat-cell *matCellDef="let element">
                    <div class="pit-cell cat-{{element.occupancyCategory}}">
                        <b> {{element.vacancy}} </b>
                        <small> {{element.occupancyRate | percent}} </small>
                        <span  *ngIf="!validData">
                            Keine Daten vorhanden.
                        </span>
                    </div>
                </td>
            </ng-container>

            <!-- Time Segment Column -->
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef> Zeit </th>
                <td mat-cell *matCellDef="let element">
                    <b> {{element.timeSegment | date: 'HH:mm'}} Uhr </b><br>
                    <small> {{element.timeSegment | date: 'dd.MM.yyyy'}} </small>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsPrognosis;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPrognosis;"></tr>

        </table>
        <div class="py-5">
            <h1>Qualität der Prognose</h1>
        </div>
        <mat-card class="!flex flex-row justify-between">
            <div>
                <mat-icon *ngFor="let star of stars">{{star}}</mat-icon>
            </div>
            <div>
                ({{quality}} von 5 Sternen)
            </div>
        </mat-card>
        <mat-card>{{qualityMessage}}</mat-card>
    </div>                

    <div class="flex flex-col w-1/2">
        <div class="py-5">
            <h1>Datenbasis</h1>
        </div>
        <table mat-table [dataSource]="dataSourceDataBase" class="mat-elevation-z1" *ngIf="validData">

            <!-- Site Column -->
            <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef> Parkraum </th>
                <td mat-cell *matCellDef="let element">
                    <b> {{element.site.station.name}} </b><br>
                    <small> {{element.site.name}} </small>
                </td>
            </ng-container>

            <!-- Used Occupancies Column -->
            <ng-container matColumnDef="occupancy">
                <th mat-header-cell *matHeaderCellDef> Belegung </th>
                <td mat-cell *matCellDef="let element">
                    <div class="pit-cell cat-{{element.occupancyCategory}}">
                        <b> {{element.vacancy}} </b>
                        <small> {{element.occupancyRate | percent: '1.0-0' : 'en'}} </small>
                    </div>
                </td>
            </ng-container>

            <!-- Time Segment Column -->
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef> Zeit </th>
                <td mat-cell *matCellDef="let element">
                    <b> {{element.timeSegment | date: 'HH:mm'}} Uhr </b><br>
                    <small> {{element.timeSegment | date: 'dd.MM.yyyy'}} </small>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDataBase;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDataBase;"></tr>

        </table>
    </div>

</div>