<alarm-details-form #defaultDateFrom (alarmDetailsRequest)="onSubmitAlarmDetailsRequest($event)"></alarm-details-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5 z-10">
    <h1>Alarmliste: {{site?.name}} von {{from | date: 'dd. MMMM yyyy'}} bis {{until | date: 'dd. MMMM yyyy'}} |
        Schwellwert: {{threshold}}</h1>
</div>

<div class="flex flex-col px-5 mb-5 w-[40%]">

            <table mat-table [dataSource]="dataSource" class="alarm-details-table mat-elevation-z1">

                <!-- Capacity Column -->
                <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef> Kapazität </th>
                    <td mat-cell *matCellDef="let element"><b> {{element.capacity}} </b></td>
                </ng-container>

                <!-- Occupancy Column -->
                <ng-container matColumnDef="occupancy">
                    <th mat-header-cell *matHeaderCellDef> Belegt </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="pit-cell cat-{{element.category || 0}}">
                            <b> {{element.occupancy}} </b>
                            <small> {{element.occupancyRate | percent}} </small>
                        </div>
                    </td>
                </ng-container>

                <!-- Vacancy Column -->
                <ng-container matColumnDef="vacancy">
                    <th mat-header-cell *matHeaderCellDef> Frei </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="pit-cell cat-{{element.category || 0}}">
                            <b> {{element.vacancy}} </b>
                        </div>
                    </td>
                </ng-container>

                <!-- Time Segment Column -->
                <ng-container matColumnDef="timeSegment">
                    <th mat-header-cell *matHeaderCellDef> Zeit </th>
                    <td mat-cell *matCellDef="let element">
                        <b> {{element.timeSegment | date: 'HH:mm:ss'}} </b><br>
                        <small>{{element.timeSegment | date: 'dd.MM.yyyy'}}</small>
                    </td>
                </ng-container>

                <!-- Below Threshold Column -->
                <ng-container matColumnDef="belowThreshold">
                    <th mat-header-cell *matHeaderCellDef> Alarm </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.belowThreshold" class="pit-cell cat-{{element.category}}">
                            Alarm
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

</div>