<mat-dialog-content class="!flex flex-col">

    <mat-form-field appearance="outline" class="mb-5">
        <mat-label *ngIf="selectedFile"> {{ selectedFile.name }} </mat-label>
        <mat-label *ngIf="!selectedFile"> Datei auswählen ... </mat-label>
        <input matInput disabled />
        <button mat-icon-button matSuffix (click)="fileInput.click()">
            <mat-icon>folder_open</mat-icon>
        </button>
        <input hidden (change)="selectFile($event)" #fileInput type="file" id="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
    </mat-form-field>
    
    <!-- Import success or failed partially -->
    <mat-card *ngIf="success" [ngClass]="{'error-snack': success.failed > 0, 'success-snack': success.failed === 0}">
        <mat-card-content *ngIf="success.failed === 0">
            <p><b>{{ selectedFile.name }}</b> wurde erfolgreich importiert.</p>
        </mat-card-content>
        <mat-card-content *ngIf="success.failed > 0">
            <p>Datei wurde nicht vollständig importiert. Es wurden <b>{{success.failed}} Fehler</b> in folgenden Zeilen gefunden:</p>
            <ul>
                <li *ngFor="let message of success.userMessages">{{message}}</li>
            </ul>
            <p *ngIf="success.success > 0">{{success.success}} Zeilen wurden erfolgreich importiert.</p>
        </mat-card-content>
    </mat-card>

    <!-- Import failed complete -->
    <mat-card *ngIf="failed" class="error-snack">
        <mat-card-content>
            <p>Datei wurde nicht importiert.</p>
            <ul>
                <li *ngFor="let message of failed.userMessages">{{message}}</li>
            </ul>
        </mat-card-content>
    </mat-card>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="success">Schließen <span *ngIf="autoClose">({{countdown}})</span></button>
    <button mat-flat-button color="primary" [disabled]="autoClose" (click)="import()">Importieren</button>
</mat-dialog-actions>