<alarm-list-form #defaultDateFrom (alarmListRequest)="onSubmitAlarmListRequest($event)"></alarm-list-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5 z-10">
    <h1>Alarmliste: Von {{from | date: 'dd. MMMM yyyy'}} bis {{until | date: 'dd. MMMM yyyy'}} |
        Schwellwert: {{threshold}}</h1>
</div>

<div class="flex flex-col px-5 mb-5 w-[40%]">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

        <!-- Site Column -->
        <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef> Parkraum </th>
            <td mat-cell *matCellDef="let element">
                <b> {{element.site.station?.name}} </b><br>
                <small> {{element.site.name}} </small>
            </td>
        </ng-container>

        <!-- Days Column -->
        <ng-container matColumnDef="days">
            <th mat-header-cell *matHeaderCellDef> Unterschiedliche Tage </th>
            <td mat-cell *matCellDef="let element"><b> {{element.days}} </b></td>
        </ng-container>

        <!-- Count Column -->
        <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef> Stunden Unterschreitung </th>
            <td mat-cell *matCellDef="let element"><b> {{element.count}} </b></td>
        </ng-container>

        <!-- Chart Button Column -->
        <ng-container matColumnDef="chartButton">
            <th mat-header-cell *matHeaderCellDef> Diagramm </th>
            <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" aria-label="View chart button" (click)="openChartDialog(element)">
                    <mat-icon>bar_chart</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>