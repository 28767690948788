import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@core/states/auth/auth.state';
import { UserState } from '@core/states/user/user.state';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { User } from './user-dialog/user.interface';
import { ModeSwitcherService } from '@shared/services/mode-switcher.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    isAuthenticated$: Observable<boolean>;
    user$: Observable<User>;
    mode$: Observable<string>;

    constructor(
        private store: Store,
        public dialog: MatDialog,
        private modeService: ModeSwitcherService
    ) { }

    ngOnInit(): void {
        this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
        this.user$ = this.store.select(UserState);
        this.mode$ = this.modeService.getCurrent();
    }

    openUserDialog() {
        this.dialog.open(UserDialogComponent, { data: this.user$ });
    }

}
