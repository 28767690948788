import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';

@Component({
	selector: 'app-import-button',
	templateUrl: './import-button.component.html',
	styleUrls: ['./import-button.component.scss']
})
export class ImportButtonComponent {

	@Input() path: string = ''
	@Output() closedDialog = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog
	) { }

	openDialog(): void {
		
		const dialogRef =  this.dialog.open(ImportDialogComponent, {
			width: '33.33vw',
			data: this.path
		});

		dialogRef.afterClosed().subscribe(result => {
			this.closedDialog.emit(result);
		});
	}

}
