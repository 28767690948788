<mat-toolbar class="sticky top-0 z-50 mat-elevation-z2 flex flex-col justify-between items-center" color="primary">

    <div class="flex flex-row">
        
        <img [routerLink]="'/home'" class="dbbp-logo" src="assets/db-logo.svg" height="30" width="auto" alt="Logo" [style.margin-right.px]="8" />
        {{(mode$ | async) === 'PIT_STANDARD' ? 'PIT' : 'Parkspotting'}}
        
        <mode-switcher *ngIf="isAuthenticated$ | async" [matTooltip]="'Modus wechseln'"></mode-switcher>

        <div class="ms-4" *ngIf="isAuthenticated$ | async">
            <a mat-button [routerLink]="'/occupancy'" routerLinkActive="active"> Aktuelle Belegung </a>
            <a mat-button [matMenuTriggerFor]="menuHistory" [class.active]="day.isActive || sparklines.isActive || week.isActive || range.isActive || weekmonth.isActive || weekyear.isActive || grouping.isActive"> Historie </a>
            <mat-menu #menuHistory="matMenu">
                <a mat-menu-item [matMenuTriggerFor]="historyDay"> Historie Tag </a>
                <mat-menu #historyDay="matMenu">
                    <a mat-menu-item 
                    [routerLink]="'/history/day'" 
                    routerLinkActive="active" 
                    #day="routerLinkActive">Ganglinien</a>
                    <a mat-menu-item 
                    [routerLink]="'/history/sparklines'" 
                    routerLinkActive="active" 
                    #sparklines="routerLinkActive"
                    [hidden]="(mode$ | async) === 'PIT_PARKSPOTTING' ? false : true">Sparklines</a>
                </mat-menu>
                <a mat-menu-item [routerLink]="'/history/week'" routerLinkActive="active" #week="routerLinkActive"> Historie Woche </a>
                <a mat-menu-item [routerLink]="'/history/range'" routerLinkActive="active" #range="routerLinkActive"> Historie Bereich </a>
                <a mat-menu-item [routerLink]="'/history/weekmonth'" routerLinkActive="active" #weekmonth="routerLinkActive"> ∅ Woche (Monat) </a>
                <a mat-menu-item [routerLink]="'/history/weekyear'" routerLinkActive="active" #weekyear="routerLinkActive"> ∅ Woche (Jahr) </a>
                <a mat-menu-item [routerLink]="'/history/grouping'" routerLinkActive="active" #grouping="routerLinkActive"> Zusammenfassung </a>
            </mat-menu>
            <a mat-button [routerLink]="'/prognosis'" routerLinkActive="active"> Prognose </a>
            <a mat-button [matMenuTriggerFor]="menuAlarm" [class.active]="list.isActive || details.isActive"> Alarm </a>
                <mat-menu #menuAlarm="matMenu">
                    <a mat-menu-item [routerLink]="'/alarm/list'" routerLinkActive="active" #list="routerLinkActive"> Liste </a>
                    <a mat-menu-item [routerLink]="'/alarm/details'" routerLinkActive="active" #details="routerLinkActive"> Details </a>
                </mat-menu>
            <a mat-button [routerLink]="'/failure'" routerLinkActive="active"> Ausfallrate </a>
            <a mat-button [routerLink]="'/capacity'" routerLinkActive="active"> Kapazität </a>
            <a mat-button [routerLink]="'/dashboard'" routerLinkActive="active"> Dashboard </a>
            <a mat-button [routerLink]="'/happyornot'" routerLinkActive="active" [hidden]="(mode$ | async) === 'PIT_PARKSPOTTING' ? true : false"> HoN </a>
            <a *ngIf="isDev" mat-button [routerLink]="'/counted-occupancy'" routerLinkActive="active" [hidden]="(mode$ | async) === 'PIT_PARKSPOTTING' ? false : true"> Zähldaten </a>
            <a *ngIf="isDev" mat-button [matMenuTriggerFor]="menuSpot" [class.active]="cluster.isActive || model.isActive || spaces.isActive" [hidden]="(mode$ | async) === 'PIT_PARKSPOTTING' ? false : true"> Spot </a>
            <mat-menu #menuSpot="matMenu">
                <a mat-menu-item [routerLink]="'/spot/cluster'" routerLinkActive="active" #cluster="routerLinkActive"> Cluster </a>
                <a mat-menu-item [routerLink]="'/spot/model'" routerLinkActive="active" #model="routerLinkActive"> Modell </a>
                <a mat-menu-item [routerLink]="'/spot/spaces'" routerLinkActive="active" #spaces="routerLinkActive"> Parkräume </a>
            </mat-menu>
        </div>
    </div>

    <div class="flex flex-row items-center" *ngIf="isAuthenticated$ | async"> 

        <app-clock class="me-4"></app-clock>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Account" *ngIf="user$">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item aria-label="Benutzer" (click)="openUserDialog()">
                <mat-icon>person</mat-icon> {{(user$ | async)?.username}}
            </button>
            <logout-button></logout-button>
        </mat-menu>

    </div>

</mat-toolbar>