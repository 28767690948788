import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ModeSwitcherService {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) { }

	getCurrent(): Observable<string> {
		return this.route.queryParamMap.pipe(
			map((params: ParamMap) => params.get('mode') || 'PIT_STANDARD'),
		);
	}

	switch(mode: string) {
		const url = this.router.url.split('?')[0];
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate([url], {
				relativeTo: this.route,
				queryParams: { mode: mode }
			})
		);
	}

}
