<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="alarm-details-form" (ngSubmit)="submitAlarmDetailsRequest()">

        <div class="flex flex-row">

            <!-- Site select box -->
            <div class="w-[25%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Parkraum</mat-label>
                    <mat-select formControlName="site" disableOptionCentering required>
                        <mat-option *ngFor="let station of stationList" [value]="station">
                            {{station.name}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="form.controls['site'].invalid">Bitte wählen Sie einen Parkraum aus.</mat-error> -->
                </mat-form-field>
            </div>

            <!-- From Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Von</mat-label>
                    <input matInput [matDatepicker]="datepickerFrom" formControlName="dateFrom" 
                    [max]="maxDateFrom" (dateChange)="setLimitations()" (dateChange)="formatDate($event.value, 'dateFrom')" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepickerFrom">
                        <mat-icon matDatepickerToggleIcon>today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerFrom (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- To Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Bis</mat-label>
                    <input matInput [matDatepicker]="datepickerUntil" formControlName="dateUntil" 
                    [min]="minDateUntil" [max]="maxDateUntil" (dateChange)="setLimitations()" (dateChange)="formatDate($event.value, 'dateUntil')" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepickerUntil">
                        <mat-icon matDatepickerToggleIcon>event</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerUntil (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Threshold -->
            <div class="w-[7%] me-5">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Schwellwert</mat-label>
                    <input matInput type="number" value="{{defaultThreshold}}" min="1" max="100" formControlName="threshold">
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <!-- EXCEL download button -->
            <div class="w-[18%] flex justify-end items-center">
                <button type="button" mat-flat-button 
                    exportButton 
                    [path]="'sites/' + form.value.site.id + '/thresholds/' + form.value.dateFrom + '/' + form.value.dateUntil + '/' + form.value.threshold" 
                    [format]="'excel'">
                    <mat-icon>file_download</mat-icon> Excel
                </button>
            </div>

        </div>

    </form>



</mat-card>
