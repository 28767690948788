import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'app-history-range',
  templateUrl: './history-range.component.html',
  styleUrls: ['./history-range.component.scss']
})
export class HistoryRangeComponent implements OnInit {

    site: any = null;
    from: string | null = null;
    until: string | null = null;

    displayedColumns: string[] = [
        'timeSegment',
        'capacity',
        'occupancy',
        'vacancy'
    ];

    dataSource = new MatTableDataSource<any>();

    itemsCount: number
    pageSize: number = 200
    pageSizeOptions: number[] = [200, 500, 1000, 2000]
    pageEvent: PageEvent;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
    }

    onSubmitHistoryRangeRequest($event: any) {
        
        this.site = $event.site;
        this.from = $event.from;
        this.until = $event.until;

        this.dataService.getHistoryRangeById(this.site.id, this.from, this.until).subscribe(
            (data: any) => {
                this.dataSource.data = data.series
                this.itemsCount = data.series.length
                this.dataSource.paginator = this.paginator;
            }
        )

    }

}
