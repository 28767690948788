import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataService } from '@core/services/data.service';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import * as moment from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'dd, LL',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'history-sparklines-form',
	templateUrl: './history-sparklines-form.component.html',
	styleUrls: ['./history-sparklines-form.component.scss'],
	providers: [{
		provide: DateAdapter,
		useClass: MomentDateAdapter,
		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
	}, {
		provide: MAT_DATE_FORMATS,
		useValue: CUSTOM_FORMATS
	}]
})
export class HistorySparklinesFormComponent implements OnInit {

	@Output() historySparklinesRequest = new EventEmitter<any>();

	form: UntypedFormGroup
	// default date is yesterday
	defaultDate: string = moment().subtract(1, 'day').format('YYYY-MM-DD');
	// max date today
	maxDate: string = moment().format('YYYY-MM-DD');

	stationList: any

	defaultSite: any

	constructor(
		private dataService: DataService,
		private formBuilder: UntypedFormBuilder,
		private orderBy: OrderByPipe
	) { }

	ngOnInit(): void {
		this.form = new UntypedFormGroup({});
		this.form = this.formBuilder.group({
			site: new UntypedFormControl('', [Validators.required]),
			date: new UntypedFormControl(this.defaultDate, [Validators.required])
		})

		this.dataService.getSites().subscribe(
			(data: any) => {
				this.stationList = this.orderBy.transform(
					data.content.map((i: any) => ({
						id: i.id,
						name: `${i.station.name} ${i.name}`,
						visibility: i.visibility == 'ONLINE' ? true : false
					})),
					'name',
					false
				);
				this.defaultSite = this.stationList[0];
				this.form.controls['site'].setValue(this.defaultSite);
				this.historySparklinesRequest.emit({
					site: this.defaultSite,
					date: this.defaultDate
				});
			}
		)
	}

	submitHistorySparklinesRequest(): void {
		this.historySparklinesRequest.emit({
			site: this.form.value.site,
			date: moment(this.form.value.date).format('YYYY-MM-DD')
		});
	}

	startWeekOnMonday(event: any) {
		this.form.value.date = new Date(event.getFullYear(), event.getMonth(), 1);
	}

	formatDate(date: string) {
		this.form.value.date = date ? moment(date).format('YYYY-MM-DD') : '';
	}

}
