import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    
    constructor(
        private readonly spinnerService: SpinnerService
    ) { }

    intercept(
        request:HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        // https://github.com/angular/angular/issues/18155#issuecomment-326136484
        if (request.headers.has(InterceptorSkipHeader)) {
            const value = request.headers.get(InterceptorSkipHeader);
            if ( value === 'spinner') {
                const headers = request.headers.delete(InterceptorSkipHeader);
                return next.handle(request.clone({ headers }));
            }
        }

        const spinnerSubscription: Subscription = this.spinnerService.spinner$.subscribe();
        return next
            .handle(request)
            .pipe(finalize(() => spinnerSubscription.unsubscribe()));
    }
}