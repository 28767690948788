import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '@core/services/data.service';

import { MatTableDataSource } from '@angular/material/table';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import { Observable } from 'rxjs';
import { ModeSwitcherService } from '@shared/services/mode-switcher.service';
import * as moment from 'moment';

@Component({
    selector: 'app-occupancy',
    templateUrl: './occupancy.component.html',
    styleUrls: ['./occupancy.component.scss']
})
export class OccupancyComponent implements OnInit, OnDestroy {

    interval: number;
    delay: number = 10 * 60000; // for all components incl. children

    mode$: Observable<string>
    
    activeLabel: string
    label_comparison: string = 'KZP & DP im Vergleich'
    label_summedup: string = 'KZP & DP summiert'
    label_app_export: string = 'Zähldaten Export'

    displayedColumns: string[] = [
        'site',
        'capacity',
        'occupancy',
        'vacancy',
        'timestamp'
    ]
    dataSource = new MatTableDataSource<any>();

    constructor(
        private dataService: DataService,
        private orderBy: OrderByPipe,
        private modeService: ModeSwitcherService
    ) { }

    ngOnInit(): void {

        this.mode$ = this.modeService.getCurrent();
        this.updateOccupancy();
        this.interval = window.setInterval(() => { this.updateOccupancy() }, this.delay)

    }

    ngOnDestroy(): void {
        if (this.interval) window.clearInterval(this.interval)
    }

    updateOccupancy() {
        this.dataService.getOccupancies().subscribe(
            (data: any) => {
                data.map((entry: any) => {
                    entry.occupancy.hasDowntime = this.hasDowntime(entry.occupancy.timeSegment)
                })
                this.dataSource.data = this.orderBy.transform(data, 'site.station.name', false);
            }
        )
    }

    /** Get selected tab index */
    tabIndex(event: any) {
        switch (event) {
            case 0:
                this.activeLabel = this.label_comparison;
                break;
            case 1:
                this.activeLabel = this.label_summedup;
                break;
            case 2:
                this.activeLabel = this.label_app_export;
                break;
            default:
                break;
        }
    }
    
	/** TODO: Do better job - maybe service? */
	hasDowntime(timeSegment: string): boolean {

		if ( moment(timeSegment).isValid() ) {
			const now = moment();
			const duration = moment.duration(now.diff(timeSegment));
			const hours = duration.asHours();

			if ( hours >= 5 ) return true
			
			return false
		}

		return false
	}
}
