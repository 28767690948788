<history-weekyear-form #defaultDate (historyWeekyearRequest)="onSubmitHistoryWeekyearRequest($event)"></history-weekyear-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Durchschnittswoche (Jahr): {{site?.name}} im Jahr {{date | date: 'yyyy'}}</h1>
</div>

<div class="flex flex-col px-5 mb-5">

    <table mat-table [dataSource]="dataSource" class="history-weekyear-table mat-elevation-z1">
        
        <!-- Hours Column -->
        <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; index as i">
                <span>{{dayInterval[i]}}</span>
            </td>
        </ng-container>

        <!-- Series Columns -->
        <ng-container *ngFor="let segment of timeSegments; index as i" matColumnDef="{{segment}}">
            <th mat-header-cell *matHeaderCellDef> {{segment}} </th>
            <td mat-cell *matCellDef="let element">
                <div class="pit-cell cat-{{element[i].category || 0}}">
                    <span *ngIf="element[i].dataStatus === 'VALID'">
                        <b> {{element[i].vacancy}} </b><br>
                        <small> {{element[i].occupancyRate | percent: '1.0-0' : 'en'}} </small>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>