import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { AlarmListFormComponent } from './alarm-list-form/alarm-list-form.component';
import { AlarmlistChartDialogComponent } from './alarm-list-chart-dialog/alarmlist-chart-dialog.component'; 

@Component({
    selector: 'app-alarm-list',
    templateUrl: './alarm-list.component.html',
    styleUrls: ['./alarm-list.component.scss']
})
export class AlarmListComponent implements OnInit {

    @ViewChild(AlarmListFormComponent) child: AlarmListFormComponent;

    from: string | null = null;
    until: string | null = null;
    threshold: number | null = null;

    displayedColumns: string[] = [
        'site',
        'days',
        'count',
        'chartButton'
    ]
    dataSource = new MatTableDataSource<any>();

    constructor(
        private dataService: DataService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {

    }

    onSubmitAlarmListRequest($event: any) {
        this.from = $event.from;
        this.until = $event.until;
        this.threshold = $event.threshold
  
        this.dataService.getThresholds(this.from, this.until, this.threshold).subscribe(
            (data: any) => {
                this.dataSource = data.sort((a: any, b: any) => {
                    return a.site.station.name.localeCompare(b.site.station.name);
                });
            }
        )
    }

    openChartDialog(element:any) {
        this.dialog.open(AlarmlistChartDialogComponent, { 
            width: '90vw',
            data: {
                item: element,
                dateUntil: this.until,
                threshold: this.threshold
            }
        });
    }

}
