
<div class="flex justify-center items-center h-[calc(100vh-128px)]">
    <mat-card class="w-[400px] mat-elevation-z1">
        <form (ngSubmit)="login()" class="login-form">
            <mat-card-content>
                <mat-form-field class="username-form-field" appearance="outline">
                    <mat-label>Benutzername</mat-label>
                    <input matInput type="text" [(ngModel)]="userInputValue" name="username" tabindex="1" />
                    <a *ngIf="userInputValue" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="userInputValue = ''" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>
                <mat-form-field class="password-form-field" appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <input matInput type="password" [(ngModel)]="passwordInputValue"
                        [type]="hideVisibility ? 'password' : 'text'" name="password" tabindex="2" />
                    <a *ngIf="passwordInputValue" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="passwordInputValue = ''" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                    <a matSuffix mat-icon-button tabindex="-1"> 
                        <mat-icon (click)="hideVisibility = !hideVisibility" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hideVisibility">
                            {{hideVisibility ? "visibility_off" : "visibility"}}</mat-icon>
                    </a>
                </mat-form-field>
            </mat-card-content>
            <button type="submit" name="submit" mat-raised-button color="primary" tabindex="3">Anmelden</button>
        </form>
    </mat-card>
</div>

