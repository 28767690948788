import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { OccupancyComponent } from './occupancy/occupancy.component';
import { PrognosisComponent } from './prognosis/prognosis.component';
import { FailureComponent } from './failure/failure.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlarmListComponent } from './alarm/alarm-list/alarm-list.component';
import { CapacityComponent } from './capacity/capacity.component';
import { AlarmDetailsComponent } from './alarm/alarm-details/alarm-details.component';
import { HonComponent } from './hon/hon.component';
import { HistoryDayComponent } from './history/history-day/history-day.component';
import { HistoryWeekComponent } from './history/history-week/history-week.component';
import { HistoryRangeComponent } from './history/history-range/history-range.component';
import { HistoryWeekmonthComponent } from './history/history-weekmonth/history-weekmonth.component';
import { HistoryWeekyearComponent } from './history/history-weekyear/history-weekyear.component';
import { HistoryGroupingComponent } from './history/history-grouping/history-grouping.component';
import { ModeGuard } from '@core/guards/mode.guard';
import { HistorySparklinesComponent } from './history/history-day/parkspotting/history-sparklines/history-sparklines.component';
import { RoleGuard } from '@core/guards/role.guard';
import { CountedOccupancyComponent } from './counted-occupancy/counted-occupancy.component';
import { SpotClusterComponent } from './spot/spot-cluster/spot-cluster.component';
import { SpotModelComponent } from './spot/spot-model/spot-model.component';
import { SpotSpacesComponent } from './spot/spot-spaces/spot-spaces.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        // loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'occupancy',
        component: OccupancyComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'history/day',
        component: HistoryDayComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'history/sparklines',
        component: HistorySparklinesComponent,
        canActivate: [AuthGuard, RoleGuard, ModeGuard],
        data: { modes: ['PIT_PARKSPOTTING'] }
    },
    {
        path: 'history/week',
        component: HistoryWeekComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'history/range',
        component: HistoryRangeComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'history/weekmonth',
        component: HistoryWeekmonthComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'history/weekyear',
        component: HistoryWeekyearComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'history/grouping',
        component: HistoryGroupingComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'counted-occupancy',
        component: CountedOccupancyComponent,
        canActivate: [AuthGuard, RoleGuard, ModeGuard],
        data: { modes: ['PIT_PARKSPOTTING'] }
    },
    {
        path: 'spot/cluster',
        component: SpotClusterComponent,
        canActivate: [AuthGuard, RoleGuard, ModeGuard],
        data: { modes: ['PIT_PARKSPOTTING'] }
    },
    {
        path: 'spot/model',
        component: SpotModelComponent,
        canActivate: [AuthGuard, RoleGuard, ModeGuard],
        data: { modes: ['PIT_PARKSPOTTING'] }
    },
    {
        path: 'spot/spaces',
        component: SpotSpacesComponent,
        canActivate: [AuthGuard, RoleGuard, ModeGuard],
        data: { modes: ['PIT_PARKSPOTTING'] }
    },
    {
        path: 'prognosis',
        component: PrognosisComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'alarm/list',
        component: AlarmListComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'alarm/details',
        component: AlarmDetailsComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'failure',
        component: FailureComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'capacity',
        component: CapacityComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'happyornot',
        component: HonComponent,
        canActivate: [AuthGuard, RoleGuard, ModeGuard],
        data: { modes: ['PIT_STANDARD'] }
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: '**',
        component: LoginComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
