<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="history-week-form" (ngSubmit)="submitHistoryWeekRequest()">

        <div class="flex flex-row">

            <!-- Site select box -->
            <div class="w-[25%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Parkraum</mat-label>
                    <mat-select formControlName="site" disableOptionCentering required>
                        <mat-option *ngFor="let station of stationList" [value]="station">
                            <span *ngIf="!station.visibility" class="site-offline-badge">
                                Offline<span style="display:none">:</span>
                            </span> {{station.name}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="form.controls['site'].invalid">Bitte wählen Sie einen Parkraum aus.</mat-error> -->
                </mat-form-field>
            </div>

            <!-- Date Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Von</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="date" 
                    [max]="maxDate" (dateChange)="formatDate($event.value)" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepicker">
                        <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepicker (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <!-- EXCEL download button -->
            <div class="w-[45%] flex justify-end items-center">
                <button type="button" mat-flat-button
                    exportButton 
                    [path]="'sites/' + form.value.site.id + '/occupancies/week/' + form.value.date" 
                    [format]="'excel'">
                    <mat-icon>file_download</mat-icon> Excel
                </button>
            </div>


        </div>

    </form>



</mat-card>