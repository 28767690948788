
<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="prognosis-form" (ngSubmit)="submitPrognosisRequest()">

        <div class="flex flex-row">

            <!-- Site select box -->
            <div class="w-[25%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Parkraum</mat-label>
                    <mat-select formControlName="site" disableOptionCentering required>
                        <mat-option *ngFor="let station of stationList" [value]="station">
                            {{station.name}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="form.controls['site'].invalid">Bitte wählen Sie einen Parkraum aus.</mat-error> -->
                </mat-form-field>
            </div>

            <!-- Datepicker -->
            <div class="w-[18%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Datum</mat-label>
                    <input matInput [matDatepicker]="datepicker" [min]="minDate"  readonly formControlName="date" required />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepicker">
                        <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-raised-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Timepicker -->
            <div class="w-[7%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Uhrzeit</mat-label>
                    <input matInput name="selectedTime" [format]="24" [ngxMatTimepicker]="timepicker" readonly formControlName="time" required />
                    <!-- <mat-error *ngIf="form.controls['time'].invalid">yBitte wählen Sie eine Uhrzeit aus.</mat-error> -->
                    <mat-icon matSuffix for="timepicker" (click)="timepicker.open()" style="color:rgba(0,0,0,.54)">schedule</mat-icon>
                </mat-form-field>
                <ngx-mat-timepicker color="primary" #timepicker [defaultTime]="defaultTime" [minutesGap]="timeGap" [cancelBtnTmpl]="timepickerCancel" [confirmBtnTmpl]="timepickerApply"></ngx-mat-timepicker>
                <ng-template #timepickerCancel>
                    <button mat-button>Schließen</button>
                </ng-template>
                <ng-template #timepickerApply>
                    <button mat-raised-button color="primary">Übernehmen</button>
                </ng-template>                    
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

        </div>

    </form>

</mat-card>