<mat-card class="mat-elevation-z0">

    <div class="flex flex-row">

        <div class="w-[100%] flex justify-end items-center">

            <!-- EXCEL download button -->
            <button type="button" mat-flat-button 
                exportButton 
                [path]="'spot/clusters'" 
                [format]="'excel'">
                <mat-icon>file_download</mat-icon> Export
            </button>

            <!-- EXCEL upload button -->
            <app-import-button path="spot/clusters" (closedDialog)="onClosedDialog($event)"></app-import-button>

        </div>

    </div>

</mat-card>
