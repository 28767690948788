<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <!-- Site Column -->
    <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> Parkbereich </th>
        <td mat-cell *matCellDef="let element">
            <b> {{element.site.station.name}} </b><br>
            <small> {{element.site.name}} </small>
        </td>
    </ng-container>

    <!-- Capacity Column -->
    <ng-container matColumnDef="capacity">
        <th mat-header-cell *matHeaderCellDef> Kapazität </th>
        <td mat-cell *matCellDef="let element"><b> {{element.occupancy.capacity}} </b></td>
    </ng-container>

    <!-- Occupancy Column -->
    <ng-container matColumnDef="occupancy">
        <th mat-header-cell *matHeaderCellDef> Belegt </th>
        <td mat-cell *matCellDef="let element">
            <div class="pit-cell cat-{{element.occupancy.occupancyCategory}}"
            *ngIf="element.occupancy.dataStatus === 'VALID'"
            [ngClass]="{'invalid': element.occupancy.hasDowntime}">
                <ng-container *ngIf="!element.occupancy.hasDowntime">
                    <b> {{element.occupancy.occupancy}} </b>
                    <small> {{element.occupancy.occupancyRate | percent }} </small>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Vacancy Column -->
    <ng-container matColumnDef="vacancy">
        <th mat-header-cell *matHeaderCellDef> Frei </th>
        <td mat-cell *matCellDef="let element">
            <div class="pit-cell cat-{{element.occupancy.occupancyCategory}}"
            *ngIf="element.occupancy.dataStatus === 'VALID'"
            [ngClass]="{'invalid': element.occupancy.hasDowntime}">
                <span *ngIf="!element.occupancy.hasDowntime">
                    <b> {{element.occupancy.vacancy}} </b>
                </span>
            </div>
        </td>
    </ng-container>

    <!-- Timestamp Column -->
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> Zeit </th>
        <td mat-cell *matCellDef="let element">
            <b> {{element.occupancy.timeSegment | date: 'HH:mm:ss'}} </b><br>
            <small>{{element.occupancy.timeSegment | date: 'dd.MM.yyyy'}}</small>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>