import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';

@Component({
    selector: 'feedback-tab',
    templateUrl: './feedback-tab.component.html',
    styleUrls: ['./feedback-tab.component.scss']
})
export class FeedbackTabComponent implements OnInit {

    @Input() spaceId: string;

    feedbacks: any[]
    from: any = moment().subtract(1, 'years').format('YYYY-MM-DD')
    until: any = moment().format('YYYY-MM-DD')

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {

        this.dataService.getFeedbacks(this.spaceId, this.from, this.until).subscribe(
            (data: any) => {
                this.feedbacks = data.content;
            }
        )
    }

}
