import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';
import { HistoryTablePipe } from '../history-table.pipe';

@Component({
    selector: 'app-history-weekyear',
    templateUrl: './history-weekyear.component.html',
    styleUrls: ['./history-weekyear.component.scss']
})
export class HistoryWeekyearComponent implements OnInit {

    site: any = null;
    date: string | null = null;
    from: string | null = null;
    until: string | null = null;

    dayInterval: string[];
    timeSegments: string[];
    displayedColumns: string[];
    dataSource = new MatTableDataSource<any>();

    constructor(
        private dataService: DataService,
        private historyTablePipe: HistoryTablePipe
    ) { }

    ngOnInit(): void {
    }

    onSubmitHistoryWeekyearRequest($event: any) {

        this.site = $event.site;
        this.date = $event.date;

        this.dataService.getHistoryWeekyearById(this.site.id, this.date).subscribe(
            (data: any) => {

                this.from = data.from;
                this.until = data.until;

                // Table data and columns arrays
                this.dayInterval = this.historyTablePipe.tableHead1DayInterval();
                this.timeSegments = this.historyTablePipe.tableHead1HourInterval();
                this.displayedColumns = ['hours', ...this.timeSegments];
                this.dataSource = this.historyTablePipe.transform(data.series, 7, 24);

            }
        )

        console.log($event)
    }

}
