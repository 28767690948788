<div class="flex flex-col p-5">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 dashboard">

        <!-- Series Columns -->
        <ng-container *ngFor="let segment of timeSegments; index as i; last as $last;" matColumnDef="{{segment}}">
            <th mat-header-cell *matHeaderCellDef> {{segment}} </th>
            <td mat-cell *matCellDef="let element">
                <div class="pit-cell cat-{{element.occupancies[i].occupancyCategory || 0}} mat-elevation-z0">
                    <span *ngIf="element.occupancies[i].validData">
                        <b> {{element.occupancies[i].vacancy}} </b><br>
                        <small> {{element.occupancies[i].occupancyRate | percent}} </small>
                    </span>
                    <span *ngIf="!element.occupancies[i].validData && $last">
                        <b> {{element.currentOccupancy.timeSegment | date: "HH:mm" }} </b><br>
                        <small> {{element.currentOccupancy.timeSegment | date: "d.M.yy" }} </small>
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Site Column -->
        <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef> Parkbereich </th>
            <td mat-cell *matCellDef="let element">
                <div matRipple class="pit-cell detail-dialog-button mat-elevation-z0" 
                (click)="openDetailDialog(
                    element.site.id,
                    element.site.space.id,
                    element.site.station.name,
                    element.site.name,
                    element.currentOccupancy.capacity
                    )">
                    <span>
                        <b> {{element.site.station.name}} </b>
                        <span *ngIf="element.feedbackCount"  matTooltip="{{element.feedbackCount}}" [matTooltipPosition]="'right'" class="notification-bullet">
                            <mat-icon>email</mat-icon>
                        </span><br>
                        <small> {{element.site.name}} ({{element.currentOccupancy.capacity}}) </small>
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Current Column -->
        <ng-container matColumnDef="current">
            <th mat-header-cell *matHeaderCellDef> Aktuell </th>
            <td mat-cell *matCellDef="let element">
                <div class="pit-cell cat-{{element.currentOccupancy.occupancyCategory}} mat-elevation-z0" 
                *ngIf="element.currentOccupancy.dataStatus === 'VALID'"
                [ngClass]="{'invalid': element.currentOccupancy.hasDowntime}">
                    <ng-container *ngIf="!element.currentOccupancy.hasDowntime">
                        <b> {{element.currentOccupancy.vacancy}} </b>
                        <small> {{element.currentOccupancy.occupancyRate | percent}} </small>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="26">Keine Übereinstimmung gefunden für den Begriff: "{{input.value}}"</td>
        </tr>

    </table>

    <div id="filter">

        <mat-card class="mat-elevation-z1 !flex flex-row justify-between items-center !mt-5">

            <mat-form-field appearance="outline" class="dashboard-filter w-[30%]" subscriptSizing="dynamic">
                <mat-label><mat-icon>search</mat-icon> Dashboard durchsuchen </mat-label>
                <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>

            <div class="flex flex-row items-center">
                <mat-icon>help</mat-icon>
                <img src="assets/kzp_erlaeuterung.jpg">
            </div>

        </mat-card>

    </div>

</div>

