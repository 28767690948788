import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataService } from '@core/services/data.service';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import * as moment from 'moment';
import 'moment/locale/de';
import { forkJoin } from 'rxjs';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'hon-form',
    templateUrl: './hon-form.component.html',
    styleUrls: ['./hon-form.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, {
        provide: MAT_DATE_FORMATS,
        useValue: CUSTOM_FORMATS
    }]
})
export class HonFormComponent implements OnInit {

    @Output() honRequest = new EventEmitter<any>();

    stationList: any

    defaultSite: number
    defaultInterval: string = 'MONTHS'
    defaultCompare: boolean = false
    yearCompare: any[] = this.arrayOfYearsSince(2019);

    form: UntypedFormGroup
    // default date is yesterday
    date: string = moment().subtract(1, 'days').format('YYYY-MM-DD');
    // default date is yesterday
    defaultDate: string = this.date;
    // max date is today
    maxDate: string = moment().format('YYYY-MM-DD');

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private orderBy: OrderByPipe
    ) { }

    ngOnInit(): void {

        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            site: new UntypedFormControl('', [Validators.required]),
            date: new UntypedFormControl(this.defaultDate, [Validators.required]),
            interval: new UntypedFormControl(this.defaultInterval, [Validators.required]),
            compare: new UntypedFormControl(this.defaultCompare, [Validators.required]),
            year: new UntypedFormControl(this.yearCompare[0], [Validators.required]),
        })

        forkJoin([
            this.dataService.getSites(),
            this.dataService.getHonSurveys()
        ]).subscribe((results: any) => {
            // console.log(results)
            // technically there is no need to remove duplicates, but why not
            const spaceIds = [...new Set(results[1].map((item: any) => item.spaceId))];
            // filter all spaces from result by spaceIds
            const honSites = results[0].content.filter((e: any) => e.space).filter((e: any) => spaceIds.includes(e.space.id));
            // reduce list to id and displayed name, then sort by name
            this.stationList = this.orderBy.transform(
                honSites.map((i: any) => ({ id: i.id, name: `${i.station.name} ${i.name}`, spaceId: i.space.id })),
                'name',
                false
            );

            this.defaultSite = this.stationList[0];
            this.form.controls['site'].setValue(this.defaultSite);
            this.honRequest.emit({
                site: this.defaultSite,
                date: moment(this.defaultDate).format('YYYY-MM-DD'),
                interval: this.defaultInterval,
                compare: this.defaultCompare,
                year: this.yearCompare[0]
            });
        })


    }

    submitHonRequest(): void {
        this.honRequest.emit({
            site: this.form.value.site,
            date: moment(this.form.value.date).format('YYYY-MM-DD'),
            interval: this.form.value.interval,
            compare: this.form.value.compare,
            year: this.form.value.year
        });
    }

    startWeekOnMonday(event: any) {
        this.form.value.dateFrom = new Date(event.getFullYear(), event.getMonth(), 1);
    }

    arrayOfYearsSince(min: number): number[] {
        let years = [];
        let max = new Date().getFullYear() - 1;
        for (let i = max; i >= min; i--) {
            years.push(i)
        }
        return years;
    }
}
