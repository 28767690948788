import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeartbeatService } from '@core/services/heartbeat.service';
import { AuthState } from '@core/states/auth/auth.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

	isAuthenticated$: Observable<boolean>;
	
    constructor(
		private store: Store,
        private hearbeatService: HeartbeatService
    ) { }

	ngOnInit(): void {
	
		this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
		// TODO: interceptor bei http request unterdrücken
		this.isAuthenticated$.subscribe((auth) => {
			if (auth) this.hearbeatService.start()
			else this.hearbeatService.stop()
		})

	}

}
