import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartTooltipModel, ChartTooltipOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

import { DataService } from '@core/services/data.service';

import * as moment from 'moment';
import 'moment/locale/de';

@Component({
    selector: 'alarm-tab',
    templateUrl: './alarm-tab.component.html',
    styleUrls: ['./alarm-tab.component.scss']
})
export class AlarmTabComponent implements OnInit {

    @Input() siteId: number;

    series: any[]
    from: string
    until: string

    alarmListChartLabels: Label[];
    alarmListChartData: ChartDataSets[];
    alarmListChartType: ChartType = 'bar';
    alarmListChartLegend = false;
    alarmListChartOptions: ChartOptions;

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {

        this.from = moment().subtract(31, 'days').format('YYYY-MM-DD')
        this.until = moment().subtract(1, 'days').format('YYYY-MM-DD')

        this.dataService.getThresholdsById(this.siteId, this.from, this.until, 10).subscribe(
            (data: any) => {

                this.series = data.series

                let hoursPerDay = [];
                let daysPerMonth = [];
                let datasets = [];

                for (let i = 0; i < this.series.length; i++) {
                    let day = moment(this.series[i].timeSegment).format('L');
                    let hour = moment(this.series[i].timeSegment).format('LT');
                    if (daysPerMonth.includes(day) === false) {
                        daysPerMonth.push(day);
                    }
                    if (hoursPerDay.includes(hour) === false) {
                        hoursPerDay.push(hour);
                    }
                }
                this.alarmListChartLabels = daysPerMonth


                for (let i = 0; i < hoursPerDay.length; i++) {

                    let colors = [];
                    let hours = [];
                    let belowThresholds = [];

                    for (let j = 0; j < this.series.length; j++) {
                        let color, threshold, hour = moment(this.series[j].timeSegment).format('LT');
                        if (this.series[j].thresholdStatus === 'BELOW' && this.series[j].dataStatus === 'VALID') {
                            color = '#ff4800';
                            threshold = true;
                        } else if (this.series[j].thresholdStatus === 'BELOW' && this.series[j].dataStatus === 'INVALID') {
                            color = '#d4d4d4';
                            threshold = true;
                        } else {
                            color = 'transparent';
                            threshold = false;
                        }
                        if (hoursPerDay[i] === hour) {
                            colors.push(color);
                            hours.push(1);
                            belowThresholds.push(threshold);
                        }
                    }

                    datasets.push({
                        xAxisID: 'bottom-x-axis',
                        belowThresholds: belowThresholds,
                        data: hours,
                        backgroundColor: colors,
                        hoverBackgroundColor: colors
                    });
                }

                this.alarmListChartData = datasets


                this.alarmListChartOptions = {
                    title: {
                        display: true
                    },
                    tooltips: {
                        custom: (tooltip: ChartTooltipModel) => {
                            if (!tooltip) return;
                            tooltip.displayColors = false;
                            // tooltip.xAlign = 'center';
                            // tooltip.yAlign = 'bottom';
                        },
                        callbacks: {
                            title: (tooltipItem: any, data: any) => {
                                if (!data) return;
                                return data.labels[tooltipItem[0].index];
                            },
                            label: (tooltipItem, data) => {
                                let count = this.countBelowThresholdsPerDay(this.series, data.labels[tooltipItem.index]);
                                return `Insgesamt ${(count !== 1 ? `${count} Stunden` : `${count} Stunde`)} Alarm ausgelöst.`;
                            }
                        }
                    },
                    scales: {
                        yAxes: [{
                            stacked: true,
                            gridLines: {
                                color: '#dedede',
                                zeroLineColor: '#dedede'
                            },
                            scaleLabel: {
                                display: true,
                                labelString: '24 Stunden',
                                fontColor: '#424242',
                            },
                            ticks: {
                                min: 0,
                                max: 24,
                                stepSize: 1,
                                beginAtZero: true,
                                fontColor: '#424242',
                            }
                        }],
                        xAxes: [{
                            id: 'bottom-x-axis',
                            stacked: true,
                            gridLines: {
                                color: '#dedede',
                                zeroLineColor: '#dedede'
                            },
                            scaleLabel: {
                                display: true,
                                labelString: '31 Tage',
                                fontColor: '#424242',
                            },
                            ticks: {
                                min: 1,
                                max: 31,
                                stepSize: 1,
                                beginAtZero: false,
                                fontColor: '#424242',
                                callback: (_, index) => {
                                    return index + 1;
                                }
                            }
                        }, {
                            id: 'top-x-axis',
                            gridLines: {
                                drawOnChartArea: false,
                                offsetGridLines: true,
                                color: '#dedede',
                            },
                            scaleLabel: {
                                display: true,
                                labelString: ''
                            },
                            stacked: true,
                            position: 'top',
                            offset: true, // to align both x axes
                            ticks: {
                                min: 1,
                                max: 31,
                                stepSize: 1,
                                beginAtZero: false,
                                fontColor: '#ff4800',
                                fontSize: 20,
                                callback: (value) => {
                                    let belowThresholdCount = this.countBelowThresholdsPerDay(this.series, value);
                                    if (belowThresholdCount === 0) {
                                        return '';
                                    }
                                    return belowThresholdCount;
                                }
                            }
                        }]
                    }
                } // end of options
            }
        )
    }

    countBelowThresholdsPerDay(series: any, day: any): number {
        let belowThresholdCount = 0;
        for (let i = 0; i < series.length; i++) {
            if (day === moment(series[i].timeSegment).format('L')) {
                if (series[i].belowThreshold === true) {
                    belowThresholdCount += 1;
                }
            }
        }
        return belowThresholdCount;
    }

}
