import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { GetFailureSites, RemoveFailureSites, FailureStateModel } from './failure.action';
import { DataService } from '@core/services/data.service';

@State<FailureStateModel>({
    name: 'failure',
    defaults: {
        from: null,
        until: null,
        data: null
    }
})
@Injectable()
export class FailureState {

    @Selector()
    static getFailureSites(state: FailureStateModel): any {
        return state;
    }

    constructor(
        private dataService: DataService
    ) { }

    @Action(GetFailureSites)
    getFailureSites(ctx: StateContext<FailureStateModel>, action: GetFailureSites) {

        const currentState = ctx.getState();

        // console.log('Failure state', currentState)

        if ( typeof currentState !== 'undefined' && currentState.data !== null ) return false

        return this.dataService.getFailures(action.from, action.until).pipe(
            tap((result) => {
                result.failureSites.sort((a: any, b: any) => {
                    return a.site.station.name.localeCompare(b.site.station.name);
                });
                ctx.setState({
                    from: action.from,
                    until: action.until,
                    data: result.failureSites
                });
            })
        );
    }

    @Action(RemoveFailureSites)
    removeFailureSites(ctx: StateContext<FailureStateModel>) {
        return ctx.patchState({ from: null, until: null, data: null });
    }

}