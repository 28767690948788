import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '@core/services/data.service';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import * as moment from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'history-grouping-form',
    templateUrl: './history-grouping-form.component.html',
    styleUrls: ['./history-grouping-form.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, {
        provide: MAT_DATE_FORMATS,
        useValue: CUSTOM_FORMATS
    }]
})
export class HistoryGroupingFormComponent implements OnInit {

    @Output() historyGroupingRequest = new EventEmitter<any>();
    @Input('failures') failures: any[] = []; // tslint:disable-line: no-input-rename

    collapsed: boolean = true;
    disabled: boolean = true;

    form: UntypedFormGroup
    // default date is today
    defaultDate: string = moment().format('YYYY-MM-DD');
    // max date today
    maxDate: string = moment().format('YYYY-MM-DD');

    sitesList: any
    // stationIds: number[]
    // settings
    // groupByStation: string | null = 'groupByStation'
    disabledOffline: boolean = false;
    // ignoreIncomplete: string | null = 'ignoreIncomplete'
    ignorePartially: boolean = false;

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private orderBy: OrderByPipe,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            type: new UntypedFormControl('week', [Validators.required]),
            settings: new UntypedFormControl(''),
            bulkselect: new UntypedFormControl(''),
            date: new UntypedFormControl(this.defaultDate, [Validators.required]),
            sites: this.formBuilder.array([]),
        })

        this.dataService.getSites().subscribe(
            (data: any) => {
                this.sitesList = this.orderBy.transform(
                    data.content.map((i: any) => ({
                        id: i.id,
                        stationId: i.station.id,
                        name: `${i.station.name} ${i.name}`,
                        visibility: i.visibility == 'ONLINE' ? true : false,
                        checked: false
                    })),
                    'name',
                    false
                );
            }
        )
    }

    submitHistoryGroupingRequest(): void {

        if ( this.form.value.sites.length > 0 ) {

            this.collapsed = false;
            this.historyGroupingRequest.emit({
                type: this.form.value.type,
                sites: this.form.value.sites,
                date: moment(this.form.value.date).format('YYYY-MM')
            });

        } else {
            
            this.collapsed = true;
            this.snackBar.open('Hoppla, die Auswahl ist leer. Es muss mindestens ein Parkbereich ausgewählt sein.', 'Verstanden!', {panelClass: 'error-snack'});
        }
    }

    startWeekOnMonday(event: any) {
        this.form.value.dateFrom = new Date(event.getFullYear(), event.getMonth(), 1);
    }

    onChangeSite(event: any, stationId: any) {

        const sites = <UntypedFormArray>this.form.get('sites') as UntypedFormArray;

        if ( this.form.controls.settings.value.includes('groupByStation') ) {
            
            sites.clear();
            
            this.sitesList.forEach((site:any) => {
                site.checked = false;
            })
            // Get all items with same station id
            let itemsWithSameStation = this.sitesList.filter((item:any) => item.stationId === stationId);
            // Exclude offline items when selected no offliners
            if ( this.form.controls.settings.value.includes('excludeOffline') ) {
                itemsWithSameStation = itemsWithSameStation.filter((item:any) => item.visibility);
            }
            // Get IDs of all selected items
            itemsWithSameStation.forEach((site:any) => {
                sites.push(new UntypedFormControl(site.id))
                site.checked = true;  
            })

        } else {

            if (event.checked) {
                sites.push(new UntypedFormControl(event.source.value))
            } else {
                const i = sites.controls.findIndex(x => x.value === event.source.value);
                sites.removeAt(i);
            }
        }
    }

    bulkSelectSites(type: any) {
        
        const sites = <UntypedFormArray>this.form.get('sites') as UntypedFormArray;
        type !== 'deselectIncomplete' ? sites.clear() : sites;
        // sites.clear();
        // Check all sites
        if ( type === 'selectAll') {
            this.sitesList.forEach((site:any) => {
                sites.push(new UntypedFormControl(site.id))
                site.checked = true;
            })
        }
        // Uncheck all sites
        if ( type === 'deselectAll') {
            this.sitesList.forEach((site:any) => {
                site.checked = false;
            })
        }
        // Check only online sites
        if ( type === 'selectAllOnline') {
            this.sitesList.forEach((site:any) => {
                if (site.visibility) {
                    sites.push(new UntypedFormControl(site.id))
                    site.checked = true;
                } else {
                    site.checked = false;
                }

            })
        }
        // Uncheck incomplete sites
        if ( type === 'deselectIncomplete' && this.failures) {
            this.sitesList.forEach((site:any) => {
                if (this.failures.some(item => item.site.id === site.id)) {
                    sites.removeAt(sites.value.findIndex((item:any) => item === site.id))
                    site.checked= false;
                }
            })
        }
    }

    onChangeSettings(event: any) {
        // Uncheck "excludeOffline" when "groupByStation" is not checked
        if ( !this.form.controls.settings.value.includes('groupByStation') ) {
            // Filter out "excludeOffline" from settings array
            const settings = this.form.controls.settings.value.filter((item:any) => item !== 'excludeOffline')
            // Patch filtered array
            this.form.patchValue({settings: settings})
        }
        // Toggle checkbox disabled 
        if ( this.form.controls.settings.value.includes('excludeOffline') ) {
            
            this.disabledOffline = true
            // Remove and uncheck offliner from formarray and sites list 
            const sites = <UntypedFormArray>this.form.get('sites') as UntypedFormArray;
            this.sitesList.forEach((site:any) => {
                if ( !site.visibility && sites.value.includes(site.id) ) {
                    sites.removeAt(sites.value.findIndex((item:any) => item === site.id))
                    site.checked = false
                }
            })

        } else {

            this.disabledOffline = false
            
        }
    }

}
