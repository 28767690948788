import { HttpResponse } from '@angular/common/http';
import { Directive, HostListener, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DataService } from '@core/services/data.service';
import { saveAs } from 'file-saver';

@Directive({
	selector: '[exportButton]'
})
export class ExportButtonDirective {

	@Input() path: string = ''
	@Input() format: string = ''
	@Input() params: any = ''

	snackOptions: MatSnackBarConfig = { duration: 3000, panelClass: 'success-snack' };

	constructor(
		private dataService: DataService,
		private snackBar: MatSnackBar
	) { }

	@HostListener('click') onClick() {
		const filteredParams = this.filterParams(this.params);

		this.dataService.downloadFile(this.path, this.format, filteredParams).subscribe((response: HttpResponse<Blob>) => {

			const contentType = response.headers.get('Content-Type');
			const contentDisposition = response.headers.get('Content-Disposition')
			// Content-Disposition: attachment; name="string.xlsx"; filename="string.xlsx"
			const fileName = this.extractFilename(contentDisposition);
			// Content-Type: application/octet-stream
			const blob = new Blob([response.body], { type: contentType });

			saveAs(blob, fileName);

			this.snackBar.open(`${fileName} wurde erfolgreich exportiert.`, null, this.snackOptions);
		})

	}

	private extractFilename(contentDisposition: string): string | null {
		const matches = /filename="([^"]+)"/.exec(contentDisposition);
		if (matches && matches.length > 1) {
			return matches[1];
		}
		return null;
	}


	private filterParams(params: any): any {
		const filteredParams: any = {};
		if (params) {
			Object.keys(params).forEach(key => {
				if (params[key] !== null && params[key] !== '') {
					filteredParams[key] = params[key];
				}
			});
		}
		return filteredParams;
	}

}
