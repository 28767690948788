import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'failure-form',
    templateUrl: './failure-form.component.html',
    styleUrls: ['./failure-form.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, {
        provide: MAT_DATE_FORMATS,
        useValue: CUSTOM_FORMATS
    }]
})
export class FailureFormComponent implements OnInit {

    @Output() failureRequest = new EventEmitter<any>();

    form: UntypedFormGroup
    // default date from is 1 month from yesterday
    defaultDateFrom: string = moment().subtract(1, 'days').subtract(1, 'months').format('YYYY-MM-DD');
    // default date until is yesterday
    defaultDateUntil: string = moment().subtract(1, 'days').format('YYYY-MM-DD');
    // max date from never bigger than current until date, also see setLimitations() method
    maxDateFrom: string = this.defaultDateUntil;
    // min date until never smaller than current from date, also see setLimitations() method
    minDateUntil: string = this.defaultDateFrom;
    // limit date from to yesterday
    maxDateUntil: string = moment().subtract(1, 'day').format('YYYY-MM-DD');

    constructor(
        private formBuilder: UntypedFormBuilder,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            dateFrom: new UntypedFormControl(this.defaultDateFrom, [Validators.required]),
            dateUntil: new UntypedFormControl(this.defaultDateUntil, [Validators.required])
        })
        this.failureRequest.emit({
            from: moment(this.form.value.dateFrom).format('YYYY-MM-DD'),
            until: moment(this.form.value.dateUntil).format('YYYY-MM-DD')
        });
    }

    submitFailureRequest(): void {
        this.failureRequest.emit({
            from: moment(this.form.value.dateFrom).format('YYYY-MM-DD'),
            until: moment(this.form.value.dateUntil).format('YYYY-MM-DD')
        });
    }

    setLimitations() {
        this.maxDateFrom = this.form.value.dateUntil
        this.minDateUntil = this.form.value.dateFrom
        this.cdr.detectChanges();
    }

    startWeekOnMonday(event: any) {
        this.form.value.dateFrom = new Date(event.getFullYear(), event.getMonth(), 1);
    }
}
