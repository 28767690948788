import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tariffNames'
})
export class TariffNamesPipe implements PipeTransform {

  transform(value:string): string {
    
    if (value === '1hour') return '1 Stunde'
    if (value === '1day') return '1 Tag'
    if (value === '1dayDiscount') return '1 Tag (Rabatt)'
    if (value === '1dayPCard') return '1 Tag (Rabatt)'
    if (value === '1week') return '1 Woche'
    if (value === '1weekDiscount') return '1 Woche (Rabatt)'
    if (value === '1weekPCard') return '1 Woche (Rabatt)'
    if (value === '1monthLongTerm') return '1 Monat Dauerparken (mind. 3 Monate)*'
    if (value === '1monthReservation') return '1 Monat Dauerparken (fester Stellplatz)*'
    if (value === '1monthVendingMachine') return '1 Monat (am Automaten)*'
    
    else return value

  }

}
