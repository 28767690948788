import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';
import { HistoryTablePipe } from '../history-table.pipe';
import { HistoryGroupingFormComponent } from './history-grouping-form/history-grouping-form.component';

@Component({
  selector: 'app-history-grouping',
  templateUrl: './history-grouping.component.html',
  styleUrls: ['./history-grouping.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HistoryGroupingComponent implements OnInit {

    @ViewChild(HistoryGroupingFormComponent) child: HistoryGroupingFormComponent;
    	
    type: string
    sites: any
    date: string

    occupancies: any
    failures: any

    stations: any

    timeSegments: string[];
    dayInterval: string[]
    displayedColumns: string[];
    dataSource = new MatTableDataSource<any>();

    failureMessage: boolean = false;

    constructor(
        private dataService: DataService,
        private historyTablePipe: HistoryTablePipe
    ) { }

    ngOnInit(): void {

    }

    onSubmitHistoryGroupingRequest($event: any) {
        // console.log($event)
        this.type = $event.type
        this.sites = $event.sites
        
        const format = $event.type === 'weekmonth' ? 'YYYY-MM' : ($event.type === 'weekyear') ? 'YYYY' : 'YYYY-MM-DD';
        this.date = moment($event.date).format(format)

        this.dataService.getHistoryGrouping(this.type, this.sites.join(','), this.date).subscribe(
            (data: any) => {
                // console.log(data)
                this.stations = data.entity.name.split(',').map((item:any) => item.trim());
                // sort series by time segment
                this.occupancies = data.series.map((serie:any) => serie).sort((a:any, b:any) =>
                    a.timeSegment < b.timeSegment ? -1 : 1
                )
                // returns boolean weather obj has data status partially or not
                let hasPartially = this.occupancies.some((item:any) => item.dataStatus === 'PARTIALLY');
                // Table data and columns arrays
                this.dayInterval = this.historyTablePipe.tableHead1DayInterval();
                this.timeSegments = this.historyTablePipe.tableHead1HourInterval();
                this.displayedColumns = ['hours', ...this.timeSegments];
                this.dataSource = this.historyTablePipe.transform(this.occupancies, 7, 24);
                // console.log(this.dataSource)
                
                const from = moment(data.from).format('YYYY-MM-DD')
                const until = moment(data.until).format('YYYY-MM-DD')
                this.dataService.getHistoryGroupingFails(this.sites.join(','), from, until).subscribe(
                    (data: any) => {
                        // filter out failures
                        this.failures = data.failureSites.filter((item:any) => item.failure.failureHours);
                        // trim out whitespaces in title
                        this.failures.forEach((item:any) => { 
                            item.site.title = item.site.title.trim();
                            item.tooltip = `📅 ${item.failure.failureDaysCount} Tage\n🕑 ${item.failure.failureHours} Stunden\n💣 Ausfallrate: ${Math.floor(item.failure.failureRate*100).toFixed(0)}%`; 
                        });
                
                        // setup alert message if there are failures but data has incompletely aggregated values
                        if ( !hasPartially && this.failures.length > 0 ) {
                            this.failureMessage = true;
                        }
                    }
                )
            }
        )
    }

}
