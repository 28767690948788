<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="capacity-form" (ngSubmit)="submitCountedOccupancyRequest()">

        <div class="flex flex-row">

            <!-- Space -->
            <div class="w-[30%] me-5">
                <mat-form-field formGroupName="space" appearance="outline">
                    <mat-label>Parkraum</mat-label>
                    <input type="text" placeholder="Parkraum suchen..." aria-label="Parkraum" matInput 
                        formControlName="name" 
                        [matAutocomplete]="autoSpace"
                        (keyup)="onKeyup()">
                        <a *ngIf="form.get('space.name').value" matSuffix mat-icon-button aria-label="Löschen"
                            (click)="form.get('space').reset()" tabindex="-1">
                            <mat-icon>close</mat-icon>
                        </a>
                    <mat-autocomplete #autoSpace="matAutocomplete">
                        <mat-option *ngFor="let c of filteredSpaces | async" [value]="c.nameOptional" (onSelectionChange)="selectionChange(c.id)">
                            {{c.nameOptional}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!-- From Datepicker -->
            <div class="w-[15%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Von</mat-label>
                    <input matInput [matDatepicker]="datepickerFrom" [max]="maxDate" formControlName="dateFrom" (dateChange)="formatDate($event.value, 'dateFrom')"/>
                    <a *ngIf="form.get('dateFrom').value" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="form.get('dateFrom').reset()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                    <mat-datepicker-toggle matSuffix [for]="datepickerFrom">
                        <mat-icon matDatepickerToggleIcon>today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerFrom>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- To Datepicker  -->
            <div class="w-[15%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Bis</mat-label>
                    <input matInput [matDatepicker]="datepickerUntil" [min]="form.get('dateFrom').value" [max]="maxDate" formControlName="dateUntil" (dateChange)="formatDate($event.value, 'dateUntil')" />
                    <a *ngIf="form.get('dateUntil').value" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="form.get('dateUntil').reset()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                    <mat-datepicker-toggle matSuffix [for]="datepickerUntil">
                        <mat-icon matDatepickerToggleIcon>event</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerUntil>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <!-- EXCEL download button -->
            <div class="w-[30%] flex justify-end items-center">
                <button type="button" mat-flat-button 
                    exportButton 
                    [path]="'spaces/countedOccupancies'" 
                    [format]="'EXCEL'"
                    [params]="{spaceId: form.get('space.id').value, from: form.get('dateFrom').value, until: form.get('dateUntil').value}">
                    <mat-icon>file_download</mat-icon> Excel
                </button>
                <button type="button" mat-flat-button 
                    exportButton 
                    [path]="'spaces/countedOccupancies'" 
                    [format]="'CSV'"
                    [params]="{spaceId: form.get('space.id').value, from: form.get('dateFrom').value, until: form.get('dateUntil').value}">
                    <mat-icon>file_download</mat-icon> CSV
                </button>
            </div>

        </div>

    </form>

</mat-card>
