import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appPrint]'
})
export class PrintDirective {

    @Input() appPrint: string = ''

    constructor() { }

    @HostListener('click') print() {
        const filename = this.appPrint || new Date().toString()
        const title = document.title
        document.title = this.sanitize(filename);
        window.print();
        document.title = title
    }

    sanitize(input: string): string {

        const illegalRe = /[\/\?<>\\:\*\|":]/g;
        const controlRe = /[\x00-\x1f\x80-\x9f]/g;
        const reservedRe = /^\.+$/;
        const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;

        const sanitized = input
            .replace(illegalRe, '')
            .replace(controlRe, '')
            .replace(reservedRe, '')
            .replace(windowsReservedRe, '')
            .replace(/\u00c4/g, 'Ae')
            .replace(/\u00e4/g, 'ae')
            .replace(/\u00dc/g, 'Ue')
            .replace(/\u00fc/g, 'ue')
            .replace(/\u00d6/g, 'Oe')
            .replace(/\u00f6/g, 'oe')
            .replace(/\u00df/g, 'ss')
            .replace(/ /g, '_');

        return sanitized;
    }

}
