<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="capacity-form" (ngSubmit)="submitSpotSpacesRequest()">

        <div class="flex flex-row">

            <!-- From Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Datum wählen</mat-label>
                    <input matInput [matDatepicker]="datepickerTimestamp" formControlName="date" [max]="maxDate" (dateChange)="formatDate($event.value, 'date')"/>
                    <a *ngIf="form.get('date').value" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="form.get('date').reset()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                    <mat-datepicker-toggle matSuffix [for]="datepickerTimestamp">
                        <mat-icon matDatepickerToggleIcon>today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerTimestamp>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <div class="w-[70%] flex justify-end items-center">

                <!-- EXCEL download button -->
                <button type="button" mat-flat-button 
                    exportButton 
                    [path]="'spot/spaces'" 
                    [format]="'excel'">
                    <mat-icon>file_download</mat-icon> Export
                </button>

            </div>

        </div>

    </form>

</mat-card>
