import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
    selector: 'overview-tab',
    templateUrl: './overview-tab.component.html',
    styleUrls: ['./overview-tab.component.scss']
})
export class OverviewTabComponent implements OnInit {

    @Input() spaceId: string;

    space: any;
    occupancy: any;
    prognoses: any;

    mapZoom:number = 17
    mapCenter: google.maps.LatLngLiteral
    markerPosition: google.maps.LatLngLiteral
    mapOptions: google.maps.MapOptions = {
        mapTypeId: 'satellite',
        styles: [{
                  featureType: 'poi.business',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'transit',
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'on' }],
                }
        ]
    }
    
    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
        this.getSpaceDetails()
        this.getShortPrognosis()
    }

    getSpaceDetails(): void {
        this.dataService.getSpace(this.spaceId).subscribe(
            (data: any) => {
                this.space = data;
                this.mapCenter = {
                    lat: data.geoLocation.latitude,
                    lng: data.geoLocation.longitude
                }
                this.markerPosition = {
                    lat: data.geoLocation.latitude,
                    lng: data.geoLocation.longitude
                }
            }
        )
    }

    getShortPrognosis(): void {
        this.dataService.getOccupanciesById(this.spaceId).subscribe(
            (data: any) => {
                this.occupancy = data.occupancy
            }
        )
        this.dataService.getPrognosesById(this.spaceId).subscribe(
            (data: any) => {
                this.prognoses = data.prognoses
            }
        )
    }


}