import { Component, Input, OnInit, ViewChild, AfterViewInit, ComponentFactoryResolver } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { DataService } from '@core/services/data.service';
import { CapacityChartDialogComponent } from './capacity-chart-dialog/capacity-chart-dialog.component';


@Component({
  selector: 'app-capacity',
  templateUrl: './capacity.component.html',
  styleUrls: ['./capacity.component.scss']
})
export class CapacityComponent implements OnInit {

    from: string | null = null;
    until: string | null = null;

    displayedColumns: string[] = [
        'site',
        'capacityMax',
        'capacityMin',
        'capacityDeviation',
        'chartButton'
    ]
    dataSource = new MatTableDataSource<any>();

    constructor(
        public dialog: MatDialog,
        private dataService: DataService
    ) { }

    ngOnInit(): void {

    }

    onSubmitCapacityRequest($event: any) {
        this.from = $event.from;
        this.until = $event.until;
        this.dataService.getCapacities(this.from, this.until).subscribe(
            (data: any) => {
                this.dataSource = data.sort((a: any, b: any) => {
                    return a.site.station.name.localeCompare(b.site.station.name);
                });
            }
        )
    }

    openChartDialog(element:any) {
        this.dialog.open(CapacityChartDialogComponent, { 
            width: '90vw', 
            data: {
                item: element,
                dateUntil: this.until 
            }
        });
    }


}
