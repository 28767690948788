import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-parkspotting-app-export',
	templateUrl: './parkspotting-app-export.component.html',
	styleUrls: ['./parkspotting-app-export.component.scss']
})
export class ParkspottingAppExportComponent implements OnInit, AfterViewInit {

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

	form: UntypedFormGroup

	filteredSpaces: Observable<any[]>;

	subscription: Subscription;

	hasChanged: boolean = false

	exportParams: any = {}

	constructor(
		private formBuilder: UntypedFormBuilder,
		private dataService: DataService
	) { }

	ngOnInit(): void {
		this.form = new UntypedFormGroup({});
		this.form = this.formBuilder.group({
			space: this.formBuilder.group({
				id: [''],
				name: [''],
			}),
			format: new UntypedFormControl('EXCEL'),
			dateFrom: new UntypedFormControl(''),
			dateUntil: new UntypedFormControl(''),
		})
	}

	ngAfterViewInit() {
		/** Empty input if no option was selected, this triggers required validator
		 *  and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e: any) => {
			if ( !(e && e.source) && this.hasChanged ) {
				this.form.get('space.name').patchValue('', { emitEvent: false });
				this.form.get('space.id').patchValue('', { emitEvent: false });
				this.autocompleteTrigger.closePanel();
			}
		});
	}

	formatDate(date: string, formControl: string) {
		if ( moment(date, 'YYYY-MM-DD').isValid() ) {
			this.form.get(formControl).patchValue(moment(date).format('YYYY-MM-DD'))
		} else {
			this.form.get(formControl).patchValue('')
		}
        
    }

	selectionChange(id: number) {
		// patch the id to cities object
		this.form.get('space.id').patchValue(id)
		// reset change detector
		this.hasChanged = false
	}

	/** That's not how you suppose to do it, but for now it does the job */
	onKeyup() {
		// changes has been made by user
		this.hasChanged = true
		// filter cities
		this.filteredSpaces = this.form.get('space.name').valueChanges.pipe(
			startWith(this.form.get('space.name').value),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value || ''))
		)
	}

	private _filter(value: any): Observable<any[]> {
		console.log(value)
		let filterValue = (typeof value === 'string' ? value : value.name).toLowerCase();
		let params = { size: 2000, visibility: 'ONLINE', responsibility: 'DBBAHNPARK', channel: 'PIT', ...(filterValue && { name: filterValue }) }
		return this.dataService.getSpaces(params).pipe(
			map((data: any) => data.content.filter((item: any) => item.name.toLowerCase().includes(filterValue)))
		)
	}

}
