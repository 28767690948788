import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

import { DataService } from '@core/services/data.service';

import * as moment from 'moment';
import 'moment/locale/de';

@Component({
    selector: 'failure-tab',
    templateUrl: './failure-tab.component.html',
    styleUrls: ['./failure-tab.component.scss']
})
export class FailureTabComponent implements OnInit {

    @Input() siteId: number[];

    failureDates: any
    failureHours: any
    daysPerMonth: any

    from: string
    until: string

    failureChartLabels: Label[];
    failureChartData: ChartDataSets[]
    failureChartType: ChartType = 'bar';
    failureChartLegend = false;
    failureChartOptions: ChartOptions = {
        tooltips: {
            callbacks: {
                title: (tooltipItem: any, data: any) => {
                    return data.labels[tooltipItem[0].index];
                },
                label: (tooltipItem: any, data: any) => {
                    return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
                }
            }
        },
        scales: {
            yAxes: [{
                gridLines: {
                    color: '#dedede',
                    zeroLineColor: '#dedede'
                },
                scaleLabel: {
                    display: true,
                    labelString: '24 Stunden',
                    fontColor: '#424242'
                },
                ticks: {
                    min: 0,
                    max: 24,
                    beginAtZero: true,
                    fontColor: '#424242'
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Letze 31 Tage',
                    fontColor: '#424242'
                },
                gridLines: {
                    color: '#dedede',
                    zeroLineColor: '#dedede'
                },
                ticks: {
                    fontColor: '#424242',
                    callback: function (value, index, values) {
                        return index + 1;
                    }
                }
            }]
        }
    };

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {

        this.from = moment().subtract(31, 'days').format('YYYY-MM-DD')
        this.until = moment().subtract(1, 'days').format('YYYY-MM-DD')

        this.dataService.getFailuresById(this.siteId, this.from, this.until).subscribe(
            (data: any) => {

                this.failureDates = data.failureDates

                this.failureHours = this.failureDates.map((item: any) => {
                    return item.failure.failureHours;
                }).reverse();

                this.daysPerMonth = this.failureDates.map((item: any) => {
                    return moment(item.date).format('dddd, DD. MMMM YYYY')
                }).reverse();

                this.failureChartData = [
                    { data: this.failureHours, label: 'Ausfall (Std)', backgroundColor: 'rgba(9,64,116,1)' }
                ];

                this.failureChartLabels = this.daysPerMonth

            }
        )

    }
}
