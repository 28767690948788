<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="history-grouping-form" (ngSubmit)="submitHistoryGroupingRequest()">

        <mat-card-header class="flex flex-row">

            <!-- Date options -->
            <div class="w-[25%] flex justify-start items-center me-5">
                <mat-radio-group aria-label="Historie Typ wählen" color="primary" formControlName="type">
                    <mat-radio-button value="week"> Historie Woche </mat-radio-button>
                    <mat-radio-button value="weekmonth"> ∅ Woche (Monat) </mat-radio-button>
                    <mat-radio-button value="weekyear"> ∅ Woche (Jahr) </mat-radio-button>
                </mat-radio-group>
            </div>

            <!-- Settings select box -->
            <div class="w-[15%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Einstellungen</mat-label>
                    <mat-select formControlName="settings" (selectionChange)="onChangeSettings($event)" multiple disableOptionCentering>
                        <mat-option [value]="'groupByStation'" (click)="disabled = !disabled"> Gemeinsamer Bahnhof </mat-option>
                        <mat-option [value]="'excludeOffline'" [disabled]="disabled" style="padding: 0 0 0 32px"> Ohne Offline-Parkbereiche </mat-option>
                        <mat-option [value]="'ignorePartially'" (click)="ignorePartially = !ignorePartially"> Unvollständige ignorieren </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Settings select box -->
            <div class="w-[15%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Massenauswahl</mat-label>
                    <mat-select formControlName="bulkselect" disableOptionCentering>
                        <mat-option [value]="1" (click)="bulkSelectSites('selectAllOnline')"> Alle Online-Parkbereiche auswählen </mat-option>
                        <mat-option [value]="2" (click)="bulkSelectSites('selectAll')"> Alle Parkbereiche auswählen </mat-option>
                        <mat-option [value]="3" (click)="bulkSelectSites('deselectAll')"> Alle Parkbereiche abwählen </mat-option>
                        <mat-option [value]="4" (click)="bulkSelectSites('deselectIncomplete')" [disabled]="failures ? false : true"> Unvollständige Parkräume abwählen </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <!-- Date Datepicker -->
            <div class="w-[15%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Datum</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="date" 
                    [max]="maxDate" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepicker">
                        <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepicker (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <!-- Toggle Sites list button -->
            <div class="w-[20%] flex justify-end items-center">
                <button type="button" mat-flat-button (click)="collapsed = !collapsed">
                    <mat-icon>unfold_more</mat-icon> Parkbereiche {{collapsed ? 'verstecken' : 'anzeigen'}}
                </button>
            </div>

        </mat-card-header>
        
        <mat-card-content [hidden]="!collapsed" class="mat-elevation-z1 !mb-0">

            <!-- Sites list -->
            <ul class="col-4-grid">
                <li *ngFor="let site of sitesList; let i = index">
                    <mat-checkbox (change)="onChangeSite($event, site.stationId)" [checked]="site.checked" [value]="site.id" [disabled]="disabledOffline && !site.visibility" color="primary">
                        {{site.name}} <span *ngIf="!site.visibility" class="site-offline-badge">Offline</span>
                    </mat-checkbox>
                </li>
            </ul>    
                    
        </mat-card-content>

    </form>

</mat-card>
