import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '@core/services/data.service';
import { Observable, interval } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnDestroy {

	selectedFile: File
	
	success: {
        success: number,
        failed: number,
        userMessages: string[]
    } | null = null;
    failed: {
        code: number,
        error: string,
        message: string,
        userMessages: string[]
    } | null = null;

    countdown: number = 5;
    autoClose: boolean = false;
    intervalId: any;

	constructor(
		public dialogRef: MatDialogRef<ImportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public path: string,
		private dataService: DataService
	) { }

	ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

	selectFile(event: any) {
		let files: FileList = event.target.files;
		this.selectedFile = files[0];
	}

	import(): void {
        
		this.failed = null; 
		this.success = null;

        const formData: FormData = new FormData();
        formData.append('file', this.selectedFile);

        this.dataService.uploadFile(this.path, formData).pipe(
            tap((data: any) => {
                this.success = data;
                this.autoClose = true;
            }),
            switchMap(() => this.startCountdown())
        ).subscribe({
            complete: () => {
                this.dialogRef.close(this.success);
            },
            error: (error) => this.failed = error.error
        });
    }

    startCountdown(): Observable<number> {
        return interval(1000).pipe(
            takeWhile(() => this.countdown > 0),
            tap(() => this.countdown--)
        );
    }
  
}
