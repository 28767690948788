import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DataService } from '@core/services/data.service';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import * as moment from 'moment';
import { Moment } from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'MMMM YYYY',
    },
    display: {
        dateInput: 'MMMM YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'history-weekmonth-form',
    templateUrl: './history-weekmonth-form.component.html',
    styleUrls: ['./history-weekmonth-form.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, {
        provide: MAT_DATE_FORMATS,
        useValue: CUSTOM_FORMATS
    }]
})
export class HistoryWeekmonthFormComponent implements OnInit {

    @Output() historyWeekmonthRequest = new EventEmitter<any>();

    form: UntypedFormGroup
    // default date is today
    defaultDate: string = moment().format('YYYY-MM');
    // max date today
    maxDate: string = moment().format('YYYY-MM');

    stationList: any

    defaultSite: any

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private orderBy: OrderByPipe
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            site: new UntypedFormControl('', [Validators.required]),
            date: new UntypedFormControl(this.defaultDate, [Validators.required])
        })

        this.dataService.getSites().subscribe(
            (data: any) => {
                this.stationList = this.orderBy.transform(
                    data.content.map((i: any) => ({
                        id: i.id,
                        name: `${i.station.name} ${i.name}`,
                        visibility: i.visibility == 'ONLINE' ? true : false
                    })),
                    'name',
                    false
                );
                this.defaultSite = this.stationList[0];
                this.form.controls['site'].setValue(this.defaultSite);
                this.historyWeekmonthRequest.emit({
                    site: this.defaultSite,
                    date: moment(this.defaultDate).format('YYYY-MM')
                });
            }
        )
    }

    submitHistoryWeekmonthRequest(): void {
        this.historyWeekmonthRequest.emit({
            site: this.form.value.site,
            date: moment(this.form.value.date).format('YYYY-MM')
        });
    }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = moment(this.form.get('date').value);
        ctrlValue.year(normalizedYear.year());
        this.form.get('date').setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = moment(this.form.get('date').value);
        ctrlValue.month(normalizedMonth.month());
        this.form.get('date').setValue(ctrlValue);
        datepicker.close();
    }

    formatDate(date: string, format: string): string {
        return moment(date).format(format);
    }

}
