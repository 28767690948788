<div class="flex flex-col justify-center items-center w-100 h-[calc(100vh-128px)]">
    
    <div class="flex flex-row flex-grow justify-center items-center">
        <mat-card class="w-96">
            <mat-card-header>
                <div mat-card-avatar class="w-12 h-12 dbbp-logo"></div>
                <mat-card-title>Hallo, {{currentUser.firstname}} {{currentUser.lastname}}!</mat-card-title>
                <mat-card-subtitle>Ein Angebot der DB BahnPark</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                {{(mode$ | async) === 'PIT_STANDARD' ? 'P.I.T. - Prognose und Informations Tool' : 'P.I.T.  - Parkspotting Mode aktiv'}}
            </mat-card-content>
        </mat-card>
    </div>

    <div class="h-6 text-center">
        <p class="text-base">Made with ❤️ in Berlin.</p>
        <small>
            <code>🍊 API Version {{api?.version}} // 💻 GUI Version {{appVersion}} // 🌎 {{api?.basepath}}</code>
        </small>    
    </div>

</div>