<h1 mat-dialog-title class="!flex flex-row justify-start items-center">
    <mat-icon>verified_user</mat-icon> {{(user$ | async)?.salutation | salutation}} {{(user$ | async)?.firstname}} {{(user$ | async)?.lastname}}
</h1>
<div mat-dialog-content [style.padding-top.px]="16">
    
        <table class="user-table">
            <tbody>
                <tr>
                    <td>Benutzername</td>
                    <td>{{(user$ | async)?.username}}</td>
                </tr>
                <tr>
                    <td>Benutzerrolle</td>
                    <td>{{(user$ | async)?.role}}</td>
                </tr>
                <tr>
                    <td>Benutzerstatus</td>
                    <td>{{(user$ | async)?.userStatus}}</td>
                </tr>
                <!--
                <tr>
                    <td>Zuletzt eingeloggt</td>
                    <td>{{(user$ | async)?.lastLogin}}</td>
                </tr>
                <tr>
                    <td>Konto erstellt</td>
                    <td>{{(user$ | async)?.created}}</td>
                </tr>
                -->
            </tbody>
        </table>

</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close>Schlie&szlig;en</button>
</div>
