import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataService } from '@core/services/data.service';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import * as moment from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
  selector: 'history-range-form',
  templateUrl: './history-range-form.component.html',
  styleUrls: ['./history-range-form.component.scss'],
  providers: [{
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  }, {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
  }]
})
export class HistoryRangeFormComponent implements OnInit {

    @Output() historyRangeRequest = new EventEmitter<any>();

    form: UntypedFormGroup
    // default date is today
    defaultDateFrom: string = moment().format('YYYY-MM-DD');
    // default date is today
    defaultDateUntil: string = moment().format('YYYY-MM-DD');
    // max date today
    maxDate: string = moment().format('YYYY-MM-DD');

    stationList: any
    
    defaultSite: any

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private orderBy: OrderByPipe
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            site: new UntypedFormControl('', [Validators.required]),
            from: new UntypedFormControl(this.defaultDateFrom, [Validators.required]),
            until: new UntypedFormControl(this.defaultDateUntil, [Validators.required])
        })

        this.dataService.getSites().subscribe(
            (data: any) => {
                this.stationList = this.orderBy.transform(
                    data.content.map((i: any) => ({ 
                        id: i.id, 
                        name: `${i.station.name} ${i.name}`,
                        visibility: i.visibility == 'ONLINE' ? true : false
                    })),
                    'name',
                    false
                );
                this.defaultSite = this.stationList[0];
                this.form.controls['site'].setValue(this.defaultSite);
                this.historyRangeRequest.emit({
                    site: this.defaultSite,
                    from: this.defaultDateFrom,
                    until: this.defaultDateUntil
                });
            }
        )
    }

    submitHistoryRangeRequest(): void {
        this.historyRangeRequest.emit({
            site: this.form.value.site,
            from: moment(this.form.value.from).format('YYYY-MM-DD'),
            until: moment(this.form.value.until).format('YYYY-MM-DD')
        });
    }

    formatDate(date: string, formControl: string) {
        this.form.get(formControl).patchValue(moment(date).format('YYYY-MM-DD'))
    }

}
