<h1>Kapazität für {{data.item.site.station.name}} {{data.item.site.name}}</h1>
<p>{{from | date: 'dd.MM.yyyy'}} bis {{until | date: 'dd.MM.yyyy'}}</p>

<div *ngIf="capacityDates" style="display: block; width:100%">
    <canvas baseChart
        [datasets]="capacityChartData"
        [labels]="capacityChartLabels"
        [options]="capacityChartOptions"
        [legend]="capacityChartLegend"
        [chartType]="capacityChartType">
    </canvas>
</div>

<mat-dialog-actions align="end">
    <button mat-flat-button [appPrint]="'Kapazität_'+data.item.site.station.name+'_'+data.item.site.name"><mat-icon>print</mat-icon> Drucken</button>
    <button mat-flat-button color="primary" mat-dialog-close>Schließen</button>
</mat-dialog-actions>