import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';

@Component({
    selector: 'app-history-week',
    templateUrl: './history-week.component.html',
    styleUrls: ['./history-week.component.scss']
})
export class HistoryWeekComponent implements OnInit {


    site: any = null;
    date: string | null = null;
    from: string | null = null;
    until: string | null = null;

    timeSegments: any[];
    displayedColumns: string[];
    dataSource = new MatTableDataSource<any>();

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
    }

    onSubmitHistoryWeekRequest($event: any) {

        this.site = $event.site;
        this.date = $event.date;

        this.dataService.getHistoryWeekById(this.site.id, this.date).subscribe(
            (data: any) => {
                
                this.from = data.from;
                this.until = data.until;

                const timeSegments = data.series.map((item: any) => moment(item.timeSegment).format('H:mm'))
                this.timeSegments = [...new Set(timeSegments)]
                // console.log(this.timeSegments)
                this.displayedColumns = ['hours', ...this.timeSegments]
                this.dataSource = data.series.reduce((acc: any, _: any, i: number) => {
                    if (!(i % 24)) {
                        acc.push(data.series.slice(i, i + 24));
                    }
                    return acc;
                }, []);

            }
        )

        // console.log($event)
    }
}
