import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';
import 'moment/locale/de';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
  selector: 'counted-occupancy-form',
  templateUrl: './counted-occupancy-form.component.html',
  styleUrls: ['./counted-occupancy-form.component.scss'],
  providers: [{
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  }, {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
  }]
})
export class CountedOccupancyFormComponent implements OnInit {
    
    @Output() countedOccupancyRequest = new EventEmitter<any>();

    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

    form: UntypedFormGroup
    // default date from is 1 month backwards from today
    // defaultDateFrom: string = moment().subtract(1, 'months').format('YYYY-MM-DD');
    // default date until is today
    // defaultDateUntil: string = moment().format('YYYY-MM-DD');
    // max date from never bigger than current until date, also see setLimitations() method
    // maxDateFrom: string = this.defaultDateUntil;
    // min date until never smaller than current from date, also see setLimitations() method
    // minDateUntil: string = this.defaultDateFrom;
    // limit date from to tody
    maxDate: string = moment().format('YYYY-MM-DD');

	filteredSpaces: Observable<any[]>;

	subscription: Subscription;

	hasChanged: boolean = false

	exportParams: any = {}

    constructor(
        private formBuilder: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private dataService: DataService 
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            space: this.formBuilder.group({
                id: [''],
                name: [''],
            }),
            dateFrom: new UntypedFormControl(null), // this.defaultDateFrom
            dateUntil: new UntypedFormControl(null) // this.defaultDateUntil
        })
        this.countedOccupancyRequest.emit({
            from: this.form.value.dateFrom,
            until: this.form.value.dateUntil
        });
    }

    ngAfterViewInit() {
		/** Empty input if no option was selected, this triggers required validator
		 *  and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e: any) => {
			if ( !(e && e.source) && this.hasChanged ) {
				this.form.get('space.name').patchValue('', { emitEvent: false });
				this.form.get('space.id').patchValue('', { emitEvent: false });
				this.autocompleteTrigger.closePanel();
			}
		});
	}

	selectionChange(id: number) {
		// patch the id to cities object
		this.form.get('space.id').patchValue(id)
		// reset change detector
		this.hasChanged = false
	}

	/** That's not how you suppose to do it, but for now it does the job */
	onKeyup() {
		// changes has been made by user
		this.hasChanged = true
		// filter cities
		this.filteredSpaces = this.form.get('space.name').valueChanges.pipe(
			startWith(this.form.get('space.name').value),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value || ''))
		)
	}

	private _filter(value: any): Observable<any[]> {
		let filterValue = (typeof value === 'string' ? value : value.name).toLowerCase();
		let params = { size: 2000, visibility: 'ONLINE', responsibility: 'DBBAHNPARK', channel: 'PIT', parkspotting: 'true', ...(filterValue && { name: filterValue }) }
		return this.dataService.getSpaces(params, 'spinner').pipe(
			map((data: any) => data.content.filter((item: any) => item.nameOptional.toLowerCase().includes(filterValue)))
		)
	}

    submitCountedOccupancyRequest(): void {
        this.countedOccupancyRequest.emit({
            from: this.form.value.dateFrom,
            until: this.form.value.dateUntil,
            spaceId: this.form.value.space.id,
            spaceNameOptional: this.form.value.space.name
        });
    }

    setLimitations() {
        // this.maxDateFrom = this.form.value.dateUntil
        // this.minDateUntil = this.form.value.dateFrom
        this.cdr.detectChanges();
    }

    startWeekOnMonday(event: any) {
        this.form.value.dateFrom = new Date(event.getFullYear(), event.getMonth(), 1);
    }

    formatDate(date: string, formControl: string) {
        this.form.get(formControl).patchValue(moment(date).format('YYYY-MM-DD'))
    }

}

