import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'feedback'
})
export class FeedbackPipe implements PipeTransform {

    transform(value: string | number | null, type: string): string {

        if ( value && type === 'sex' ) {
            if (value === 'w') return 'weiblich';
            if (value === 'm') return 'männlich';
        }

        if ( value && type === 'age' ) {
            if (value === 10) return 'unter 20 Jahre';
            if (value === 20) return '20 bis 29 Jahre';
            if (value === 30) return '30 bis 39 Jahre';
            if (value === 40) return '40 bis 49 Jahre';
            if (value === 50) return '50 bis 59 Jahre';
            if (value === 60) return '60 bis 69 Jahre';
            if (value === 70) return '70 bis 79 Jahre';
            if (value === 80) return 'über 80 Jahre';
        }

        return 'keine Angabe';
    }

}
