<app-spot-model-form (spotModelRequest)="onSubmitSpotModelRequest($event)"></app-spot-model-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Aktuelles Parkspotting Modell vom {{timestamp | date: 'dd.MM.Y'}}</h1>
</div>

<div class="flex flex-col w-[50%] px-5 mb-5">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 spot-model-table">

        <!-- Beta Long Term Column -->
        <ng-container matColumnDef="betaLongTerm">
            <th mat-header-cell *matHeaderCellDef> Beta Langzeit Tickets </th>
            <td mat-cell *matCellDef="let element">{{element.betaLongTerm  | number: '1.2-6' : 'de'}}</td>
        </ng-container>

        <!-- Cluster Type Column -->
        <ng-container matColumnDef="clusterType">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Clustertyp </th>
            <td mat-cell *matCellDef="let element" class="!text-left">{{element.clusterType | spot}}</td>
        </ng-container>

        <!-- Gamma Long Term Column -->
        <ng-container matColumnDef="gammaSeasonParker">
            <th mat-header-cell *matHeaderCellDef> Gamma Dauerparker </th>
            <td mat-cell *matCellDef="let element">{{element.gammaSeasonParker  | number: '1.2-6' : 'de'}}</td>
        </ng-container>

        <!-- Max Hours Short Term Column -->
        <ng-container matColumnDef="maxHoursShortTerm">
            <th mat-header-cell *matHeaderCellDef> Max Hours Kurzzeit </th>
            <td mat-cell *matCellDef="let element">{{element.maxHoursShortTerm  | number: '1.2-6' : 'de'}}</td>
        </ng-container>

        <!-- Model Day Column -->
        <ng-container matColumnDef="dayType">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Tagestyp </th>
            <td mat-cell *matCellDef="let element" class="!text-left">{{element.dayType | spot}}</td>
        </ng-container>

        <!-- Scaling Column -->
        <ng-container matColumnDef="scaling">
            <th mat-header-cell *matHeaderCellDef class="!text-right"> Skalierungsfaktor </th>
            <td mat-cell *matCellDef="let element" class="!text-right">{{element.scaling  | number: '1.2-6' : 'de'}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6" class="!text-center">Keine Daten gefunden.</td>
        </tr>

    </table>

    <img src="assets/bom-model.jpg" alt="Parkspotting Modell" class="w-full mt-5 mat-elevation-z1">

</div>