import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'app-spot-model',
  templateUrl: './spot-model.component.html',
  styleUrls: ['./spot-model.component.scss']
})
export class SpotModelComponent implements OnInit {

	timestamp: string = '';

	displayedColumns: string[] = [
		'clusterType',
		'dayType',
		'scaling',
		'maxHoursShortTerm',
		'betaLongTerm',
		'gammaSeasonParker'
	]
	dataSource = new MatTableDataSource<any>();

	constructor(
		private dataService: DataService
	) { }

	ngOnInit(): void {
		this.updateSpotModels();
	}

	updateSpotModels(): void {
		this.dataService.getSpotModels().subscribe(
            (data: any) => {
                this.dataSource = data.parameters;
				this.timestamp = data.timestamp;
            }
        )
	}

	onSubmitSpotModelRequest(event: any): void {
		if ( event ) this.updateSpotModels();
	}
}
