<hon-form #defaultDate (honRequest)="onSubmitHonRequest($event)"></hon-form>

<div class="flex flex-col px-5">
    <div class="flex flex-row items-center justify-between">
        <h1 class="py-5">
            <img src="assets/hon-logo.svg" alt="Happy Or Not" width="140" height="auto">
            Ergebnisse für {{site?.name}} von {{from | date: 'MMMM YYYY'}} bis {{until | date: 'MMMM YYYY'}}
        </h1>
        <div>
            <span class="nps-value-icon" *ngIf="npsValue">
                <svg version="1.1" id="certificate" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="-49 141 512 512" style="enable-background:new -49 141 512 512;fill:#004171" xml:space="preserve">
                    <path d="M409.6,396.9l46-45c13.7-13,7.3-36-10.7-40.3l-62.6-16l17.7-62c5-17.8-11.8-34.7-29.7-29.7l-62,17.7l-16-62.7
                    c-4.2-17.7-27.5-24.2-40.3-10.7l-45,46.3l-45-46.3c-12.6-13.4-36-7.2-40.3,10.7l-16,62.7l-62-17.7c-17.8-5-34.7,11.8-29.7,29.7
                    l17.7,62l-62.7,16c-18,4.3-24.4,27.4-10.7,40.3l46,45l-46,45c-13.7,13-7.3,36,10.7,40.3l62.7,16l-17.7,62
                    c-5,17.8,11.8,34.7,29.7,29.7l62-17.7l16,62.7c4.4,18.6,27.7,24,40.3,10.7l45-46l45,46c12.5,13.5,36,7.5,40.3-10.7l16-62.7l62,17.7
                    c17.8,5,34.7-11.8,29.7-29.7l-17.7-62l62.6-16c18-4.3,24.4-27.4,10.7-40.3L409.6,396.9L409.6,396.9z"/>
                </svg>
                {{npsValue}}
            </span> aus {{countTotal}} Antworten
        </div>
    </div>

    <div class="mb-5">
        <mat-card *ngIf="honData">
            <canvas baseChart [datasets]="honChartData" [labels]="honChartLabels" [options]="honChartOptions"
            [legend]="honChartLegend" [chartType]="honChartType" #mycanvas="base-chart" height="350">
            </canvas>
        </mat-card>
    </div>

    <div *ngIf="compare" class="flex flex-row items-center justify-between">
        <h1 class="py-5">
            <img src="assets/hon-logo.svg" alt="Happy Or Not" width="140" height="auto">
            Ergebnisse für {{site?.name}} von {{fromCompare | date: 'MMMM YYYY'}} bis {{untilCompare | date: 'MMMM YYYY'}}
        </h1>
        <div class="flex-item">
            <span class="nps-value-icon" *ngIf="npsValue">
                <svg version="1.1" id="certificate" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="-49 141 512 512" style="enable-background:new -49 141 512 512;fill:#004171" xml:space="preserve">
                    <path d="M409.6,396.9l46-45c13.7-13,7.3-36-10.7-40.3l-62.6-16l17.7-62c5-17.8-11.8-34.7-29.7-29.7l-62,17.7l-16-62.7
                    c-4.2-17.7-27.5-24.2-40.3-10.7l-45,46.3l-45-46.3c-12.6-13.4-36-7.2-40.3,10.7l-16,62.7l-62-17.7c-17.8-5-34.7,11.8-29.7,29.7
                    l17.7,62l-62.7,16c-18,4.3-24.4,27.4-10.7,40.3l46,45l-46,45c-13.7,13-7.3,36,10.7,40.3l62.7,16l-17.7,62
                    c-5,17.8,11.8,34.7,29.7,29.7l62-17.7l16,62.7c4.4,18.6,27.7,24,40.3,10.7l45-46l45,46c12.5,13.5,36,7.5,40.3-10.7l16-62.7l62,17.7
                    c17.8,5,34.7-11.8,29.7-29.7l-17.7-62l62.6-16c18-4.3,24.4-27.4,10.7-40.3L409.6,396.9L409.6,396.9z"/>
                </svg>
                {{npsValueCompare}}
            </span> aus {{countTotalCompare}} Antworten
        </div>
    </div>

    <div *ngIf="compare" class="mb-5">
        <mat-card *ngIf="honDataCompare">
            <canvas baseChart [datasets]="honCompareChartData" [labels]="honCompareChartLabels" [options]="honCompareChartOptions"
            [legend]="honChartLegend" [chartType]="honChartType" #mycanvas="base-chart" height="350">
            </canvas>
        </mat-card>
    </div>
</div>