import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataService } from '@core/services/data.service';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import * as moment from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@Component({
    selector: 'prognosis-form',
    templateUrl: './prognosis-form.component.html',
    styleUrls: ['./prognosis-form.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }, {
        provide: MAT_DATE_FORMATS,
        useValue: CUSTOM_FORMATS
    }]
})
export class PrognosisFormComponent implements OnInit {

    @Output() prognosisRequest = new EventEmitter<any>();

    stationList: any
    
    defaultSite: number
    // Default date is tomorrow
    defaultDate: string = moment().add(1, 'day').format();
    // Minimum date is tomorrow
    minDate: string = this.defaultDate;
    // Round up to next quarter of an hour
    defaultTime: string = moment().add(15 -(moment().minute() % 15), 'minutes').format('HH:mm');
    // Minute steps in timepicker
    timeGap: number = 5;

    form: UntypedFormGroup

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private orderBy: OrderByPipe
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            site: new UntypedFormControl('', [Validators.required]),
            date: new UntypedFormControl(this.defaultDate, [Validators.required]),
            time: new UntypedFormControl(this.defaultTime, [Validators.required])
        })

        this.dataService.getSites().subscribe(
            (data: any) => {
                this.stationList = this.orderBy.transform(
                    data.content.map((i: any) => ({ id: i.id, name: `${i.station.name} ${i.name}` })), 
                    'name',
                    false
                );
                this.defaultSite = this.stationList[0];
                this.form.controls['site'].setValue(this.defaultSite);
                this.prognosisRequest.emit({
                    site: this.defaultSite,
                    date: moment(this.defaultDate).format('YYYY-MM-DD'),
                    time: this.defaultTime
                });
            }
        )

    }

    submitPrognosisRequest(): void {
        this.prognosisRequest.emit({
            site: this.form.value.site,
            date: moment(this.form.value.date).format('YYYY-MM-DD'),
            time: this.form.value.time
        });
    }

    startWeekOnMonday(event: any) {
        this.form.value.dateFrom = new Date(event.getFullYear(), event.getMonth(), 1);
    }
    

}
