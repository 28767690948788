import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as moment from 'moment';
import { twoYearRangeValidator } from './two-year-range.validator';

export const CUSTOM_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'dd, LL',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-spot-spaces-form',
	templateUrl: './spot-spaces-form.component.html',
	styleUrls: ['./spot-spaces-form.component.scss'],
	providers: [{
		provide: DateAdapter,
		useClass: MomentDateAdapter,
		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
	}, {
		provide: MAT_DATE_FORMATS,
		useValue: CUSTOM_FORMATS
	}]
})
export class SpotSpacesFormComponent implements OnInit {

	@Output() spotSpacesRequest = new EventEmitter<any>();

	form: UntypedFormGroup

	maxDate: string = moment().format('YYYY-MM-DD');

	constructor(
		private formBuilder: UntypedFormBuilder,
	) { }

	ngOnInit(): void {
		this.form = new UntypedFormGroup({});
		this.form = this.formBuilder.group({
			dateFrom: new UntypedFormControl(null),
			dateUntil: new UntypedFormControl(null)
		}, { 
			validators: [twoYearRangeValidator]
		});
	}

	submitSpotSpacesRequest(): void {
        this.spotSpacesRequest.emit(this.form.value);
    }

    formatDate(date: string, formControl: string) {
		if (formControl === 'dateUntil') {
			this.form.get(formControl).patchValue(moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'))
		} else if ( formControl === 'dateFrom') {
			this.form.get(formControl).patchValue(moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'))
		}
    }

}
