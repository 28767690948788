<h1>Ausfallrate für {{data.failureSite.site.station.name}} {{data.failureSite.site.name}}</h1>
<p>
    {{from | date: 'dd.MM.yyyy'}} bis {{until | date: 'dd.MM.yyyy'}}<br>
    Ausfallzeit: {{data.failureSite.failure.failureHours}} Stunden an {{data.failureSite.failure.failureDaysCount}} Tagen.
</p>

<div *ngIf="failureDates" style="display: block; width:100%">
    <canvas baseChart
        [datasets]="failureChartData"
        [labels]="failureChartLabels"
        [options]="failureChartOptions"
        [legend]="failureChartLegend"
        [chartType]="failureChartType">
    </canvas>
</div>

<mat-dialog-actions align="end">
    <button mat-flat-button [appPrint]="'Ausfallrate_'+data.failureSite.site.station.name+'_'+data.failureSite.site.name"><mat-icon>print</mat-icon> Drucken</button>
    <button mat-flat-button color="primary" mat-dialog-close>Schließen</button>
</mat-dialog-actions>