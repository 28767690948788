import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';

import { DataService } from '@core/services/data.service';

import * as moment from 'moment';
import 'moment/locale/de';
import * as Chart from 'chart.js';
import { ChartTooltipModel } from 'chart.js';


@Component({
    selector: 'app-hon',
    templateUrl: './hon.component.html',
    styleUrls: ['./hon.component.scss']
})
export class HonComponent implements OnInit {

    honChart: any = null;
    // canvas: any
    // ctx: any

    SVGdarkGreen: string = '<svg version="1.1" id="SVGdarkGreen" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 88.9 88.9" class="hon-smileys-tooltip"><path fill="#069D30" d="M44.5,0C69,0,88.9,19.9,88.9,44.5C88.9,69,69,88.9,44.5,88.9S0,69,0,44.5C0,19.9,19.9,0,44.5,0"></path><path d="M27.6,31.5c2.4-1.6,5.9-0.4,7.8,2.5c2,3,1.7,6.6-0.7,8.2c-2.4,1.6-5.9,0.4-7.8-2.5 C25,36.8,25.3,33.1,27.6,31.5"></path><path d="M59.1,31.5c-2.4-1.6-5.9-0.4-7.8,2.5c-2,3-1.7,6.6,0.7,8.2c2.4,1.6,5.9,0.4,7.8-2.5 C61.8,36.8,61.5,33.1,59.1,31.5"></path><path d="M26.4,48.7c5.7,6.8,27,15.5,32.2,0.2c7.9,10.1-2,18.1-11.6,17C37.3,64.8,27.2,57.9,26.4,48.7"></path></svg>';

    SVGlightGreen: string = '<svg version="1.1" id="SVGlightGreen" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 88.9 88.9" class="hon-smileys-tooltip"><path fill="#98CC73" d="M44.5,0C69,0,88.9,19.9,88.9,44.5C88.9,69,69,88.9,44.5,88.9S0,69,0,44.5C0,19.9,19.9,0,44.5,0"></path><path d="M27.9,31.9c2.4-1.6,5.9-0.4,7.9,2.6c2,3,1.7,6.7-0.7,8.3c-2.4,1.6-5.9,0.4-7.9-2.6 C25.2,37.2,25.6,33.4,27.9,31.9"></path><path d="M61.1,31.9c-2.4-1.6-5.9-0.4-7.9,2.6c-2,3-1.7,6.7,0.7,8.3c2.4,1.6,5.9,0.4,7.9-2.6 C63.9,37.2,63.5,33.4,61.1,31.9"></path><path d="M27.6,56.2c11.3,4.5,23,4.3,34.5-0.5C57.2,61.3,33.8,63.3,27.6,56.2"></path></svg>';

    SVGlightRed: string = '<svg version="1.1" id="SVGlightRed" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 88.9 88.9" class="hon-smileys-tooltip"><path fill="#F37D7E" d="M44.5,0C69,0,88.9,19.9,88.9,44.5C88.9,69,69,88.9,44.5,88.9C19.9,88.9,0,69,0,44.5C0,19.9,19.9,0,44.5,0"></path><path d="M27.9,42.1c2.4,1.6,5.9,0.4,7.9-2.6c2-3,1.7-6.7-0.7-8.3c-2.4-1.6-5.9-0.4-7.9,2.6 C25.2,36.8,25.6,40.6,27.9,42.1"></path><path d="M61.1,42.1c-2.4,1.6-5.9,0.4-7.9-2.6c-2-3-1.7-6.7,0.7-8.3c2.4-1.6,5.9-0.4,7.9,2.6 C63.9,36.8,63.5,40.6,61.1,42.1"></path><path d="M27.2,58.4c11.3-4.5,23-4.3,34.5,0.5C56.7,53.4,33.3,51.3,27.2,58.4"></path></svg>';

    SVGdarkRed: string = '<svg version="1.1" id="SVGdarkRed" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 88.9 88.9" class="hon-smileys-tooltip"><path fill="#ED1E24" d="M44.5,0C69,0,88.9,19.9,88.9,44.5C88.9,69,69,88.9,44.5,88.9C19.9,88.9,0,69,0,44.5C0,19.9,19.9,0,44.5,0"></path><path d="M26.5,41.8c2.4,1.6,5.9,0.4,7.9-2.6c2-3,1.7-6.7-0.7-8.3c-2.4-1.6-5.9-0.4-7.9,2.6 C23.8,36.5,24.1,40.2,26.5,41.8"></path><path d="M60.5,41.8c-2.4,1.6-5.9,0.4-7.9-2.6c-2-3-1.7-6.7,0.7-8.3c2.4-1.6,5.9-0.4,7.9,2.6 C63.2,36.5,62.9,40.2,60.5,41.8"></path><path d="M24.8,68c5.8-6.9,27.3-15.6,32.5-0.2c8-10.3-2-18.3-11.7-17.2C35.9,51.7,25.7,58.8,24.8,68"></path></svg>';

    smileyArray = [null, this.SVGdarkGreen, this.SVGlightGreen, this.SVGlightRed, this.SVGdarkRed];

    honChartLabels: Label[];
    honChartData: ChartDataSets[]
    honChartType: ChartType = 'bar';
    honChartLegend = false;
    honChartOptions: ChartOptions = {}
    // comparison chart variables
    honCompareChartLabels: Label[];
    honCompareChartData: ChartDataSets[]
    honCompareChartOptions: ChartOptions = {}

    site: any;
    interval: string;
    compare: boolean;
    yearCompare: any;
    from: string;
    until: string;
    // comparison chart variables
    fromCompare: string;
    untilCompare: string;

    halfOfMaxValue: any

    honData: any
    countTotal: number
    npsValue: number
    // comparison chart variables
    honDataCompare: any
    countTotalCompare: any
    npsValueCompare: any

    @ViewChild('mycanvas') _chart: BaseChartDirective;

    constructor(
        private dataService: DataService,
    ) {
        Chart.defaults.LineWithLine = Chart.defaults.line;

        Chart.controllers.LineWithLine = Chart.controllers.line.extend({
            draw: function (ease: any) {
                Chart.controllers.line.prototype.draw.call(this, ease);

                if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
                    let activePoint = this.chart.tooltip._active[0],
                        ctx = this.chart.ctx,
                        x = activePoint.tooltipPosition().x,
                        topY = this.chart.scales['nps-y'].top,
                        bottomY = this.chart.scales['nps-y'].bottom;

                    // draw line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = '#e3e3e3';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        });

    }

    ngOnInit(): void {
    }

    onSubmitHonRequest($event: any) {

        // console.log($event)

        this.compare = $event.compare;
        this.yearCompare = $event.year;
        this.interval = $event.interval;

        // 11 months and 30 days because API returns one more
        const subtract: any[] = (this.interval === 'MONTHS') ? [11, 'months'] : [30, 'days'];

        this.from = moment($event.date).subtract(subtract[0], subtract[1]).format('YYYY-MM-DD')
        this.until = moment($event.date).format('YYYY-MM-DD')

        this.site = $event.site

        this.dataService.getHonSurveyResultsById(this.site.spaceId, this.from, this.until, this.interval).subscribe(
            (data: any) => {
                this.honData = data;
                this.honChartLabels = this.buildChartLabels(this.honData[0])
                this.honChartData = this.buildChartDataset(this.honData[0])
                this.honChartOptions = this.buildChartOptions('1')

                this.countTotal = this.honData[1][0].countTotal || 0
                this.npsValue = Math.floor(this.honData[1][0].npsValue) || 0
            }
        )


        if (this.compare) {

            const fromYearCompare = (this.interval === 'MONTHS') ? this.yearCompare - 1 : this.yearCompare
            this.fromCompare = moment(this.from).set('year', fromYearCompare).format('YYYY-MM-DD')
            this.untilCompare = moment(this.until).set('year', this.yearCompare).format('YYYY-MM-DD')

            this.dataService.getHonSurveyResultsById(this.site.spaceId, this.fromCompare, this.untilCompare, this.interval).subscribe(
                (data: any) => {
                    this.honDataCompare = data;
                    this.honCompareChartLabels = this.buildChartLabels(this.honDataCompare[0])
                    this.honCompareChartData = this.buildChartDataset(this.honDataCompare[0])
                    this.honCompareChartOptions = this.buildChartOptions('2')

                    this.countTotalCompare = this.honDataCompare[1][0].countTotal || 0
                    this.npsValueCompare = Math.floor(this.honDataCompare[1][0].npsValue) || 0
                }
            )
            
        }
    }

    buildChartLabels(data: any): Label[] {
        // check which interval an set date format
        const dateFormat = (this.interval === 'DAYS') ? 'dddd DD.M.YYYY' : 'MMMM yyyy';

        let daysPerMonth = data.map(function (item: any) {
            return moment(item.date).format(dateFormat)
        }).reverse();

        return daysPerMonth
    }

    buildChartDataset(data: any): ChartDataSets[] {

        let countDarkRed = data.map(function (item: any) {
            return item.countDarkRed;
        }).reverse();

        let countLightRed = data.map(function (item: any) {
            return item.countLightRed;
        }).reverse();

        let countLightGreen = data.map(function (item: any) {
            return item.countLightGreen;
        }).reverse();

        let countDarkGreen = data.map(function (item: any) {
            return item.countDarkGreen;
        }).reverse();

        let npsValue = data.map(function (item: any) {
            return item.npsValue;
        }).reverse();

        // sum all arrays with map then find max value with math.max and halve it
        this.halfOfMaxValue = Math.max.apply(null, countDarkRed.map((a: any, i: any) => a + countLightRed[i] + countLightGreen[i] + countDarkGreen[i])) / 2;

        return [{
            // label: 'NPS',
            yAxisID: 'nps-y',
            data: npsValue,
            backgroundColor: 'transparent',
            borderColor: '#0d65c3',
            pointRadius: 5,
            pointBackgroundColor: '#0d65c3',
            type: 'LineWithLine',
            order: 1
        },
        {
            // label: SVGdarkRed,
            yAxisID: 'votes-y',
            data: countDarkRed,
            backgroundColor: '#f7332d',
            order: 2
        },
        {
            // label: SVGlightRed,
            yAxisID: 'votes-y',
            data: countLightRed,
            backgroundColor: '#fe9394',
            order: 2
        },
        {
            // label: SVGlightGreen,
            yAxisID: 'votes-y',
            data: countLightGreen,
            backgroundColor: '#aae48b',
            order: 2
        },
        {
            // label: SVGdarkGreen,
            yAxisID: 'votes-y',
            data: countDarkGreen,
            backgroundColor: '#34c84b',
            order: 2
        }]

    }

    buildChartOptions(id: any): ChartOptions {

        //register custom positioner
        Chart.Tooltip.positioners.customPosition = (elements: any, eventPosition: any) => {
            // if ( !elements.length ) return false;
            let offset = 0;
            let tooltipWidth = 0;
            let tooltipHeight = 0;

            if (document.querySelector<HTMLElement>('#chartjs-tooltip-' + id)) {
                // console.log(document.querySelector('#chartjs-tooltip-' + id))
                tooltipWidth = document.querySelector<HTMLElement>('#chartjs-tooltip-' + id).offsetWidth;
                tooltipHeight = document.querySelector<HTMLElement>('#chartjs-tooltip-' + id).offsetHeight;
            }

            // offset is negative tooltip width minus 40px (thats double of 20px in CSS style.css)
            if (elements[0]._chart.width / 1.25 < eventPosition.x) offset = -tooltipWidth - 40;

            /* console.log({
                x: elements[0]._view.x + offset,
                y: elements[0]._chart.height / 2 - tooltipHeight // eventPosition.y // elements[4]._view.y - tooltipHeight // 
            }, eventPosition) */

            return {
                x: elements[0]._view.x + offset,
                y: elements[0]._chart.height / 2 - tooltipHeight // eventPosition.y // elements[4]._view.y - tooltipHeight // 
            }
        }
        // if (this.honChart !== null) { this.honChart.destroy(); }
        // console.log(scope)
        // this.canvas = <HTMLCanvasElement>document.getElementById('1')
        // this.ctx = this.canvas.getContext('2d');
        // console.log((scope.honRatio === 'true'))
        // this._chart.chart.ctx

        return {
            maintainAspectRatio: false,
            tooltips: {
                enabled: false,
                mode: 'index',
                intersect: false,
                position: 'nearest',
                custom: (tooltipModel: ChartTooltipModel) => {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip-' + id);
                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip-' + id;
                        tooltipEl.classList.add('chartjs-tooltip');
                        tooltipEl.innerHTML = '<table class="table-hon"></table>'
                        document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }
                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    if (tooltipModel.body) {

                        const sortArray = [0, 4, 3, 2, 1];
                        tooltipModel.dataPoints = sortArray.map(i => tooltipModel.dataPoints[i]);
                        tooltipModel.body = sortArray.map(i => tooltipModel.body[i]);

                        var titleLines = tooltipModel.title || [];

                        var bodyLines = tooltipModel.body.filter((el: any) => el !== undefined).map((el: any) => el.lines);
                        let yLabels = tooltipModel.dataPoints.filter((el: any) => el !== undefined).map((el: any) => el.yLabel);

                        yLabels.shift();

                        let sum = yLabels.reduce((pv: any, cv: any) => pv + cv, 0);

                        var innerHtml = '<thead>';

                        titleLines.forEach((title: any) => {
                            innerHtml += '<tr><th colspan="3">' + title + '</th></tr>';
                        });

                        innerHtml += '</thead><tbody>';

                        bodyLines.every((body: any, i: any) => {

                            let percent = Math.round(<number>tooltipModel.dataPoints[i].yLabel * 100 / sum);

                            if (tooltipModel.hasOwnProperty('dataPoints') && isNaN(<any>tooltipModel.dataPoints[0].yLabel)) {
                                innerHtml += '<tr><td colspan="3">Keine Antworten.</td></tr>';
                                return false;
                            } else if (i === 0) {
                                innerHtml += '<tr><td colspan="3">NPS: ' + Math.round(body) + '</td></tr>';
                            } else {
                                innerHtml += '<tr><td>' + this.smileyArray[i] + '</td><td>' + percent + '%</td><td>' + body + '</td></tr>';
                            }
                            return true;

                        });

                        if (tooltipModel.hasOwnProperty('dataPoints') && !isNaN(<any>tooltipModel.dataPoints[0].yLabel)) {
                            innerHtml += '<tr><td colspan="3">Antworten: ' + sum + '</td></tr>';
                        }

                        innerHtml += '</tbody>';

                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }

                    // var position = document.body.getBoundingClientRect(); -> Hon Detail 
                    var canvas = this._chart.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.left = canvas.left + tooltipModel.caretX + 'px';
                    // tooltipEl.style.top = (position.top * -1) + 150 + tooltipModel.caretY + 'px'; -> Hon Detail 
                    tooltipEl.style.top = canvas.top + tooltipModel.caretY + 'px';
                },
            },
            scales: {
                yAxes: [{
                    id: 'nps-y',
                    display: true,
                    // labelString: 'NPS',
                    position: 'right',
                    scaleLabel: {
                        display: true,
                        labelString: 'NPS',
                    },
                    gridLines: {
                        display: true,
                        drawBorder: false,
                        color: '#dedede',
                        zeroLineColor: '#dedede'
                    },
                    ticks: {
                        max: 100,
                        min: -100,
                        stepSize: 100,
                        fontColor: '#424242',
                    }
                }, {
                    id: 'votes-y',
                    display: true,
                    position: 'left',
                    stacked: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Antworten',
                    },
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: this.halfOfMaxValue,
                        fontColor: '#424242',
                    }
                }],
                xAxes: [{
                    stacked: true,
                    scaleLabel: {
                        display: false,
                        // labelString: 'Letze 31 Tage',
                    },
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: '#424242',
                        callback: function (value: any, index: any, values: any) {
                            return index + 1;
                        }
                    }
                }]
            }
        }
    }


}