<history-range-form #defaultDateFrom (historyRangeRequest)="onSubmitHistoryRangeRequest($event)"></history-range-form>

<div class="flex flex-row w-[calc(100%-2.5rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Historie Bereich: {{site?.name}} vom {{from | date: 'dd. MMMM yyyy'}} bis {{until | date: 'dd. MMMM yyyy'}}</h1>
</div>

<div class="flex flex-col w-[40%] px-5 mb-5">

    <table mat-table [dataSource]="dataSource" class="history-range-table mat-elevation-z1">

        <!-- Time Segment Column -->
        <ng-container matColumnDef="timeSegment">
            <th mat-header-cell *matHeaderCellDef> Zeit </th>
            <td mat-cell *matCellDef="let element">
                <b>{{element.timeSegment | date: 'HH:mm'}} Uhr</b><br>
                {{element.timeSegment | date: 'dd.MM.YYYY'}}
            </td>
        </ng-container>

        <!-- Capacity Column -->
        <ng-container matColumnDef="capacity">
            <th mat-header-cell *matHeaderCellDef> Kapazität </th>
            <td mat-cell *matCellDef="let element"><b>{{element.capacity}}</b></td>
        </ng-container>

        <!-- Occupancy Column -->
        <ng-container matColumnDef="occupancy">
            <th mat-header-cell *matHeaderCellDef> Belegt </th>
            <td mat-cell *matCellDef="let element">
                <div class="pit-cell cat-{{element.category || 0}}">
                    <span *ngIf="element.dataStatus">
                        <b> {{element.occupancy}} </b><br>
                        <small> {{element.occupancyRate | percent}} </small>
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Vacancy Column -->
        <ng-container matColumnDef="vacancy">
            <th mat-header-cell *matHeaderCellDef> Frei </th>
            <td mat-cell *matCellDef="let element">
                <div class="pit-cell cat-{{element.category || 0}}">
                    <span *ngIf="element.dataStatus">
                        <b> {{element.vacancy}} </b>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [length]="itemsCount" 
                    [pageSize]="pageSize" 
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = $event"
                    showFirstLastButtons>
    </mat-paginator>

</div>