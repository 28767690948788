import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

import { DataService } from '@core/services/data.service';

import * as moment from 'moment';
import 'moment/locale/de';


@Component({
  selector: 'app-capacity-chart-dialog',
  templateUrl: './capacity-chart-dialog.component.html',
  styleUrls: ['./capacity-chart-dialog.component.scss']
})
export class CapacityChartDialogComponent implements OnInit {

    capacityDates: any
    failureHours: any
    daysPerMonth: any

    from: string
    until: string

    capacityChartLabels: Label[];
    capacityChartData: ChartDataSets[]
    capacityChartType: ChartType = 'bar';
    capacityChartLegend = true;
    capacityChartOptions: ChartOptions = {
        tooltips: {
            callbacks: {
                title: (tooltipItem:any, data:any) => {
                    return data.labels[tooltipItem[0].index];
                },
                label: (tooltipItem:any, data:any) => {
                    return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
                }
            }
        },
        scales: {
            yAxes: [{
                gridLines: {
                    color: '#dedede',
                    zeroLineColor: '#dedede'
                },
                scaleLabel: {
                    display: true,
                    labelString: '24 Stunden',
                    fontColor: '#424242',
                },
                ticks: {
                    beginAtZero: true,
                    fontColor: '#424242',
                }
            }],
            xAxes: [{
                gridLines: {
                    color: '#dedede',
                    zeroLineColor: '#dedede'
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Letze 31 Tage',
                    fontColor: '#424242',
                },
                ticks: {
                    fontColor: '#424242',
                    callback: function (value, index, values) {
                        return index + 1;
                    }
                }
            }]
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: '#424242',
                // fontSize: 18
            }
            
        }
    };
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dataService: DataService
    ) { }

    ngOnInit(): void {

        this.from = moment(this.data.dateUntil).subtract(30, 'days').format('YYYY-MM-DD')
        this.until = moment(this.data.dateUntil).format('YYYY-MM-DD')

        this.dataService.getCapacitiesById(this.data.item.site.id, this.from, this.until).subscribe(
            (data: any) => {

                this.capacityDates = data

                let capacityMax = this.capacityDates.map((item:any) => {
                    return item.capacity.capacityMax;
                }).reverse();
                let capacityMin = this.capacityDates.map((item:any) => {
                    return item.capacity.capacityMin;
                }).reverse();
                let daysPerMonth = this.capacityDates.map((item:any) => {
                    return moment(item.date).format('LLLL');
                }).reverse();

                this.capacityChartData = [{
                        label: 'Max. Kapazität pro Tag',
                        data: capacityMax,
                        backgroundColor: 'rgba(234,28,0,1)',
    
                    }, {
                        label: 'Min. Kapazität pro Tag',
                        data: capacityMin,
                        backgroundColor: 'rgba(9,64,116,1)',
                }]
                this.capacityChartLabels = daysPerMonth
            }
        )

    }
}
