import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-parkspotting-summed-up',
	templateUrl: './parkspotting-summed-up.component.html',
	styleUrls: ['./parkspotting-summed-up.component.scss']
})
export class ParkspottingSummedUpComponent implements OnInit, OnDestroy {

	@Input() data: any
	@Input() delay: number
	interval: number

	displayedColumns: string[] = [
		'site',
		'capacity',
		'occupancy',
		'vacancy',
		'timestamp'
	]
	dataSource = new MatTableDataSource<any>();

	constructor() { }

	ngOnInit(): void {
		this.dataSource.data = this.data
		this.interval = window.setInterval(() => { this.dataSource.data = this.data }, this.delay)
	}

    ngOnDestroy(): void {
        if (this.interval) window.clearInterval(this.interval)
    }

}
