import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ModeSwitcherService } from '@shared/services/mode-switcher.service';

@Injectable()
export class QueryParametersInterceptor implements HttpInterceptor {

	private mode: string;

	constructor(
		private modeService: ModeSwitcherService
	) {
		this.modeService.getCurrent().subscribe(param => this.mode = param);
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

		const requestWithParams = request.clone({
			params: request.params.append('mode', this.mode)
		});

		return next.handle(requestWithParams);
	}

}
