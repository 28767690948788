<span [matMenuTriggerFor]="modeSwitcher" class="mode-switcher">
        <mat-icon>expand_more</mat-icon>
</span>

<mat-menu #modeSwitcher="matMenu">
    <mat-list>
        <div mat-subheader>Wählen Sie einen Modus:</div>
        <mat-list-item 
        (click)="switchMode('PIT_STANDARD')" 
        [disabled]="(mode$ | async) === 'PIT_STANDARD'"
        matRipple>
                <mat-icon mat-list-icon>keyboard</mat-icon>
                <span mat-list-icon>1</span>
                <div mat-line>PIT</div>
                <div mat-line>Klassische Protokolle</div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item 
        (click)="switchMode('PIT_PARKSPOTTING')" 
        [disabled]="(mode$ | async) === 'PIT_PARKSPOTTING'" 
        matRipple>
                <mat-icon mat-list-icon>keyboard</mat-icon>
                <span mat-list-icon>2</span>
                <div mat-line>Parkspotting</div>
                <div mat-line>Birds on Mars Protokoll</div>
        </mat-list-item>
      </mat-list>
</mat-menu>


