<h1>{{data.stationName}}</h1>
<p>{{data.name}} ({{data.capacity}})</p>

<mat-tab-group animationDuration="0ms" class="pt-4">

    <!-- Overview tab -->
    <mat-tab label="Übersicht">
        <ng-template matTabContent>
            <overview-tab [spaceId]="data.spaceId"></overview-tab>
        </ng-template>
    </mat-tab>

    <!-- Failure tab -->
    <mat-tab label="Ausfallrate">
        <ng-template matTabContent>
            <failure-tab [siteId]="data.siteId"></failure-tab>
        </ng-template>
    </mat-tab>

    <!-- Alarm tab -->
    <mat-tab label="Alarm-Übersicht">
        <ng-template matTabContent>
            <alarm-tab [siteId]="data.siteId"></alarm-tab>
        </ng-template>
    </mat-tab>

    <!-- Feedback tab -->
    <mat-tab label="Kundenfeedback">
        <ng-template matTabContent>
            <feedback-tab [spaceId]="data.spaceId"></feedback-tab>
        </ng-template>
    </mat-tab>

</mat-tab-group>
