export interface UserStateModel {

    channel: string | null
    created: string | null
    salutation: string | null
    firstname: string | null
    lastname: string | null
    lastLogin: string | null
    role: string | null
    userStatus: string | null
    username: string | null
}

export class SetUser {
    static readonly type = '[User] Set user';
}

export class RemoveUser {
    static readonly type = '[User] Remove user';
}