<counted-occupancy-form #defaultDateFrom (countedOccupancyRequest)="onSubmitCountedOccupancyRequest($event)"></counted-occupancy-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5 z-10">
    <h1>Gezählte Belegung{{spaceNameOptional ? ': ' + spaceNameOptional : ''}}{{from ? ' vom ' +
        (from | date: 'dd.MM.YY') : ''}}{{until ? ' bis ' + (until | date: 'dd.MM.YY') : ''}}</h1>
</div>

<div class="flex flex-col w-[45%] px-5 mb-5">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 countedOccupancies-table">

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef> Zeitpunkt </th>
            <td mat-cell *matCellDef="let element">
                <b>{{element.timestamp | date: 'dd.MM.Y'}}</b><br>
                {{element.timestamp | date: 'HH:mm:ss'}} Uhr
            </td>
        </ng-container>

        <!-- Space ID Column -->
        <ng-container matColumnDef="spaceId">
            <th mat-header-cell *matHeaderCellDef> Parkraum ID </th>
            <td mat-cell *matCellDef="let element">
                <code>{{element.space.id}}</code>
            </td>
        </ng-container>

        <!-- Space Name Column -->
        <ng-container matColumnDef="spaceNameOptional">
            <th mat-header-cell *matHeaderCellDef> Parkraum Bezeichnung </th>
            <td mat-cell *matCellDef="let element">{{element.space.name}}</td>
        </ng-container>

        <!-- Parkspotter ID Column -->
        <ng-container matColumnDef="parkspotterId">
            <th mat-header-cell *matHeaderCellDef class="!text-center"> <mat-icon>manage_accounts</mat-icon> </th>
            <td mat-cell *matCellDef="let element" class="!text-center">
                <a mat-icon-button target="_blank"
                    href="{{guiUrl}}/#/user/{{element.creatorId}}" 
                    matTooltip="Parkspotter ID {{element.creatorId}} in der Benutzerverwaltung öffnen"
                    matTooltipPosition="right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                    </svg>
                </a>

            </td>
        </ng-container>

        <!-- Counted Occupancy Column -->
        <ng-container matColumnDef="occupancyCounted">
            <th mat-header-cell *matHeaderCellDef class="!text-right !pr-7"> Gezählte Belegung </th>
            <td mat-cell *matCellDef="let element" class="!text-right !pr-7">{{element.occupancyCounted}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5" class="text-center">Keine Daten gefunden.</td>
        </tr>

    </table>

</div>