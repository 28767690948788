<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="history-weekmonth-form" (ngSubmit)="submitHistoryWeekmonthRequest()">

        <div class="flex flex-row">

            <!-- Site select box -->
            <div class="w-[25%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Parkraum</mat-label>
                    <mat-select formControlName="site" disableOptionCentering required>
                        <mat-option *ngFor="let station of stationList" [value]="station">
                            <span *ngIf="!station.visibility" class="site-offline-badge">
                                Offline<span style="display:none">:</span>
                            </span> {{station.name}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="form.controls['site'].invalid">Bitte wählen Sie einen Parkraum aus.</mat-error> -->
                </mat-form-field>
            </div>

            <!-- Date Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Datum</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="date" 
                    [max]="maxDate" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepicker">
                        <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepicker 
                                    startView="multi-year" 
                                    (yearSelected)="chosenYearHandler($event)" 
                                    (monthSelected)="chosenMonthHandler($event, datepicker)">
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <!-- EXCEL download button -->
            <div class="w-[45%] flex justify-end items-center">
                <button type="button" mat-flat-button
                    exportButton 
                    [path]="'sites/' + form.value.site.id + '/occupancies/weekmonth/' + formatDate(form.value.date, 'YYYY-MM')" 
                    [format]="'excel'">
                    <mat-icon>file_download</mat-icon> Excel
                </button>
                <button type="button" mat-flat-button mat-button 
                    [matMenuTriggerFor]="xclExportVariants">
                    <mat-icon>file_download</mat-icon> Excel (Alle)
                </button>
                <mat-menu #xclExportVariants="matMenu">
                    <button mat-menu-item 
                        exportButton 
                        [path]="'sites/occupancies/weekmonth/' + formatDate(form.value.date, 'YYYY-MM')"
                        [params]="{format: 1}"
                        [format]="'excel'">
                        Variante 1
                        </button>
                    <button mat-menu-item
                        exportButton 
                        [path]="'sites/occupancies/weekmonth/' + formatDate(form.value.date, 'YYYY-MM')"
                        [params]="{format: 2}"
                        [format]="'excel'">
                        Variante 2
                    </button>
                    <button mat-menu-item
                        exportButton 
                        [path]="'sites/occupancies/weekmonth/' + formatDate(form.value.date, 'YYYY-MM')"
                        [params]="{format: 3}"
                        [format]="'excel'">
                        Variante 3
                    </button>
                </mat-menu>
            </div>


        </div>

    </form>
    
</mat-card>