<history-grouping-form #defaultDate (historyGroupingRequest)="onSubmitHistoryGroupingRequest($event)" [failures]="failures"></history-grouping-form>

<div *ngIf="occupancies" class="flex flex-col w-[calc(100%-2.50rem)] items-start bg-[whitesmoke] sticky top-[60px] p-5">
    <h1 class="pb-2">Zusammenfassung:</h1>
    <mat-chip-list aria-label="Ausgewählte Parkbereiche">
        <mat-chip *ngFor="let station of stations; index as i" selected>
            {{station}}
            <ng-container *ngFor="let fail of failures">
                <mat-icon *ngIf="fail.site.title === station" [style.padding-left.px]="5" matTooltipClass="custom-tooltip-failures mat-elevation-z2" matTooltip="{{fail.tooltip}}" [matTooltipPosition]="'above'" style="cursor:pointer">warning</mat-icon>
            </ng-container>
        </mat-chip>
    </mat-chip-list>
</div>

<div *ngIf="failureMessage" class="!flex flex-row justify-between items-center m-5 p-5 bg-[#fff9e2] border border-solid border-[#ffe900] rounded-[3px]">
    <div><b>Achtung!</b> In der getroffenen Auswahl sind einzelne oder mehrere Parkbereiche unvollständig aggregiert.</div>
    <button mat-icon-button (click)="failureMessage = false"><mat-icon>close</mat-icon></button>
</div>

<div *ngIf="occupancies" class="flex flex-col px-5 mb-5">

    <table mat-table [dataSource]="dataSource" class="history-grouping-table mat-elevation-z1">

        <!-- Hours Column -->
        <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <b><span *ngIf="element[0].timeSegment">{{element[0].timeSegment | date: 'EEEE' }}</span>
                <span *ngIf="element[0].dayOfWeek">{{dayInterval[element[0].dayOfWeek - 1]}}</span></b>
            </td>
        </ng-container>

        <!-- Series Columns -->
        <ng-container *ngFor="let segment of timeSegments; index as i" matColumnDef="{{segment}}">
            <th mat-header-cell *matHeaderCellDef> {{segment}} </th>
            <td mat-cell *matCellDef="let element">
                <div class="pit-cell cat-{{element[i].category || 0}}" [ngClass]="{'partially': element[i].dataStatus === 'PARTIALLY' && !child.ignorePartially}">
                    <span *ngIf="element[i].dataStatus !== 'INVALID'">
                        <b> {{element[i].vacancy}} </b><br>
                        <small> {{element[i].occupancyRate | percent: '1.0-0' : 'en'}} </small>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>
