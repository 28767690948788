import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-spot-model-form',
  templateUrl: './spot-model-form.component.html',
  styleUrls: ['./spot-model-form.component.scss']
})
export class SpotModelFormComponent {

	@Output() spotModelRequest = new EventEmitter<any>();

	constructor() { }

	onClosedDialog(event: any): void {
		this.spotModelRequest.emit(event);
	}

}
