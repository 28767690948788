import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-spot-cluster-form',
	templateUrl: './spot-cluster-form.component.html',
	styleUrls: ['./spot-cluster-form.component.scss']
})
export class SpotClusterFormComponent {

	@Output() spotClusterRequest = new EventEmitter<any>();

	constructor() { }

	onClosedDialog(event: any): void {
		this.spotClusterRequest.emit(event);
	}
}
