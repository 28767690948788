export interface FailureStateModel {
    from: string,
    until: string,
    data: any[]
}

export class GetFailureSites {
    static readonly type = '[Failure] Get failure sites';
    constructor(
        public from: string,
        public until: string
    ) { }
}

export class RemoveFailureSites {
    static readonly type = '[Failure] Remove failure sites';
    constructor() { }
}