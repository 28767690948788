import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';

@Component({
    selector: 'app-history-day',
    templateUrl: './history-day.component.html',
    styleUrls: ['./history-day.component.scss']
})
export class HistoryDayComponent implements OnInit {

    site: any = null;
    date: string | null = null;

    timeSegments: any[];
    displayedColumns: string[];
    dataSource = new MatTableDataSource<any>();

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
    }

    onSubmitHistoryDayRequest($event: any) {
        
        this.site = $event.site;
        this.date = $event.date;

        this.dataService.getHistoryDayById(this.site.id, this.date).subscribe(
            (data: any) => {
                             
                const timeSegments = data.series.map((item: any) => moment(item.timeSegment).format(':mm'))
                this.timeSegments = [...new Set(timeSegments)]
                this.displayedColumns = ['hours', ...this.timeSegments]
                this.dataSource = data.series.reduce((acc:any, _:any, i:number) => {
                    if ( !(i % 12)  ) {
                        acc.push(data.series.slice(i, i + 12));
                    }
                    return acc;
                }, []);

            }
        )

        // console.log($event)
    }

}
