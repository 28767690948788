import { Component, OnInit } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Label } from 'ng2-charts';

@Component({
	selector: 'app-history-sparklines',
	templateUrl: './history-sparklines.component.html',
	styleUrls: ['./history-sparklines.component.scss']
})
export class HistorySparklinesComponent implements OnInit {

    site: any = null;
    date: string | null = null;

    capacityTotal: number;

    sparklinesChartLabels: Label[];
    sparklinesChartData: ChartDataSets[]
    sparklinesChartType: ChartType = 'line';
    sparklinesChartLegend = false;
    sparklinesChartOptions: ChartOptions = {
        responsive: true,
		maintainAspectRatio: true,
		elements: {
			line: {
				borderWidth: 4
			},
			point: {
				radius: 1.25
			}
		},
		tooltips: {
			enabled: true,
			mode: 'index',
			intersect: false,
            callbacks: {
                title: (tooltipItem: any, chart: any) => {
                    return ` ${tooltipItem[0].label} Uhr` || '';
                },
                label: (tooltipItem: any, chart: any) => {    
                    let value, data = chart.datasets[3].data[tooltipItem.index]
                    let validData = chart.datasets[3].data[tooltipItem.index].validData
                    if (!validData) return ' Keine Daten vorhanden.'
                    switch(chart.datasets[tooltipItem.datasetIndex].label) {
                        case 'KZP': 
                            value = data.occupancyShortTerm
                            break;
                        case 'MONAT': 
                            value = data.occupancyLongTerm
                            break;
                        case 'GESAMT':
                            value = data.occupancyTotal
                            break;
                    }
                    return ` ${chart.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}% (${value})` || '';
                },
                labelColor: function(tooltipItem: any, chart: any) {
                    var dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                    return {
                        borderColor: 'white',
                        backgroundColor : dataset.borderColor
                    }
                }
            }
		},
		scales: {
			yAxes: [{ 
                id: 'percent-y',
				display: true,
				ticks: {
					beginAtZero: true,
					min: 0,
					max: 100,
					stepSize: 5,
                    fontStyle: 'bold',
                    fontColor: 'black',
                    fontSize: 14,
                    callback: function(val, index) {
                        return (index % 2) ? '' : `${val} % `;
                    },
				}
			}],
            xAxes: [{ 
                id: 'time-x',
                display: true,
                type: 'time',
                time: {
                    unit: 'hour',
                    parser: 'HH:mm',
                    displayFormats: {
                        hour: 'HH:mm',
                    },
                    tooltipFormat: 'HH:mm'
                },
                ticks: {
                    fontStyle: 'bold',
                    fontColor: 'black',
                    fontSize: 14
                }
            }]
		}
	};

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
    }

    onSubmitHistorySparklinesRequest($event: any) {
        
        this.site = $event.site;
        this.date = $event.date;

        this.dataService.getHistoryDayByIdExtended(this.site.id, this.date).subscribe(
            (data: any) => {
                // Find last valid total capacity for headline
                this.capacityTotal = data.series.reduceRight(
                    (acc: any, serie: any) => acc ?? serie.capacityTotal, null
                );
                // Build chart labels from dashboard timestamp
                this.sparklinesChartLabels = this.buildTimeSegments(data.from, data.until)
                // Removes every 2nd element from array, 5m interval -> 10m interval
                const series = data.series // .filter((_:any, i: number) => i % 2 === 0)
                // Build data arrays with NULL values included (validData: false)
                const longTermRateArray: number[] = series.map((serie:any) => 
                    serie.occupancyLongTermRate === null ? serie.occupancyLongTermRate : Math.round(serie.occupancyLongTermRate * 100)
                )
                const shortTermRateArray: number[] = series.map((serie:any) => 
                    serie.occupancyShortTermRate === null ? serie.occupancyShortTermRate : Math.round(serie.occupancyShortTermRate * 100)
                )
                const totalRateArray: number[] = series.map((serie:any) => 
                    serie.occupancyTotalRate === null ? serie.occupancyTotalRate : Math.round(serie.occupancyTotalRate * 100)
                )
              
                this.sparklinesChartData = [ {
                    borderColor: '#004171',
                    pointBackgroundColor: '#004171',
                    pointBorderColor: '#004171',
                    label: 'GESAMT',
                    fill: false,
                    data: totalRateArray
                }, {
                    borderColor: '#ff8d60',
                    pointBackgroundColor: '#ff8d60',
                    pointBorderColor: '#ff8d60',
                    label: 'KZP',
                    fill: false,
                    data: shortTermRateArray
                }, {
                    borderColor: '#7ac2ff',
                    pointBackgroundColor: '#7ac2ff',
                    pointBorderColor: '#7ac2ff',
                    label: 'MONAT',
                    fill: false,
                    data: longTermRateArray
                }, {
                    data: series
                }]
                // console.log(this.sparklinesChartData, this.sparklinesChartOptions)
            }
        )
    }

    buildTimeSegments(from: string, until: string): string[] {
        const timeSegements = [];
        let start = moment(from);
        let end = moment(until);
        while ( start <= end )  {
            timeSegements.push(moment(start).format('HH:mm'));
            start.add(5, 'minutes');
        }
        return timeSegements;
    }

}
