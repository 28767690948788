import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { DetailDialogComponent } from './detail-dialog/detail-dialog.component';
import * as moment from 'moment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    constructor(
        public dialog: MatDialog,
        private dataService: DataService
    ) { }

    // interval for updating dashboard
    interval: number;

    displayedColumns: string[];
    dataSource: MatTableDataSource<any>;
    timeSegments: string[];

    ngOnInit(): void {
        this.updateDashboard();
        this.interval = window.setInterval(() => { this.updateDashboard() }, 200000)
    }

    ngOnDestroy(): void {
        if (this.interval) window.clearInterval(this.interval)
    }

    updateDashboard() {
        this.displayedColumns = [];
        this.dataService.getDashboard().subscribe(
            (data: any) => {
                data.entries.map((entry: any) => {
                    entry.currentOccupancy.hasDowntime = this.hasDowntime(entry.currentOccupancy.timeSegment)
                });
                this.dataSource = new MatTableDataSource(data.entries)
                this.timeSegments = this.buildTimeSegments(data.from, data.until)
                this.displayedColumns = this.timeSegments.concat(['site', 'current'])
                this.dataSource.filterPredicate = (match: any, filter: string) => {
                    return match.site.station.name.toLowerCase().includes(filter) || match.site.name.toLowerCase().includes(filter);
                };
            }
        )
    }

    buildTimeSegments(from: string, until: string): string[] {
        const timeSegements = [];
        let start = moment(from);
        let end = moment(until);
        while ( start <= end )  {
            timeSegements.push(moment(start).format('HH:mm'));
            start.add(1, 'hours');
        }

        return timeSegements;
    }

    /** TODO: Do better job - maybe service? */
	hasDowntime(timeSegment: string): boolean {

		if ( moment(timeSegment).isValid() ) {
			const now = moment();
			const duration = moment.duration(now.diff(timeSegment));
			const hours = duration.asHours();

			if ( hours >= 5 ) return true
			
			return false
		}

		return false
	}

    openDetailDialog(id: number, spaceId: number, stationName: string, name: string, capacity: number) {
        this.dialog.open(DetailDialogComponent, {
            height: '85vh',
            width: '90vw',
            data: {
                siteId: id,
                spaceId: spaceId,
                stationName: stationName,
                name: name,
                capacity: capacity
            }
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
