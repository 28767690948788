import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-counted-occupancy',
  templateUrl: './counted-occupancy.component.html',
  styleUrls: ['./counted-occupancy.component.scss']
})
export class CountedOccupancyComponent implements OnInit {

    from: string | null = null;
    until: string | null = null;
    spaceId: string| null = null;
    spaceNameOptional: string| null = null;

    guiUrl: string = environment.guiUrl;

    displayedColumns: string[] = [
        'timestamp',
        'spaceId',
        'spaceNameOptional',
        'occupancyCounted',
        'parkspotterId'
    ]
    dataSource = new MatTableDataSource<any>();

    constructor(
        public dialog: MatDialog,
        private dataService: DataService
    ) { }

    ngOnInit(): void {

    }

    onSubmitCountedOccupancyRequest($event: any) {
        this.from = $event.from;
        this.until = $event.until;
        this.spaceId = $event.spaceId;
        this.spaceNameOptional = $event.spaceNameOptional;

        const params = Object.fromEntries(
			Object.entries({
			  // ...this.paginationParams,
			  from: this.from ,
			  until: this.until,
			  spaceId: this.spaceId
			}).filter(([_, value]) => value != null && value)
		);

        this.dataService.getCountedOccupancies(params).subscribe(
            (data: any) => {
                this.dataSource = data.content;
            }
        )
    }

}
