<mat-card class="mat-elevation-z0">

    <form [formGroup]="form">

        <div class="flex flex-row">

            <!-- Space -->
            <div class="w-[20%] me-5">
                <mat-form-field formGroupName="space" appearance="outline" class="flex flex-row w-full">
                    <mat-label>Parkraum</mat-label>
                    <input type="text" placeholder="Parkraum suchen..." aria-label="Parkraum" matInput 
                        formControlName="name" 
                        [matAutocomplete]="autoSpace"
                        (keyup)="onKeyup()">
                    <mat-autocomplete #autoSpace="matAutocomplete">
                        <mat-option *ngFor="let c of filteredSpaces | async" [value]="c.name" (onSelectionChange)="selectionChange(c.id)">
                            {{c.nameOptional}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!-- From Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Von</mat-label>
                    <input matInput [matDatepicker]="datepickerFrom" formControlName="dateFrom" (dateChange)="formatDate($event.value, 'dateFrom')"/>
                    <mat-datepicker-toggle matSuffix [for]="datepickerFrom">
                        <mat-icon matDatepickerToggleIcon>today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerFrom>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- To Datepicker  -->
            <div class="w-20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Bis</mat-label>
                    <input matInput [matDatepicker]="datepickerUntil" formControlName="dateUntil" (dateChange)="formatDate($event.value, 'dateUntil')" />
                    <mat-datepicker-toggle matSuffix [for]="datepickerUntil">
                        <mat-icon matDatepickerToggleIcon>event</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerUntil>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <mat-radio-group class="flex flex-col items-start justify-center" aria-label="Dateiformat wählen" formControlName="format">
                <mat-radio-button value="EXCEL">EXCEL</mat-radio-button>
                <mat-radio-button value="CSV" class="pt-1">CSV</mat-radio-button>
            </mat-radio-group>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="button" mat-raised-button color="primary"
                        exportButton 
                        [path]="'spaces/countedOccupancies'" 
                        [format]="form.get('format').value"
                        [params]="{spaceId: form.get('space.id').value, from: form.get('dateFrom').value, until: form.get('dateUntil').value}"
                        >
                        Exportieren
                </button>
            </div>
        
        </div>

    </form>

</mat-card>