import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salutation'
})
export class SalutationPipe implements PipeTransform {

  transform(value:string): string {
    
    if (value === 'MS') return 'Frau'
    if (value === 'MR') return 'Herr'
    
    else return value

  }

}
