import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetUser, RemoveUser, UserStateModel } from './user.action';
import { DataService } from '@core/services/data.service';
import { tap } from 'rxjs/operators';

@State<UserStateModel>({
    name: 'user',
    defaults: {
        channel: null,
        created: null,
        salutation: null,
        firstname: null,
        lastname: null,
        lastLogin: null,
        role: null,
        userStatus: null,
        username: null,
    }
})
@Injectable()
export class UserState {
    @Selector()
    static getCurrentUserRole(state: UserStateModel) {
        return state.role;
    }

    @Selector()
    static getCurrentUser(state: UserStateModel) {
        return state;
    }

    constructor(
        private dataService: DataService
    ) { }

    @Action(SetUser)
    setCurrentUser(ctx: StateContext<UserStateModel>) {
        return this.dataService.getUser().pipe(
            tap(
                (data) => {
                    ctx.patchState({
                        channel: data.channel,
                        created: data.created,
                        salutation: data.salutation,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        lastLogin: data.lastLogin,
                        role: data.role,
                        userStatus: data.userStatus,
                        username: data.username,
                    });
                }
            )
        );
    }
}