import { Component, OnInit } from '@angular/core';
import { UserStateModel } from '@core/states/user/user.action';
import { Store } from '@ngxs/store';

import { UserState } from '@core/states/user/user.state';
import { DataService } from '@core/services/data.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ModeSwitcherService } from '@shared/services/mode-switcher.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    currentUser: UserStateModel | undefined

    mode$: Observable<string>

    api: any
    appVersion: string = environment.appVersion

    constructor(
        private store: Store,
        private dataService: DataService,
        private modeService: ModeSwitcherService
    ) { }

    ngOnInit(): void {

        this.currentUser = this.store.selectSnapshot(UserState.getCurrentUser);
        
        this.mode$ = this.modeService.getCurrent();

        this.dataService.getApiVersion().subscribe((data: any) => {
            this.api = data
        })
    }

}
