import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DataService } from '@core/services/data.service';
import * as moment from 'moment';
import 'moment/locale/de';

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'dd, LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
  selector: 'alarm-details-form',
  templateUrl: './alarm-details-form.component.html',
  styleUrls: ['./alarm-details-form.component.scss'],
  providers: [{
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  }, {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
  }]
})
export class AlarmDetailsFormComponent implements OnInit {
    
    @Output() alarmDetailsRequest = new EventEmitter<any>();

    form: UntypedFormGroup
    // default date from is 1 month from now
    defaultDateFrom: string = moment().subtract(1, 'months').format('YYYY-MM-DD');
    // default date until is today
    defaultDateUntil: string = moment().format('YYYY-MM-DD');
    // max date from never bigger than current until date, also see setLimitations() method
    maxDateFrom: string = this.defaultDateUntil;
    // min date until never smaller than current from date, also see setLimitations() method
    minDateUntil: string = this.defaultDateFrom;
    // limit date from to today
    maxDateUntil: string = moment().format('YYYY-MM-DD');
    // default threshold
    defaultThreshold: number = 10

    stationList: any
    
    defaultSite: any

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});
        this.form = this.formBuilder.group({
            site: new UntypedFormControl('', [Validators.required]),
            dateFrom: new UntypedFormControl(this.defaultDateFrom, [Validators.required]),
            dateUntil: new UntypedFormControl(this.defaultDateUntil, [Validators.required]),
            threshold: new UntypedFormControl(this.defaultThreshold, [Validators.required])
        })

        this.dataService.getSites().subscribe(
            (data: any) => {
                this.stationList = data.content.map((i: any) => ({ id: i.id, name: `${i.station.name} ${i.name}` }));
                this.defaultSite = this.stationList[0];
                this.form.controls['site'].setValue(this.defaultSite);
                this.alarmDetailsRequest.emit({
                    site: this.defaultSite,
                    from: this.defaultDateFrom,
                    until: this.defaultDateUntil,
                    threshold: this.defaultThreshold
                });
            }
        )
    }

    submitAlarmDetailsRequest(): void {
        this.alarmDetailsRequest.emit({
            site: this.form.value.site,
            from: this.form.value.dateFrom,
            until: this.form.value.dateUntil,
            threshold: this.form.value.threshold
        });
    }

    setLimitations() {
        this.maxDateFrom = this.form.value.dateUntil
        this.minDateUntil = this.form.value.dateFrom
        this.cdr.detectChanges();
    }

    startWeekOnMonday(event: any) {
        this.form.value.dateFrom = new Date(event.getFullYear(), event.getMonth(), 1);
    }

    formatDate(date: string, formControl: string) {
        this.form.get(formControl).patchValue(moment(date).format('YYYY-MM-DD'))
    }
}
