<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="capacity-form" (ngSubmit)="submitSpotSpacesRequest()">

        <div class="flex flex-row">

            <!-- From Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Von</mat-label>
                    <input matInput [matDatepicker]="datepickerFrom" formControlName="dateFrom" [max]="maxDate" (dateChange)="formatDate($event.value, 'dateFrom')"/>
                    <a *ngIf="form.get('dateFrom').value" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="form.get('dateFrom').reset()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                    <mat-datepicker-toggle matSuffix [for]="datepickerFrom">
                        <mat-icon matDatepickerToggleIcon>today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerFrom>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- To Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Bis</mat-label>
                    <input matInput [matDatepicker]="datepickerUntil" formControlName="dateUntil" [min]="form.get('dateFrom').value" [max]="maxDate" (dateChange)="formatDate($event.value, 'dateUntil')" />
                    <a *ngIf="form.get('dateUntil').value" matSuffix mat-icon-button aria-label="Löschen"
                        (click)="form.get('dateUntil').reset()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </a>
                    <mat-datepicker-toggle matSuffix [for]="datepickerUntil">
                        <mat-icon matDatepickerToggleIcon>event</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerUntil>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="form.invalid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

            <div class="w-[20%] flex items-center">
                <mat-error *ngIf="form.errors?.['twoYearRange']">
                    Fehler: Der Zeitraum darf 2 Jahre nicht überschreiten.
                </mat-error>
            </div>

            <div class="w-[50%] flex justify-end items-center">

                <!-- EXCEL download button -->
                <button type="button" mat-flat-button 
                    exportButton 
                    [path]="'spot/spaces'" 
                    [params]="{from: form.get('dateFrom').value, until: form.get('dateUntil').value}"
                    [format]="'excel'">
                    <mat-icon>file_download</mat-icon> Export
                </button>

            </div>

        </div>

    </form>

</mat-card>
