import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { AlarmDetailsFormComponent } from './alarm-details-form/alarm-details-form.component';

@Component({
  selector: 'app-alarmdetails',
  templateUrl: './alarm-details.component.html',
  styleUrls: ['./alarm-details.component.scss']
})
export class AlarmDetailsComponent implements OnInit {

    @ViewChild(AlarmDetailsFormComponent) child: AlarmDetailsFormComponent;

    site: any = null;
    from: string | null = null;
    until: string | null = null;
    threshold: number | null = null;

    displayedColumns: string[] = [
        'capacity',
        'occupancy',
        'vacancy',
        'timeSegment',
        'belowThreshold'
    ]
    dataSource = new MatTableDataSource<any>();

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        // Expression has changed after it was checked issue: https://stackoverflow.com/a/57646589
        /*setTimeout(() => {
            this.site = this.child.defaultSite
            this.from = this.child.defaultDateFrom
            this.until = this.child.defaultDateUntil
            this.threshold = this.child.defaultThreshold
            this.store.dispatch(new GetFailureSites(this.from, this.until)).subscribe(
                () => {
                    const store = this.store.selectSnapshot(FailureState.getFailureSites)
                    // sort data by station name - 
                    this.dataSource = store.data
                }
            )
            this.dataService.getThresholdsById(this.site.id, this.from, this.until, this.threshold).subscribe(
                (data: any) => {
                    this.dataSource = data.series
                    .failureSites.sort((a: any, b: any) => {
                        return a.site.station.name.localeCompare(b.site.station.name);
                    });
                }
            )
        }, 0);*/
    }

    onSubmitAlarmDetailsRequest($event: any) {
        this.site = $event.site;
        this.from = $event.from;
        this.until = $event.until;
        this.threshold = $event.threshold
    
        this.dataService.getThresholdsById(this.site.id, this.from, this.until, this.threshold).subscribe(
            (data: any) => {
                this.dataSource = data.series
            }
        )
    
        // console.log($event)
    }


}
