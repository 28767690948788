<div class="flex flex-row gap-4">
    <div class="w-1/3">
        <h2 class="pt-4">Standort</h2>
        <mat-card class="bg-[whitesmoke]">
            <p>
                <b>{{space?.name}}</b><br>
                {{space?.address.street}}<br>
                {{space?.address.postalCode}} {{space?.address.city}}
            </p>
        </mat-card>
        <table class="table-space-info mat-elevation-z1 mt-4">
            <tr *ngIf="space?.spaceType">
                <td> Parkart </td>
                <td> {{space?.spaceType}} </td>
            </tr>
            <tr *ngIf="space?.address.supplement">
                <td> Zufahrt </td>
                <td> {{space?.address.supplement}} </td>
            </tr>
            <tr *ngIf="space?.distance">
                <td> Entfernung </td>
                <td> nächster Bahnhofseingang {{space?.distance}} Meter </td>
            </tr>
            <tr *ngIf="space?.facilityType">
                <td> Parktechnik </td>
                <td> {{space?.facilityType}} </td>
            </tr>
            <tr *ngIf="space?.numberParkingPlaces">
                <td> Stellplätze </td>
                <td> {{space?.numberParkingPlaces}} <span *ngIf="space?.hasHandicapedPlaces">(<mat-icon>accessible</mat-icon>{{space?.numberHandicapedPlaces}})</span></td>
            </tr>
            <tr *ngIf="space?.spaceInfo.clearanceHeight">
                <td> Durchfahrtshöhe </td>
                <td> {{space?.spaceInfo.clearanceHeight / 100 | number: '1.0-2'}} Meter </td>
            </tr>
            <tr *ngIf="space?.spaceInfo.clearanceWidth">
                <td> Durchfahrtsbreite </td>
                <td> {{space?.spaceInfo.clearanceWidth / 100 | number: '1.0-2'}} Meter </td>
            </tr>
            <tr *ngIf="space?.spaceInfo.allowedPropulsions">
                <td> Zuslässige Antriebsarten </td>
                <td> {{space?.spaceInfo.allowedPropulsions}} </td>
            </tr>
            <tr *ngIf="space?.openingHours">
                <td> Öffnungszeiten </td>
                <td> {{space?.openingHours}} </td>
            </tr>
            <tr *ngIf="space?.operator">
                <td> Betreiber </td>
                <td> {{space?.operator}} ({{space?.operatorUrl}}) </td>
            </tr>
        </table>
    </div> 
    <div class="w-1/3">
        <h2 class="pt-4">Tarife</h2>
        <table class="table-tariff-prices mat-elevation-z1">
            <tr *ngIf="space?.tariffInfo.tariffFreeParkingTime !== 'nein'">
                <td>Freiparken ({{space?.tariffInfo.tariffFreeParkingTime}})</td>
                <td>Ja</td>
            </tr>
            <ng-container *ngFor="let tariff of space?.tariffPrices">
                <tr *ngIf="tariff.price">
                    <td>{{tariff.duration | tariffNames}}</td>
                    <td><b>{{tariff.price | number: '1.2-2' : 'de-At'}} €</b></td>
                </tr>
            </ng-container>
            <tr *ngIf="space?.tariffFlags.isDiscountDbBahnCard || space?.tariffFlags.isDiscountDbBahnComfort || space?.tariffFlags.isDiscountDbParkAndRail">
                <td>Besondere Angebote für Bahnkunden</td>
                <td>
                    <ul class="list--comma">
                        <li *ngIf="space?.tariffFlags.isDiscountDbBahnCard">Rabattierung mit Ihrer BahnCard</li>
                        <li *ngIf="space?.tariffFlags.isDiscountDbBahnComfort">bahn.comfort</li>
                        <li *ngIf="space?.tariffFlags.isDiscountDbParkAndRail">Park&Rail</li>
                    </ul>
                </td>
            </tr>
            <tr *ngIf="space?.tariffInfo.tariffPointOfSale">
                <td>Vorverkauf von Tageskarten</td>
                <td>{{space?.tariffInfo.tariffPointOfSale}}</td>
            </tr>
            <tr *ngIf="space?.tariffInfo.tariffDiscount">
                <td>Rabattierung von Tageskarten</td>
                <td>{{space?.tariffInfo.tariffDiscount}}</td>
            </tr>
            <tr *ngIf="space?.tariffFlags.isMonthVendingMachine || space?.tariffFlags.isMonthSeason || space?.tariffFlags.isMonthParkAndRide">
                <td>*Monatskarten</td>
                <td>
                    <ul class="list--block">
                        <li *ngIf="space?.tariffFlags.isMonthVendingMachine">Verkauf von Monatskarten am Parkscheinautomaten</li>
                        <li *ngIf="space?.tariffFlags.isMonthSeason">
                            <a href="https://www.mein-contipark.de/dauerparken">Hier Stellplatz mieten</a>
                        </li>
                        <li *ngIf="space?.tariffFlags.isMonthParkAndRide">Verkauf von P+R-Monatskarten im Reisezentrum</li>
                    </ul>
                </td>
            </tr>
            <tr *ngIf="space?.tariffInfo.tariffPaymentOptions">
                <td colspan="2">{{space?.tariffInfo.tariffPaymentOptions}}</td>
            </tr>    
            <tr *ngIf="space?.tariffInfo.tariffPaymentCustomerCards">
                <td>Kundenkarten</td>
                <td>{{space?.tariffInfo.tariffPaymentCustomerCards}}</td>
            </tr>
            <tr *ngIf="space?.tariffInfo.tariffPaymentMobile">
                <td>Mobile Zahlungsarten</td>
                <td>{{space?.tariffInfo.tariffPaymentMobile}}</td>
            </tr>  
        </table>
    </div> 
    <div class="w-1/3">
        <h2 class="pt-4">Karte</h2>
        <div class="mat-elevation-z1">
            <google-map height="350px" width="100%" [zoom]="mapZoom" [center]="mapCenter" [options]="mapOptions">
                <map-marker [position]="markerPosition"></map-marker>
            </google-map>
        </div>
        <!-- <ng-container *ngIf="prognoses"> -->
            <h2 class="pt-4">Kurzprognose</h2>
            <table class="table-short-prognosis mat-elevation-z1">
                <tbody>
                    <tr *ngIf="occupancy?.validData">
                        <td colspan="2">Freie Stellplätze: <b>Aktuell</b></td>
                    </tr>
                    <tr *ngIf="occupancy?.validData">
                        <td>
                            <div class="pit-cell cat-{{occupancy?.vacancyCategory}}">
                                <b>{{occupancy?.vacancyText}}</b>
                            </div>
                        </td>
                        <td>
                            <b>{{occupancy?.timeSegment | date: 'HH:mm'}} Uhr</b><br>
                            {{occupancy?.timeSegment | date: 'dd.MM.yyyy'}}
                        </td>
                    </tr>
                    <tr *ngIf="!occupancy?.validData">
                        <td colspan="2">Keine Daten verfügbar.</td>
                    </tr>
                </tbody>

                <tbody *ngFor="let prognosis of prognoses; let i = index">
                    <tr>
                        <td colspan="2">Freie Stellplätze: 
                            <b>
                                <span *ngIf="i === 0">In 1 Stunde</span>
                                <span *ngIf="i === 1">In 2 Stunden</span>
                            </b>
                        </td>
                    </tr>
                    <tr *ngIf="prognosis?.occupancy.validData">
                        <td>
                            <div class="pit-cell cat-{{prognosis.occupancy.vacancyCategory}}">
                                <b>{{prognosis.occupancy.vacancyText}}</b>
                            </div>
                        </td>
                        <td>
                            <b>{{prognosis.occupancy.timeSegment | date: 'HH:mm'}} Uhr</b><br>
                            {{prognosis.occupancy.timeSegment | date: 'dd.MM.yyyy'}}
                        </td>
                    </tr>
                    <tr *ngIf="!prognosis?.occupancy.validData">
                        <td colspan="2">Keine Daten verfügbar.</td>
                    </tr>
                </tbody>
            </table>
        <!-- </ng-container> -->
    </div>
</div>    
