<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="hon-form" (ngSubmit)="submitHonRequest()">

        <div class="flex flex-row">

            <!-- Site select box -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Parkraum</mat-label>
                    <mat-select formControlName="site" disableOptionCentering required>
                        <mat-option *ngFor="let station of stationList" [value]="station">
                            {{station.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Datepicker -->
            <div class="w-[18%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Datum</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="date" 
                    [max]="maxDate" />
                    <mat-datepicker-toggle matSuffix [for]="datepicker">
                        <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepicker (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Interval select box -->
            <div class="w-[10%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Intervall</mat-label>
                    <mat-select formControlName="interval" disableOptionCentering required>
                        <mat-option [value]="'MONTHS'">12 Monate</mat-option>
                        <mat-option [value]="'DAYS'">31 Tage</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Compare toggle -->
            <div class="w-[10%] me-5 flex flex-row items-center justify-center">
                <mat-slide-toggle color="primary" labelPosition="before" formControlName="compare">Vergleichen</mat-slide-toggle>
            </div>

            <!-- Year to compare select box -->
            <div class="w-[10%] me-5" *ngIf="form.value.compare">
                <mat-form-field appearance="outline">
                    <mat-label>Jahr zum Vergleich</mat-label>
                    <mat-select formControlName="year" disableOptionCentering>
                        <mat-option *ngFor="let year of yearCompare" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>


        </div>

    </form>

</mat-card>
