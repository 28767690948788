import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { MatTableDataSource } from '@angular/material/table';
import { PrognosisFormComponent } from './prognosis-form/prognosis-form.component';

@Component({
    selector: 'app-prognosis',
    templateUrl: './prognosis.component.html',
    styleUrls: ['./prognosis.component.scss']
})
export class PrognosisComponent implements OnInit {

    validData: boolean
    name: string
    site: any
    time: string
    date: string
    quality: number 
    qualityMessage: string
    stars: string[]

    public displayedColumnsPrognosis: string[] = [
        'site',
        'prognosis',
        'timestamp'
    ]
    public dataSourcePrognosis: MatTableDataSource<any>;

    public displayedColumnsDataBase: string[] = [
        'site',
        'occupancy',
        'timestamp'
    ]
    public dataSourceDataBase: MatTableDataSource<any>;

    constructor(
        public dataService: DataService
    ) { }

    ngOnInit(): void {

    }

    onSubmitPrognosisRequest($event: any) {

        this.site = $event.site;
        this.name = $event.site.name;
        this.date = $event.date;
        this.time = $event.time;

        this.dataService.getPrognosisBySite(this.site.id, this.date, this.time).subscribe(
            (data: any) => {

                this.validData = data.prognosis.occupancy.validData;

                const occupancy = [data.prognosis.occupancy]
                occupancy[0].site = data.site
                this.dataSourcePrognosis = new MatTableDataSource(occupancy);

                const usedOccupancies = data.prognosis.usedOccupancies.map((item:any) => ({ ...item, site: data.site}))
                this.dataSourceDataBase = new MatTableDataSource(usedOccupancies);

                this.quality = data.prognosis.quality
                this.qualityMessage = data.prognosis.qualityMessage
                this.stars = this.qualityRating(this.quality)
            }
        )
    }

    qualityRating(quality:number): string[] {
        const stars = [];
        for (let i=0; i<=4; i++) {
            if (i < quality) stars.push('star')
            else stars.push('star_outline')
        }
        return stars
    }


}
