<capacity-form #defaultDateFrom (capacityRequest)="onSubmitCapacityRequest($event)"></capacity-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5 z-10">
    <h1>Kapazität: Von {{from | date: 'dd. MMMM yyyy'}} bis {{until | date: 'dd. MMMM yyyy'}}</h1>
</div>

<div class="flex flex-col px-5 mb-5 w-[50%]">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

        <!-- Site Column -->
        <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef> Parkraum </th>
            <td mat-cell *matCellDef="let element">
                <b> {{element.site.station.name}} </b><br>
                <small> {{element.site.name}} </small>
            </td>
        </ng-container>
12
        <!-- Max Capacity Column -->
        <ng-container matColumnDef="capacityMax">
            <th mat-header-cell *matHeaderCellDef> Maximalwert </th>
            <td mat-cell *matCellDef="let element"><b> {{element.capacity.capacityMax}} </b></td>
        </ng-container>

        <!-- Min Capacity Column -->
        <ng-container matColumnDef="capacityMin">
            <th mat-header-cell *matHeaderCellDef> Minimalwert </th>
            <td mat-cell *matCellDef="let element"><b> {{element.capacity.capacityMin}} </b></td>
        </ng-container>

        <!-- Capacity Deviation Column -->
        <ng-container matColumnDef="capacityDeviation">
            <th mat-header-cell *matHeaderCellDef> Abweichung </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.capacity.capacityMin !== element.capacity.capacityMax">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond" viewBox="0 0 16 16">
                        <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                    </svg>
                </span>
            </td>
        </ng-container>

        <!-- Chart Button Column -->
        <ng-container matColumnDef="chartButton">
            <th mat-header-cell *matHeaderCellDef> Diagramm </th>
            <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" aria-label="View chart button" (click)="openChartDialog(element)">
                    <mat-icon>bar_chart</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>