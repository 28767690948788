import { Component, Input, OnInit, ViewChild, AfterViewInit, ComponentFactoryResolver } from '@angular/core';
import { Store } from '@ngxs/store';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { FailureFormComponent } from './failure-form/failure-form.component';
import { FailureChartDialogComponent } from './failure-chart-dialog/failure-chart-dialog.component'
import { DataService } from '@core/services/data.service';
import { GetFailureSites } from './store/failure.action';
import { FailureState } from './store/failure.state';

@Component({
    selector: 'app-failure',
    templateUrl: './failure.component.html',
    styleUrls: ['./failure.component.scss']
})
export class FailureComponent implements AfterViewInit, OnInit {

    @ViewChild(FailureFormComponent) child: FailureFormComponent;

    from: string | null = null;
    until: string | null = null;

    displayedColumns: string[] = [
        'site',
        'failureDaysCount',
        'failureHours',
        'chartButton'
    ]
    dataSource = new MatTableDataSource<any>();

    constructor(
        private store: Store,
        public dialog: MatDialog,
        private dataService: DataService
    ) { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {

        /** Disabled state management for now */ 

        // Expression has changed after it was checked issue: https://stackoverflow.com/a/57646589
        /*setTimeout(() => {
            this.from = this.child.defaultDateFrom
            this.until = this.child.defaultDateUntil
            this.store.dispatch(new GetFailureSites(this.from, this.until)).subscribe(
                () => {
                    const store = this.store.selectSnapshot(FailureState.getFailureSites)
                    this.dataSource = store.data
                }
            )
        }, 0);*/
    }

    onSubmitFailureRequest($event: any) {
        this.from = $event.from;
        this.until = $event.until;
        this.dataService.getFailures(this.from, this.until).subscribe(
            (data: any) => {
                this.dataSource = data.failureSites.sort((a: any, b: any) => {
                    return a.site.station.name.localeCompare(b.site.station.name);
                });
            }
        )
    }

    openChartDialog(element: any) {
        this.dialog.open(FailureChartDialogComponent, { 
            width: '90vw', 
            data: {
                failureSite: element,
                dateUntil: this.until
            }
        });
    }

}