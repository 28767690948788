import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { InterceptorSkipHeader } from '@core/layout/spinner/spinner.interceptor';


@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private http: HttpClient
    ) { }

    getApiVersion(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/version`)
    }

    getUser(interceptor: string | null = null): Observable<any> {
        const headers = interceptor ? new HttpHeaders().set(InterceptorSkipHeader, interceptor) : new HttpHeaders();
        console.log(headers)
        return this.http.get<any>(`${environment.apiUrl}/v2/users/me`, { headers })
    }

    getSites(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/pit?size=1000`)
    }

    getDashboard(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/dashboard`)
    }

    getDashboardParkspotting(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/dashboard/parkspotting`)
    }

    // Occupancy
    getOccupancies(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/occupancies`)
    }

    getOccupanciesParkspotting(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/occupancies/parkspotting`)
    }

    getOccupanciesById(id:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces/${id}/occupancies`)
    }

    // Prognosis
    getPrognosisBySite(id:number, date:string, time:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/prognoses/${date}T${time}`)
    }

    getPrognosesById(id:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces/${id}/prognoses`)
    }
    
    // spaces
    getSpaces(params: any, interceptor: string | null = null): Observable<any> {
        const headers = interceptor ? new HttpHeaders().set(InterceptorSkipHeader, interceptor) : new HttpHeaders();
        console.log(headers)
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces`, {
            headers: headers,
            params: params
        })
    }

    getSpace(id:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces/${id}`)
    }

    // failures
    getFailures(from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/failures/${from}/${until}`)
    }

    getFailuresById(id: number[], from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/failures/${from}/${until}`)
    }

    // feedback space id
    getFeedbacks(ids:string, from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/feedbacks/spaceId:${ids}/${from}/${until}`)
    }
 
    // Thresholds
    getThresholds(from:string, until:string, threshold:number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/thresholds/${from}/${until}/${threshold}`)
    }

    getThresholdsById(id:number, from:string, until:string, threshold:number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/thresholds/${from}/${until}/${threshold}`)
    }

    // histroy 
    getHistoryDayById(id:number, date:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/occupancies/day/${date}`)
    }

    getHistoryDayByIdExtended(id:number, date:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/occupancies/day/${date}/extended`)
    }

    getHistoryWeekById(id:number, date:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/occupancies/week/${date}`)
    }

    getHistoryWeekmonthById(id:number, date:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/occupancies/weekmonth/${date}`)
    }

    getHistoryWeekyearById(id:number, date:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/occupancies/weekyear/${date}`)
    }

    getHistoryRangeById(id:number, from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/occupancies/range/${from}/${until}`)
    }

    getHistoryGrouping(type:string, sites:string, date:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${sites}/occupancies/${type}/${date}`)
    }

    getHistoryGroupingFails(sites:string, from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/sites:${sites}/failures/${from}/${until}?visibility=online,offline`)
    }

    // Capacity 
    getCapacities(from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/capacities/${from}/${until}`)
    }

    getCapacitiesById(id:number, from:string, until:string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/sites/${id}/capacities/${from}/${until}`)
    }

    // HoN
    getHonSurveys(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces/hon/surveys`)
    }

    getHonSurveyResultsById(id:number, from:string, until:string, interval:string): Observable<any> {
        return forkJoin([
            this.http.get<any>(`${environment.apiUrl}/v2/spaces/${id}/hon/${from}/${until}/${interval}`),
            this.http.get<any>(`${environment.apiUrl}/v2/spaces/${id}/hon/${from}/${until}/ALL`)
        ])
    }

    // Counted Occupancies
    getCountedOccupancies(params: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spaces/countedOccupancies`, {params: params})
    }

    // Spot
    getSpotClusters(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spot/clusters/current`)
    }

    getSpotModels(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spot/models/current`)
    }

    getSpotSpaces(params: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/spot/spaces`, {params: params})
    }

    // Import
    uploadFile(path: string, formData: FormData): Observable<any> {
		return this.http.post(`${environment.apiUrl}/v2/${path}/import/excel`, formData)
	}

    // Export
    downloadFile(path: string, format: string, params: any): Observable<HttpResponse<Blob>> {
		return this.http.get<Blob>(`${environment.apiUrl}/v2/${path}/export/${format}`,  {
			observe: 'response',
			responseType: 'blob' as 'json',
			params: params
		})
	}

}
