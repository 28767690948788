import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spot'
})
export class SpotPipe implements PipeTransform {

  transform(value:string): string {
    
    if (value === 'COMMUTER') return 'Pendler'
    if (value === 'HIGH_FREQUENCY') return 'Hochfrequenz'
    if (value === 'SATURDAY') return 'Samstagseinkauf'

    if (value === 'WORKDAY') return 'Wochentag'
    if (value === 'WEEKEND') return 'Wochenende'
    
    else return value

  }

}
