import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
	providedIn: 'root'
})
export class HeartbeatService {

	heartbeat: number;

	constructor(
		private dataService: DataService
	) {	}

	start(): void {
		console.log('[PIT] Heartbeat service started.')
		this.heartbeat = window.setInterval(() => this.ping(), 10 * 60000) // 10 minutes
	}

	stop(): void {
		console.log('[PIT] Heartbeat service stopped.')
		if (this.heartbeat) window.clearInterval(this.heartbeat)
	}

	ping(): void {
		this.dataService.getUser('error').subscribe(() => console.log('[PIT] ❤'))
	}

}
