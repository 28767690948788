<mat-card class="mat-elevation-z0">

    <form [formGroup]="form" class="failure-form" (ngSubmit)="submitFailureRequest()">

        <div class="flex flex-row">

            <!-- From Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Von</mat-label>
                    <input matInput [matDatepicker]="datepickerFrom" formControlName="dateFrom" 
                    [max]="maxDateFrom" (dateChange)="setLimitations()" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepickerFrom">
                        <mat-icon matDatepickerToggleIcon>today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerFrom (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- To Datepicker -->
            <div class="w-[20%] me-5">
                <mat-form-field appearance="outline">
                    <mat-label>Bis</mat-label>
                    <input matInput [matDatepicker]="datepickerUntil" formControlName="dateUntil" 
                    [min]="minDateUntil" [max]="maxDateUntil" (dateChange)="setLimitations()" />
                    <!-- <mat-error *ngIf="form.controls['date'].invalid">Bitte wählen Sie eine Datum aus.</mat-error> -->
                    <mat-datepicker-toggle matSuffix [for]="datepickerUntil">
                        <mat-icon matDatepickerToggleIcon>event</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datepickerUntil (monthSelected)="startWeekOnMonday($event)">
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Schließen</button>
                            <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Submit button -->
            <div class="w-[10%]">
                <button type="submit" [disabled]="!form.valid" mat-flat-button color="primary" class="update-button">
                    Aktualisieren
                </button>
            </div>

        </div>

    </form>

</mat-card>
