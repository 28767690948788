import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'historyTable'
})
export class HistoryTablePipe implements PipeTransform {

    /**
     * Table heading for 5 minute intervals.
     * @returns {string[]}
     */
    tableHead5MinuteInterval(): string[] {
        return [':00', ':05', ':10', ':15', ':20', ':25', ':30', ':35', ':40', ':45', ':50', ':55'];
    }

    /**
     * Table heading for 1 hour intervals.
     * @returns {string[]}
     */
    tableHead1HourInterval(): string[] {
        return ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
    }

    /**
     * Table heading for 45 minute intervals.
     * @returns {string[]}
     */
    tableHead1DayInterval(): string[] {
        return ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Sonnabend', 'Sonntag'];
    }

    /**
     * Transforms a list (series) to a table (list of list)
     * @returns {Array|*|table}
     */
    transform(list: any, rowNum: number, colNum: number): any {
        let table = [];
        let row = [];
        let index = 0;
        let r = 0;
        let c = 0;
        while (index < rowNum * colNum) {
            row[c] = list[index];
            index++;
            c++;
            if (c >= colNum) {
                table[r] = row;
                r++;
                row = [];
                c = 0;
            }
        }
        return table;
    }

}
