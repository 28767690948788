import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export const twoYearRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    
	const dateFrom = control.get('dateFrom')?.value;
    const dateUntil = control.get('dateUntil')?.value;

    if (dateFrom && dateUntil) {
        
		const fromMoment = moment(dateFrom);
        const untilMoment = moment(dateUntil);

        // Calculate difference in years (true parameter gives decimal precision)
        const diffInYears = untilMoment.diff(fromMoment, 'years', true);

        if (diffInYears > 2) {
            return { twoYearRange: true };
        }
    }
    return null;
};