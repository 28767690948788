import { Component, HostListener, OnInit } from '@angular/core';
import { ModeSwitcherService } from '@shared/services/mode-switcher.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'mode-switcher',
	templateUrl: './mode-switcher.component.html',
	styleUrls: ['./mode-switcher.component.scss']
})
export class ModeSwitcherComponent implements OnInit {

	public mode$: Observable<string>

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
	  if ( event.key === '1' ) this.switchMode('PIT_STANDARD')
	  else if ( event.key === '2') this.switchMode('PIT_PARKSPOTTING') 
	}

	constructor(
		private modeService: ModeSwitcherService
	) { }

	ngOnInit(): void {
		this.mode$ = this.modeService.getCurrent();
	}

	/** Switch mode */
	switchMode(mode: string) {
		this.modeService.switch(mode);
	}
}
