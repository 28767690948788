import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Revoke } from '@core/states/auth/auth.action';
import { Store } from '@ngxs/store';

@Component({
    selector: 'logout-button',
    templateUrl: './logout-button.component.html',
    styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {

    constructor(
        private router: Router,
        private store: Store,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
    }

    logout() {
        this.store.dispatch([new Revoke()]).subscribe(
            () => {
                this.snackBar.open('Erfolgreich ausgeloggt. Bis bald!', '👋', { duration: 2000, panelClass: 'success-snack' });
                this.router.navigate(['/'], { replaceUrl:true })
            }
        );
    }

}
