
<h1>Alarm für {{data.item.site.station.name}} {{data.item.site.name}}</h1>
<p>{{from | date: 'dd.MM.yyyy'}} bis {{until | date: 'dd.MM.yyyy'}}, Schwellwert {{data.threshold}}</p>

<div *ngIf="series" style="display:block;width:100%">
    <canvas baseChart
        [datasets]="alarmListChartData"
        [labels]="alarmListChartLabels"
        [options]="alarmListChartOptions"
        [legend]="alarmListChartLegend"
        [chartType]="alarmListChartType">
    </canvas>
</div>

<mat-dialog-actions align="end">
    <button mat-flat-button [appPrint]="'Alarm_'+data.item.site.station.name+'_'+data.item.site.name"><mat-icon>print</mat-icon> Drucken</button>
    <button mat-flat-button color="primary" mat-dialog-close>Schließen</button>
</mat-dialog-actions>