<div class="flex flex-row gap-4 p-2 pt-8">
    <div class="w-full">
        <mat-accordion *ngIf="feedbacks.length > 0">
            <mat-expansion-panel *ngFor="let feedback of feedbacks">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Feedback vom {{feedback.datetime | date: 'EEEE, dd. MMMM YYYY'}}
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                </mat-expansion-panel-header>
                <p class="feedback-text">"{{feedback.text}}"</p>
                <p class="feedback-meta">
                    <small>
                        <mat-icon>fingerprint</mat-icon> Geschlecht: <b>{{feedback.sex | feedback: 'sex'}}</b> | 
                        Alter: <b>{{feedback.age | feedback: 'age'}}</b>
                    </small>
                </p>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-card *ngIf="feedbacks.length === 0">
            <mat-icon>sentiment_very_dissatisfied</mat-icon> Schade, es wurden keine Feedbacks gefunden. 
        </mat-card>
    </div>
</div>
