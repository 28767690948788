import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

	transform(array: any, fields: string, reverse: boolean): any[] {

		let keys = fields.split('.')

		return array.sort((a: any, b: any, v = (c: any) => keys.reduce((o, k) => o[k] || '', c)) =>
			(reverse ? -1 : 1) * v(a).localeCompare(v(b)))

	}

}
