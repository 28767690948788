<history-sparklines-form #defaultDate (historySparklinesRequest)="onSubmitHistorySparklinesRequest($event)"></history-sparklines-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Historie (Tag): {{site?.name}} ({{capacityTotal}}) vom {{date | date: 'dd. MMMM yyyy'}}</h1>
</div>

<div class="flex flex-col px-5 mb-5">

    <mat-card>
        <canvas
            *ngIf="sparklinesChartData" 
            baseChart
            [datasets]="sparklinesChartData"
            [labels]="sparklinesChartLabels"
            [options]="sparklinesChartOptions"
            [legend]="sparklinesChartLegend"
            [chartType]="sparklinesChartType"
            #mycanvas="base-chart"
            width="1200"
            height="400">
        </canvas>
    </mat-card>

</div>