import { Component, HostListener, OnInit } from '@angular/core';
import { ModeSwitcherService } from '@shared/services/mode-switcher.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'mode-switcher',
	templateUrl: './mode-switcher.component.html',
	styleUrls: ['./mode-switcher.component.scss']
})
export class ModeSwitcherComponent implements OnInit {

	public mode$: Observable<string>

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
	  // Check if target is an input field
	  const targetElement = event.target as HTMLElement;
	  const isInputField = targetElement.tagName === 'INPUT' || 
	                      targetElement.tagName === 'TEXTAREA' ||
	                      targetElement.isContentEditable;

	  // Only proceed if not in input field OR using Shift key
	  if (!isInputField || event.shiftKey) {
	    if (event.key === '1') {
	      this.switchMode('PIT_STANDARD');
	    } else if (event.key === '2') {
	      this.switchMode('PIT_PARKSPOTTING');
	    }
	  }
	}

	constructor(
		private modeService: ModeSwitcherService
	) { }

	ngOnInit(): void {
		this.mode$ = this.modeService.getCurrent();
	}

	/** Switch mode */
	switchMode(mode: string) {
		this.modeService.switch(mode);
	}
}
