import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ModeGuard implements CanActivate {

	private mode: string;

    constructor(
		private router: Router
    ) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// Get allowd modes array
		let allowedModes = route.data.modes as Array<string>;
		// Get current mode from query param
		this.mode = route.queryParamMap.get('mode') || 'PIT_STANDARD'; // Quick fix for HoN, do better job

		if ( !allowedModes.includes(this.mode) ) {
			this.router.navigate(['/home'], { queryParams: { mode: this.mode }})
            return false
		}

		return true
	}

}
