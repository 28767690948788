import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'scroll-to-top',
	templateUrl: './scroll-to-top-button.component.html',
	styleUrls: ['./scroll-to-top-button.component.scss']
})
export class ScrollToTopButtonComponent {

	scrolled: boolean = false
	forbiddenRoutes: string[] = ['/dashboard']

	@HostListener('window:scroll', []) onWindowScroll() {
        this.scrolled = window.scrollY > 500;
    }

	constructor(
		private router: Router
	) { }

	scrollToTop(): void {
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})
	}

	disableByRoute(): boolean {
		return !this.forbiddenRoutes.includes(this.router.url)
	}

}
