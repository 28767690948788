import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@core/states/auth/auth.state';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private store: Store,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

        if ( !isAuthenticated ) {
            this.router.navigate(['/'], { queryParams: { redirect_to: state.url }})
            return false
        }

        return isAuthenticated;
    }

}
