<app-spot-spaces-form (spotSpacesRequest)="onSubmitSpotSpacesRequest($event)"></app-spot-spaces-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Parkraum Stammdaten ({{count}}) für Parkspotting</h1>
</div>
    
<div class="flex flex-col w-[calc(100%-2.5rem)] px-5 mb-20">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 spot-spaces-table">

        <!-- Space Column -->
        <ng-container matColumnDef="space">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Parkraum </th>
            <td mat-cell *matCellDef="let element" class="!text-left max-w-[18rem]">
                <b>{{element.space.name}}</b><br>
                <code>{{element.space.id}}</code>
            </td>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Publiziert am </th>
            <td mat-cell *matCellDef="let element" class="!text-left">
                <b>{{element.timestamp | date: 'dd.MM.Y'}}</b><br>
                {{element.timestamp | date: 'HH:mm:ss'}} Uhr
            </td>
        </ng-container>

        <!-- isParkspotting Column -->
        <ng-container matColumnDef="isParkspotting">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Status </th>
            <td mat-cell *matCellDef="let element" class="!text-left">
                <span [ngClass]="element.isParkspotting ? 'bg-[#5cb85c]' : 'bg-[#d9534f]'" class="px-2 py-1 rounded-sm !text-[#FFF] text-xs">
                    {{element.isParkspotting ? 'Aktiv' : 'Inaktiv'}}
                </span>
            </td>
        </ng-container>

        <!-- Capacity Total Column -->
        <ng-container matColumnDef="capacityParkspotting">
            <th mat-header-cell *matHeaderCellDef> Kapazität </th>
            <td mat-cell *matCellDef="let element">{{element.capacityParkspotting}}</td>
        </ng-container>

        <!-- Season Parker Reservation Column -->
        <ng-container matColumnDef="seasonParkerReservations">
            <th mat-header-cell *matHeaderCellDef> davon res. DP </th>
            <td mat-cell *matCellDef="let element">{{element.seasonParkerReservations}}</td>
        </ng-container>

        <!-- Model Day Column
        <ng-container matColumnDef="modelDay">
            <th mat-header-cell *matHeaderCellDef> Model Day </th>
            <td mat-cell *matCellDef="let element">{{element.modelDay}}</td>
        </ng-container> -->

        <!-- Season Parker VSS Column -->
        <ng-container matColumnDef="seasonParkerVvs">
            <th mat-header-cell *matHeaderCellDef> DP VVS </th>
            <td mat-cell *matCellDef="let element">{{element.seasonParkerVvs}}</td>
        </ng-container>

        <!-- Occupancy Season Parker Column -->
        <ng-container matColumnDef="occupancySeasonParker">
            <th mat-header-cell *matHeaderCellDef> Anzahl DP </th>
            <td mat-cell *matCellDef="let element">{{element.occupancySeasonParker}}</td>
        </ng-container>

        <!-- PS App ID Column -->
        <ng-container matColumnDef="psAppId">
            <th mat-header-cell *matHeaderCellDef> PS App ID </th>
            <td mat-cell *matCellDef="let element"><code>{{element.psAppId}}</code></td>
        </ng-container>

        <!-- PSA IDs Column -->
        <ng-container matColumnDef="psaIds">
            <th mat-header-cell *matHeaderCellDef> PSA IDs </th>
            <td mat-cell *matCellDef="let element" class="text-right whitespace-pre-wrap break-words max-w-[12rem]">
                <code>{{element.psaIds.join(', ')}}</code>
            </td>
        </ng-container>

        <!-- Special Agreements Column -->
        <ng-container matColumnDef="specialAgreements">
            <th mat-header-cell *matHeaderCellDef> Sondertarife </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon>{{element.specialAgreements ? 'check' : 'close'}}</mat-icon>
            </td>
        </ng-container>

        <!-- Tariff Hour Column -->
        <ng-container matColumnDef="tariffHour">
            <th mat-header-cell *matHeaderCellDef> Tarif Stunde </th>
            <td mat-cell *matCellDef="let element">{{element.tariffHour | currency: 'EUR' : 'symbol' : '1.2-2'}}</td>
        </ng-container>

        <!-- Special Treatment Column --> 
        <ng-container matColumnDef="specialTreatment">
            <th mat-header-cell *matHeaderCellDef> Sonderbeh. </th>
            <td mat-cell *matCellDef="let element">
                <svg *ngIf="!element.tariffHour && !element.specialAgreements && element.isParkspotting" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z"/>
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                  </svg>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10" class="!text-center">Keine Daten gefunden.</td>
        </tr>

    </table>

</div>