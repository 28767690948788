<div class="flex flex-col mb-6">

    <div class="w-100 bg-[whitesmoke] sticky top-[64px] z-10">

        <div class="flex flex-row justify-between p-5 w-[60%]">
            <!-- PIT STANDARD -->
            <ng-container *ngIf="(mode$ | async) === 'PIT_STANDARD'">
                <h1>Aktuelle Belegung Kurzparker</h1>
                <button mat-button [appPrint]="'Aktuelle Belegung Kurzparker'">
                    <mat-icon>print</mat-icon> Drucken
                </button>
            </ng-container>
            <!-- PIT Parkspotting -->
            <ng-container *ngIf="(mode$ | async) === 'PIT_PARKSPOTTING'">
                <h1>Aktuelle Belegung {{activeLabel}}</h1>
                <button mat-button [appPrint]="'Aktuelle Belegung Kurzparker'">
                    <mat-icon>print</mat-icon> Drucken
                </button>
            </ng-container>
        </div>

    </div>

    <div class="flex flex-row w-[60%] print:w-full px-5 print:px-0">
        <!-- PIT STANDARD -->
        <table *ngIf="(mode$ | async) === 'PIT_STANDARD'" mat-table [dataSource]="dataSource" class="mat-elevation-z1">

            <!-- Site Column -->
            <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef> Parkbereich </th>
                <td mat-cell *matCellDef="let element">
                    <b> {{element.site.station.name}} </b><br>
                    <small> {{element.site.name}} </small>
                </td>
            </ng-container>

            <!-- Capacity Column -->
            <ng-container matColumnDef="capacity">
                <th mat-header-cell *matHeaderCellDef> Kapazität </th>
                <td mat-cell *matCellDef="let element"><b> {{element.occupancy.capacity}} </b></td>
            </ng-container>

            <!-- Occupancy Column -->
            <ng-container matColumnDef="occupancy">
                <th mat-header-cell *matHeaderCellDef> Belegt </th>
                <td mat-cell *matCellDef="let element">
                    <div class="pit-cell cat-{{element.occupancy.occupancyCategory}}">
                        <b> {{element.occupancy.occupancy}} </b>
                        <small> {{element.occupancy.occupancyRate | percent: '1.0-0':'en' }} </small>
                    </div>
                </td>
            </ng-container>

            <!-- Vacancy Column -->
            <ng-container matColumnDef="vacancy">
                <th mat-header-cell *matHeaderCellDef> Frei </th>
                <td mat-cell *matCellDef="let element">
                    <div class="pit-cell cat-{{element.occupancy.occupancyCategory}}">
                        <b> {{element.occupancy.vacancy}} </b>
                    </div>
                </td>
            </ng-container>

            <!-- Timestamp Column -->
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef> Zeit </th>
                <td mat-cell *matCellDef="let element">
                    <b> {{element.occupancy.timeSegment | date: 'HH:mm:ss'}} </b><br>
                    <small>{{element.occupancy.timeSegment | date: 'dd.MM.yyyy'}}</small>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>
        <!-- PIT Parkspotting -->           
        <mat-tab-group 
        *ngIf="(mode$ | async) === 'PIT_PARKSPOTTING'" 
        mat-align-tabs="left" 
        animationDuration="0ms" 
        style="width:100%;" 
        class="mat-elevation-z1 tab-custom-overflow"
        (selectedIndexChange)="tabIndex($event)">
            <mat-tab 
            [label]="label_comparison" 
            *ngIf="this.dataSource.data.length > 0">
                <ng-template matTabContent>
                    <app-parkspotting-comparison [data]="this.dataSource.data" [delay]="delay"></app-parkspotting-comparison>
                </ng-template>
            </mat-tab>
            <mat-tab 
            [label]="label_summedup" 
            *ngIf="this.dataSource.data.length > 0">
                <ng-template matTabContent>
                    <app-parkspotting-summed-up [data]="this.dataSource.data" [delay]="delay"></app-parkspotting-summed-up>
                </ng-template>
            </mat-tab>
            <!--
            <mat-tab 
            [label]="label_app_export"
            *ngIf="this.dataSource.data.length > 0">
                <ng-template matTabContent>
                    <app-parkspotting-app-export></app-parkspotting-app-export>
                </ng-template>
            </mat-tab>
            -->
        </mat-tab-group>
    </div>

</div>