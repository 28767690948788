import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'app-spot-spaces',
  templateUrl: './spot-spaces.component.html',
  styleUrls: ['./spot-spaces.component.scss']
})
export class SpotSpacesComponent implements OnInit {

	count: number = 0;

	displayedColumns: string[] = [
		'space',
		'timestamp',
		'isParkspotting',
		'capacityParkspotting',
		'occupancySeasonParker',
		'seasonParkerReservations',
		'seasonParkerVvs',
		'tariffHour',
		'specialAgreements',
		'specialTreatment',
		'psAppId',
		'psaIds',
	];

	dataSource = new MatTableDataSource<any>();

	constructor(
		private dataService: DataService
	) { }

	ngOnInit(): void {
		this.updateSpotSpaces();
	}

	updateSpotSpaces(params?: any): void {
		this.dataService.getSpotSpaces(params).subscribe(
            (data: any) => {
                this.dataSource = data;
				this.count = data.length;
            }
        )
	}

	onSubmitSpotSpacesRequest(event: any): void {
		const params: any = {};
		if (event.dateFrom) { params.from = event.dateFrom; }
		if (event.dateUntil) { params.until = event.dateUntil; }
		this.updateSpotSpaces(params);
	}

}
