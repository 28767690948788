<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <!-- Site Column -->
    <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> Parkbereich </th>
        <td mat-cell *matCellDef="let element">
            <b> {{element.site.station.name}} </b><br>
            <small> {{element.site.name}} </small>
        </td>
    </ng-container>

    <!-- Capacity Column -->
    <ng-container matColumnDef="capacity">
        <th mat-header-cell *matHeaderCellDef> Kapazität </th>
        <td mat-cell *matCellDef="let element"><b> {{element.currentOccupancy.capacityTotal}} </b></td>
    </ng-container>

    <!-- Current Occupancy Column -->
    <ng-container matColumnDef="currentOccupancy">
        <th mat-header-cell *matHeaderCellDef> Belegt </th>
        <td mat-cell *matCellDef="let element">
            <div class="pit-cell cat-{{element.currentOccupancy.occupancyCategory}}" 
            *ngIf="element.currentOccupancy.dataStatus === 'VALID'"
            [ngClass]="{'invalid': element.currentOccupancy.hasDowntime}">
                <ng-container *ngIf="!element.currentOccupancy.hasDowntime">
                    <b> {{element.currentOccupancy.occupancyTotal}} </b>
                    <small> {{element.currentOccupancy.occupancyTotalRate | percent }} </small>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Vacancy Column -->
    <ng-container matColumnDef="vacancy">
        <th mat-header-cell *matHeaderCellDef> Frei </th>
        <td mat-cell *matCellDef="let element">
            <div class="pit-cell cat-{{element.currentOccupancy.occupancyCategory}}"
            *ngIf="element.currentOccupancy.dataStatus === 'VALID'"
            [ngClass]="{'invalid': element.currentOccupancy.hasDowntime}">
                <ng-container *ngIf="!element.currentOccupancy.hasDowntime">
                    <b> {{element.currentOccupancy.capacityTotal - element.currentOccupancy.occupancyTotal}} </b>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Current Occupancy Column -->
    <ng-container matColumnDef="currentOccupancyChart">
        <th mat-header-cell *matHeaderCellDef> Belegung </th>
        <td mat-cell *matCellDef="let element">
            <canvas 
                *ngIf="element.currentOccupancy.dataStatus === 'VALID' && !element.currentOccupancy.hasDowntime" 
                id="occupancy-chart-{{element.site.id}}"
                baseChart 
                width="200" 
                height="75"
                [datasets]="element.currentOccupnacyChartDataSets"
                [labels]="currentOccupnacyChartLabels"
                [options]="currentOccupnacyChartOptions"
                [plugins]="barChartPlugins"
                [legend]="currentOccupnacyChartLegend"
                [chartType]="currentOccupnacyChartType">
            </canvas>
        </td>
    </ng-container>

    <!-- Occupancies Column -->
    <ng-container matColumnDef="occupanciesChart">
        <th mat-header-cell *matHeaderCellDef> 24-Stunden-Verlauf </th>
        <td mat-cell *matCellDef="let element">
            <canvas 
                *ngIf="element.currentOccupancy.dataStatus === 'VALID' && !element.currentOccupancy.hasDowntime"    
                baseChart 
                width="150" 
                height="65"
                [datasets]="element.occupanciesChartDataSets"
                [labels]="occupanciesChartLabels"
                [options]="occupanciesChartOptions"
                [legend]="occupanciesChartLegend"
                [chartType]="occupanciesChartType">
            </canvas>
        </td>
    </ng-container>

    <!-- Timestamp Column -->
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> Zeit </th>
        <td mat-cell *matCellDef="let element">
            <b> {{element.currentOccupancy.timeSegment | date: 'HH:mm:ss'}} </b><br>
            <small>{{element.currentOccupancy.timeSegment | date: 'dd.MM.yyyy'}}</small>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>