import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';

import { DatePipe, registerLocaleData } from '@angular/common';
import { PercentPipe } from '@angular/common';
import { SalutationPipe } from './shared/pipes/salutation.pipe';

import localeDe from '@angular/common/locales/de';

import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
// import { TokenInterceptor } from './core/interceptors/token.interceptor';


import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { QueryParametersInterceptor } from '@core/interceptors/query-parameters.interceptor';

import { TariffNamesPipe } from './shared/pipes/tariff-names.pipe';

import { getGermanPaginatorIntl } from './core/intl/german-paginator.intl';
import { GoogleMapsModule } from '@angular/google-maps'
import { environment } from '@environments/environment';

import { AuthGuard } from './core/guards/auth.guard';
import { ModeGuard } from '@core/guards/mode.guard';

import { ChartsModule, BaseChartDirective } from 'ng2-charts';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { UserState } from '@core/states/user/user.state';
import { AuthState, logoutPlugin } from '@core/states/auth/auth.state';

import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';


import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


import { AppComponent } from './app.component';
import { HeaderComponent } from '@core/layout/header/header.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { OccupancyComponent } from './occupancy/occupancy.component';
import { PrognosisComponent } from './prognosis/prognosis.component';
import { PrognosisFormComponent } from './prognosis/prognosis-form/prognosis-form.component';
import { FailureComponent } from './failure/failure.component';
import { FailureFormComponent } from './failure/failure-form/failure-form.component';
import { FailureChartDialogComponent } from './failure/failure-chart-dialog/failure-chart-dialog.component';
import { FailureState } from './failure/store/failure.state';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailDialogComponent } from './dashboard/detail-dialog/detail-dialog.component';
import { OverviewTabComponent } from './dashboard/detail-dialog/overview-tab/overview-tab.component';
import { FeedbackTabComponent } from './dashboard/detail-dialog/feedback-tab/feedback-tab.component';
import { AlarmListComponent } from './alarm/alarm-list/alarm-list.component';
import { AlarmDetailsComponent } from './alarm/alarm-details/alarm-details.component';
import { AlarmListFormComponent } from './alarm/alarm-list/alarm-list-form/alarm-list-form.component';
import { CapacityComponent } from './capacity/capacity.component';
import { CapacityFormComponent } from './capacity/capacity-form/capacity-form.component';
import { AlarmDetailsFormComponent } from './alarm/alarm-details/alarm-details-form/alarm-details-form.component';
import { AlarmlistChartDialogComponent } from './alarm/alarm-list/alarm-list-chart-dialog/alarmlist-chart-dialog.component';
import { CapacityChartDialogComponent } from './capacity/capacity-chart-dialog/capacity-chart-dialog.component';
import { HonComponent } from './hon/hon.component';
import { HonFormComponent } from './hon/hon-form/hon-form.component';
import { HistoryDayComponent } from './history/history-day/history-day.component';
import { HistoryDayFormComponent } from './history/history-day/history-day-form/history-day-form.component';
import { HistoryWeekComponent } from './history/history-week/history-week.component';
import { HistoryWeekFormComponent } from './history/history-week/history-week-form/history-week-form.component';
import { HistoryRangeComponent } from './history/history-range/history-range.component';
import { HistoryRangeFormComponent } from './history/history-range/history-range-form/history-range-form.component';
import { HistoryWeekmonthComponent } from './history/history-weekmonth/history-weekmonth.component';
import { HistoryWeekmonthFormComponent } from './history/history-weekmonth/history-weekmonth-form/history-weekmonth-form.component';
import { ClockComponent } from './core/layout/header/clock/clock.component';
import { HistoryWeekyearComponent } from './history/history-weekyear/history-weekyear.component';
import { HistoryWeekyearFormComponent } from './history/history-weekyear/history-weekyear-form/history-weekyear-form.component';
import { HistoryGroupingComponent } from './history/history-grouping/history-grouping.component';
import { HistoryGroupingFormComponent } from './history/history-grouping/history-grouping-form/history-grouping-form.component';
import { HistoryTablePipe } from './history/history-table.pipe';
import { SpinnerComponent } from './core/layout/spinner/spinner.component';
import { SpinnerInterceptor } from '@core/layout/spinner/spinner.interceptor';
import { FailureTabComponent } from './dashboard/detail-dialog/failure-tab/failure-tab.component';
import { AlarmTabComponent } from './dashboard/detail-dialog/alarm-tab/alarm-tab.component';
import { UserDialogComponent } from './core/layout/header/user-dialog/user-dialog.component';
import { LogoutButtonComponent } from './core/layout/header/logout-button/logout-button.component';
import { PrintDirective } from './shared/directives/print.directive';
import { FeedbackPipe } from './shared/pipes/feedback.pipe';
import { OrderByPipe } from './shared/pipes/order-by.pipe';
import { ExportButtonDirective } from './shared/directives/export-button.directive';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ScrollToTopButtonComponent } from './core/layout/main/scroll-to-top-button/scroll-to-top-button.component';
import { QueryParamsHandlingDirective } from './shared/directives/query-params-handling.directive';
import { ModeSwitcherComponent } from './core/layout/header/mode-switcher/mode-switcher.component';
import { ParkspottingSummedUpComponent } from './occupancy/parkspotting/parkspotting-summed-up/parkspotting-summed-up.component';
import { ParkspottingComparisonComponent } from './occupancy/parkspotting/parkspotting-comparison/parkspotting-comparison.component';
import { HistorySparklinesComponent } from './history/history-day/parkspotting/history-sparklines/history-sparklines.component';
import { HistorySparklinesFormComponent } from './history/history-day/parkspotting/history-sparklines/history-sparklines-form/history-sparklines-form.component';
import { HttpXsrfInterceptor } from '@core/interceptors/http-xsrf.interceptor';
import { ParkspottingAppExportComponent } from './occupancy/parkspotting/parkspotting-app-export/parkspotting-app-export.component';
import { CountedOccupancyComponent } from './counted-occupancy/counted-occupancy.component';
import { CountedOccupancyFormComponent } from './counted-occupancy/counted-occupancy-form/counted-occupancy-form.component';
import { SpotModelComponent } from './spot/spot-model/spot-model.component';
import { SpotClusterComponent } from './spot/spot-cluster/spot-cluster.component';
import { SpotSpacesComponent } from './spot/spot-spaces/spot-spaces.component';
import { ImportButtonComponent } from './shared/components/import-button/import-button.component';
import { ImportDialogComponent } from './shared/components/import-button/import-dialog/import-dialog.component';
import { SpotClusterFormComponent } from './spot/spot-cluster/spot-cluster-form/spot-cluster-form.component';
import { SpotModelFormComponent } from './spot/spot-model/spot-model-form/spot-model-form.component';
import { SpotSpacesFormComponent } from './spot/spot-spaces/spot-spaces-form/spot-spaces-form.component';
import { SpotPipe } from './shared/pipes/spot.pipe';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    OccupancyComponent,
    PrognosisComponent,
    PrognosisFormComponent,
    FailureComponent,
    FailureFormComponent,
    FailureChartDialogComponent,
    DashboardComponent,
    DetailDialogComponent,
    OverviewTabComponent,
    FeedbackTabComponent,
    TariffNamesPipe,
    AlarmListComponent,
    AlarmDetailsComponent,
    AlarmListFormComponent,
    CapacityComponent,
    CapacityFormComponent,
    AlarmDetailsFormComponent,
    AlarmlistChartDialogComponent,
    CapacityChartDialogComponent,
    HonComponent,
    HonFormComponent,
    HistoryDayComponent,
    HistoryDayFormComponent,
    HistoryWeekComponent,
    HistoryWeekFormComponent,
    HistoryRangeComponent,
    HistoryRangeFormComponent,
    HistoryWeekmonthComponent,
    HistoryWeekmonthFormComponent,
    ClockComponent,
    HistoryWeekyearComponent,
    HistoryWeekyearFormComponent,
    HistoryGroupingComponent,
    HistoryGroupingFormComponent,
    HistoryTablePipe,
    SpinnerComponent,
    FailureTabComponent,
    AlarmTabComponent,
    UserDialogComponent,
    LogoutButtonComponent,
    PrintDirective,
    FeedbackPipe,
    OrderByPipe,
    ExportButtonDirective,
    ScrollToTopButtonComponent,
    QueryParamsHandlingDirective,
    ModeSwitcherComponent,
    ParkspottingSummedUpComponent,
    ParkspottingComparisonComponent,
    HistorySparklinesComponent,
    HistorySparklinesFormComponent,
    SalutationPipe,
    ParkspottingAppExportComponent,
    CountedOccupancyComponent,
    CountedOccupancyFormComponent,
    SpotModelComponent,
    SpotClusterComponent,
    SpotSpacesComponent,
    ImportButtonComponent,
    ImportDialogComponent,
    SpotClusterFormComponent,
    SpotModelFormComponent,
    SpotSpacesFormComponent,
    SpotPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GoogleMapsModule,
    ChartsModule,
    // FlexLayoutModule,

    NgxMatTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatTimepickerModule.setLocale('de-AT'),
 
    MatInputModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatListModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatExpansionModule,
    MatAutocompleteModule,

    NgxsModule.forRoot([AuthState, UserState, FailureState], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),

    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),


  ],
  exports: [HttpClientModule],
  providers: [
    TariffNamesPipe,
    BaseChartDirective,
    AuthGuard,
    ModeGuard,
    DatePipe,
    HistoryTablePipe,
    OrderByPipe,
    PrintDirective,
    PercentPipe,
    DecimalPipe,
    QueryParamsHandlingDirective,
    SpotPipe,
    // NgxMatNativeDateModule,
    // MatMomentDateModule,
    /*{ provide: MAT_DATE_FORMATS, useValue: {
            display: {
                dateInput: 'YYYY-MM-DD',
            },
        },
    },*/
    { provide: NGXS_PLUGINS, useValue: logoutPlugin, multi: true },
    { provide: LOCALE_ID, useValue: 'de' },
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true} },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true, },
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: QueryParametersInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { height: '85vh', scrollStrategy: new NoopScrollStrategy()} }
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        registerLocaleData(localeDe);
    }
 }
